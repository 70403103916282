/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  JWT: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AddAgencyDocumentInput = {
  agencyId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  type: DocumentType;
};

export type AddTalentDocumentInput = {
  file: Scalars['Upload']['input'];
  talentId: Scalars['String']['input'];
  type: DocumentType;
};

export type AgenciesFilterInput = {
  collaborations?: InputMaybe<Array<Scalars['String']['input']>>;
  country?: InputMaybe<Country>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  internationalProjects?: InputMaybe<Scalars['Boolean']['input']>;
  operator?: InputMaybe<QueryOperators>;
  prediction?: InputMaybe<Array<PredictionStatus>>;
  seniorities?: InputMaybe<Array<Scalars['String']['input']>>;
  specialities?: InputMaybe<Array<SkillFilterInput>>;
  status?: InputMaybe<TalentStatus>;
  tools?: InputMaybe<Array<LeveledSkillFilterInput>>;
  workplaces?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AgenciesOrder = {
  direction: OrderDirection;
  field: AgenciesOrderField;
};

/** Properties by which agency connections can be ordered. */
export enum AgenciesOrderField {
  Country = 'country',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
}

export type Agency = {
  additionalInfo?: Maybe<Scalars['String']['output']>;
  biography?: Maybe<Scalars['String']['output']>;
  collaborationNotes?: Maybe<Scalars['String']['output']>;
  collaborations?: Maybe<Array<Scalars['String']['output']>>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  documents?: Maybe<Array<DocumentOutputType>>;
  email?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<Scalars['String']['output']>;
  experience?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Scalars['String']['output']>>;
  internationalProjects?: Maybe<Scalars['Boolean']['output']>;
  interviewRecapSent?: Maybe<Scalars['Boolean']['output']>;
  interviewSent?: Maybe<Scalars['Boolean']['output']>;
  languages: Array<LanguageType>;
  mobile?: Maybe<Scalars['String']['output']>;
  newsletter?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notesDetails?: Maybe<NotesDetailsType>;
  portfolioLinks?: Maybe<Array<PortfolioLinkType>>;
  rejectSent?: Maybe<Scalars['Boolean']['output']>;
  reminderSent?: Maybe<Scalars['Boolean']['output']>;
  search?: Maybe<Array<Scalars['String']['output']>>;
  specialities?: Maybe<Array<SpecialityType>>;
  status?: Maybe<TalentStatus>;
  termsAcceptance?: Maybe<Scalars['Boolean']['output']>;
  tools?: Maybe<Array<ToolType>>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  vat?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  workplace?: Maybe<Scalars['String']['output']>;
};

export type AgencyConnection = {
  edges?: Maybe<Array<AgencyEdge>>;
  nodes?: Maybe<Array<Agency>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AgencyEdge = {
  cursor: Scalars['String']['output'];
  node: Agency;
};

export type AllFields = {
  latest?: Maybe<AllFieldsObject>;
  onboarding?: Maybe<OnboardingData>;
  previous?: Maybe<AllFieldsObject>;
};

export type AllFieldsObject = {
  education: Education;
  languages: Languages;
  soft_skills: SoftSkills;
  technical_skills: TechnicalSkills;
  work_experiences: WorkExperience;
};

export type AnalyticsStatusCount = {
  count: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export type Auth = {
  /** JWT access token */
  accessToken: Scalars['JWT']['output'];
  /** JWT refresh token */
  refreshToken: Scalars['JWT']['output'];
  user: User;
};

export type AutoMatchingOutput = {
  api_version: Scalars['String']['output'];
  count: Scalars['String']['output'];
  environment: Scalars['String']['output'];
  query: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type BaseListAllResponse = {
  list_all?: Maybe<Array<CvResumeTemplateEntry>>;
};

export type BaselineResume = {
  bio?: Maybe<Bio>;
  education?: Maybe<Education>;
  extra_field?: Maybe<ExtraField>;
  languages?: Maybe<Languages>;
  location?: Maybe<Location>;
  name_and_surname?: Maybe<NameAndSurname>;
  speciality?: Maybe<Specialities>;
  technical_skills?: Maybe<TechnicalSkills>;
  work_experiences?: Maybe<WorkExperience>;
};

export type BillingInput = {
  private?: InputMaybe<PrivateBillingInput>;
  termsAcceptance: Scalars['Boolean']['input'];
  vat?: InputMaybe<VatBillingInput>;
};

export type BillingType = {
  private?: Maybe<PrivateBillingType>;
  termsAcceptance?: Maybe<Scalars['Boolean']['output']>;
  vat?: Maybe<VatBillingType>;
};

export type Bio = {
  items?: Maybe<Scalars['String']['output']>;
  section_name: Scalars['String']['output'];
};

export type BookingRequestInput = {
  age: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  email: Scalars['String']['input'];
  expectations: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  linkedin: Scalars['String']['input'];
  mobile: Scalars['String']['input'];
  pitchTitle: Scalars['String']['input'];
  role: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CvResumeTemplateEntry = {
  createdAt: Scalars['String']['output'];
  cvresumeObject: TemplateResumeOutput;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  talentId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type CategoriesFilterInput = {
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Category = {
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  experiences?: Maybe<Array<Experience>>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CategoryConnection = {
  edges?: Maybe<Array<CategoryEdge>>;
  nodes?: Maybe<Array<Category>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryEdge = {
  cursor: Scalars['String']['output'];
  node: Category;
};

export type CategoryOrder = {
  direction: OrderDirection;
  field: CategoryOrderField;
};

/** Properties by which category connections can be ordered. */
export enum CategoryOrderField {
  CreatedAt = 'createdAt',
  Id = 'id',
  Title = 'title',
}

export type CommonAnalyticsResponse = {
  expertises?: Maybe<Array<ExpertiseCount>>;
  range: Scalars['String']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CommonsAnalyticsFilterInput = {
  entity: EntityType;
  timeRange?: InputMaybe<Array<TimeRange>>;
};

export type CompaniesRequestConnection = {
  edges?: Maybe<Array<CompanyRequestEdge>>;
  nodes?: Maybe<Array<CompanyRequest>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyRequest = {
  companyName: Scalars['String']['output'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  serviceType: RequestType;
  talentAsService?: Maybe<TalentAsServiceType>;
  talentRetention?: Maybe<TalentRetentionType>;
  termAcceptance: Scalars['Boolean']['output'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyRequestEdge = {
  cursor: Scalars['String']['output'];
  node: CompanyRequest;
};

/** Country enum */
export enum Country {
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  It = 'IT',
}

export type Coupon = {
  associatedDomain?: Maybe<Scalars['String']['output']>;
  associatedEmail?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discount: Scalars['Float']['output'];
  experience?: Maybe<Experience>;
  experienceId?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  maxRedemptions: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  redemptions?: Maybe<Array<Order>>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CouponConnection = {
  edges?: Maybe<Array<CouponEdge>>;
  nodes?: Maybe<Array<Coupon>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CouponEdge = {
  cursor: Scalars['String']['output'];
  node: Coupon;
};

export type CouponFilterInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CouponsOrder = {
  direction: OrderDirection;
  field: CouponsOrderField;
};

/** Properties by which coupon connections can be ordered. */
export enum CouponsOrderField {
  Code = 'code',
  CreatedAt = 'createdAt',
  Discount = 'discount',
  ExpirationDate = 'expirationDate',
  Id = 'id',
  MaxRedemptions = 'maxRedemptions',
}

export type CreateAgencyInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  biography?: InputMaybe<Scalars['String']['input']>;
  collaborations: Array<Scalars['String']['input']>;
  collaborationsNotes?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  country: Country;
  cv: Scalars['Upload']['input'];
  email: Scalars['String']['input'];
  employees: Scalars['String']['input'];
  experience: Scalars['String']['input'];
  expertise: Array<ExpertiseInput>;
  industries: Array<Scalars['String']['input']>;
  internationalProjects: Scalars['Boolean']['input'];
  languages: Array<LanguageInput>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  portfolio?: InputMaybe<Scalars['Upload']['input']>;
  portfolioLinks?: InputMaybe<Array<PortfolioLinkInput>>;
  status?: InputMaybe<TalentStatus>;
  termsAcceptance: Scalars['Boolean']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  vat: Scalars['String']['input'];
  verificationCode?: InputMaybe<Scalars['String']['input']>;
  website: Scalars['String']['input'];
  workplace?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCategoryInput = {
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateCompanyRequestInput = {
  companyName: Scalars['String']['input'];
  country: Country;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  serviceType: RequestType;
  talentAsService?: InputMaybe<TalentAsServiceInput>;
  talentRetention?: InputMaybe<TalentRetentionInput>;
  termAcceptance: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};

export type CreateCouponInput = {
  associatedDomain?: InputMaybe<Scalars['String']['input']>;
  associatedEmail?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  discount: Scalars['Int']['input'];
  experienceId?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  maxRedemptions: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type CreateExperienceInput = {
  availables: Scalars['Int']['input'];
  categorySlug: Scalars['String']['input'];
  discount?: InputMaybe<Scalars['Int']['input']>;
  extraId?: InputMaybe<Scalars['String']['input']>;
  flexible: Scalars['Int']['input'];
  from: Scalars['DateTime']['input'];
  insurance: Scalars['Int']['input'];
  isActive: Scalars['Boolean']['input'];
  location: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  singleRoom: Scalars['Int']['input'];
  slug: Scalars['String']['input'];
  to: Scalars['DateTime']['input'];
  wordpressId: Scalars['String']['input'];
};

export type CreateFreeOrderInput = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  experienceId: Scalars['String']['input'];
  guests: Array<GuestInput>;
};

export type CreateInferenceReviewInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  review: TalentStatus;
  talentId: Scalars['String']['input'];
};

export type CreateOrderInput = {
  billing: BillingInput;
  country?: InputMaybe<Country>;
  coupon?: InputMaybe<Scalars['String']['input']>;
  experienceId: Scalars['String']['input'];
  flexible: Scalars['Boolean']['input'];
  guests: Array<GuestInput>;
  insurance: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
  totalAmount: Scalars['Float']['input'];
};

export type CreatePaymentInput = {
  orderId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  totalAmount: Scalars['Float']['input'];
};

export type CreateSkillInput = {
  expertiseId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateTalentInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  biography?: InputMaybe<Scalars['String']['input']>;
  collaborations: Array<Scalars['String']['input']>;
  collaborationsNotes?: InputMaybe<Scalars['String']['input']>;
  country: Country;
  cv: Scalars['Upload']['input'];
  digitalNomad?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  experience: Scalars['String']['input'];
  expertise: Array<ExpertiseInput>;
  firstName: Scalars['String']['input'];
  freelance: Scalars['String']['input'];
  github?: InputMaybe<Scalars['String']['input']>;
  industries: Array<Scalars['String']['input']>;
  internationalProjects: Scalars['Boolean']['input'];
  languages: Array<LanguageInput>;
  lastName: Scalars['String']['input'];
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  openToEmployment: Scalars['Boolean']['input'];
  portfolio?: InputMaybe<Scalars['Upload']['input']>;
  portfolioLinks?: InputMaybe<Array<PortfolioLinkInput>>;
  rate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TalentStatus>;
  termsAcceptance: Scalars['Boolean']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  verificationCode?: InputMaybe<Scalars['String']['input']>;
  workplace?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTechnologyInput = {
  expertiseIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateUserInput = {
  calendlyUrl?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: UserRole;
  sign?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type Deal = {
  availability?: Maybe<Scalars['Boolean']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  businessUnit?: Maybe<Scalars['String']['output']>;
  category?: Maybe<DealCategory>;
  closingDate?: Maybe<Scalars['DateTime']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  daysNumber?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expectingCloseDate?: Maybe<Scalars['DateTime']['output']>;
  expectingStartDate?: Maybe<Scalars['DateTime']['output']>;
  field?: Maybe<Array<Scalars['String']['output']>>;
  fullRemote?: Maybe<Scalars['Boolean']['output']>;
  fullTime?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  maxBudget?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  onsiteTrip?: Maybe<Scalars['String']['output']>;
  partTime?: Maybe<Scalars['String']['output']>;
  poolCount?: Maybe<Scalars['Int']['output']>;
  ratecard?: Maybe<Scalars['Float']['output']>;
  ratecardMax?: Maybe<Scalars['Float']['output']>;
  refCode: Scalars['String']['output'];
  scope?: Maybe<Array<DealScope>>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  shortlistCount?: Maybe<Scalars['Int']['output']>;
  speciality?: Maybe<Scalars['String']['output']>;
  stage?: Maybe<DealStage>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  targetBudget?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  tools?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<DealStrategy>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  zohoId: Scalars['String']['output'];
  zohoLayoutId: Scalars['String']['output'];
};

/** The category of the deal */
export enum DealCategory {
  MultiNeed = 'MULTI_NEED',
  NewDeal = 'NEW_DEAL',
  None = 'NONE',
  Refund = 'REFUND',
  Renewal = 'RENEWAL',
  Showoff = 'SHOWOFF',
}

export type DealCommonData = {
  availability: Array<Scalars['Boolean']['output']>;
  category: Array<DealCategory>;
  dealTalentSelectionStatus: Array<DealTalentSelectionStatus>;
  fullRemote: Array<Scalars['Boolean']['output']>;
  fullTime: Array<Scalars['Boolean']['output']>;
  isArchived: Array<Scalars['Boolean']['output']>;
  scope: Array<DealScope>;
  stage: Array<DealStage>;
  type: Array<DealStrategy>;
};

export type DealConnection = {
  edges?: Maybe<Array<DealEdge>>;
  nodes?: Maybe<Array<Deal>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DealEdge = {
  cursor: Scalars['String']['output'];
  node: Deal;
};

export type DealOrder = {
  direction: OrderDirection;
  field: DealOrderField;
};

/** Properties by which talent connections can be ordered. */
export enum DealOrderField {
  Budget = 'budget',
  ClosingDate = 'closingDate',
  CreatedAt = 'createdAt',
  ExpectingCloseDate = 'expectingCloseDate',
  ExpectingStartDate = 'expectingStartDate',
  Id = 'id',
  MaxBudget = 'maxBudget',
  Ratecard = 'ratecard',
  RatecardMax = 'ratecardMax',
  StartDate = 'startDate',
  TargetBudget = 'targetBudget',
  UpdatedAt = 'updatedAt',
}

/** The goal scope of the deal */
export enum DealScope {
  Coding = 'CODING',
  Cybersecurity = 'CYBERSECURITY',
  DataAndAi = 'DATA_AND_AI',
  Design = 'DESIGN',
  HardwareAndFirmware = 'HARDWARE_AND_FIRMWARE',
  Management = 'MANAGEMENT',
  MarketingAndComunication = 'MARKETING_AND_COMUNICATION',
  SystemAndNetwork = 'SYSTEM_AND_NETWORK',
}

/** The stage of the deal */
export enum DealStage {
  Cold = 'COLD',
  Hot = 'HOT',
  Interview = 'INTERVIEW',
  Lost = 'LOST',
  Matching = 'MATCHING',
  NeedBeAnalyzed = 'NEED_BE_ANALYZED',
  Renewal = 'RENEWAL',
  Sent = 'SENT',
  Won = 'WON',
}

/** The strategy of the deal */
export enum DealStrategy {
  CorpoDeal = 'CORPO_DEAL',
  CustomDeal = 'CUSTOM_DEAL',
  FeeDeal = 'FEE_DEAL',
  OpenDeal = 'OPEN_DEAL',
  RohDeal = 'ROH_DEAL',
  SuperstudioDeal = 'SUPERSTUDIO_DEAL',
  TmDeal = 'TM_DEAL',
}

export type DealTalentSelection = {
  agreedRatecard?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dealId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  longListCount: Array<Scalars['String']['output']>;
  manuallyAdded: Scalars['Boolean']['output'];
  matchingCode?: Maybe<Scalars['String']['output']>;
  maybeCount: Array<Scalars['String']['output']>;
  notAvailableCount: Array<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  rejectedCount: Array<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  shortlistCount: Array<Scalars['String']['output']>;
  status: DealTalentSelectionStatus;
  talent: Talent;
  talentId: Scalars['String']['output'];
  talentPresentation?: Maybe<Scalars['String']['output']>;
  trsScore: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zohoId?: Maybe<Scalars['String']['output']>;
};

export type DealTalentSelectionConnection = {
  edges?: Maybe<Array<DealTalentSelectionEdge>>;
  nodes?: Maybe<Array<DealTalentSelection>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DealTalentSelectionEdge = {
  cursor: Scalars['String']['output'];
  node: DealTalentSelection;
};

/** The step status of the deal talent selection */
export enum DealTalentSelectionStatus {
  Maybe = 'MAYBE',
  NotAvailable = 'NOT_AVAILABLE',
  Rejected = 'REJECTED',
  Shortlist = 'SHORTLIST',
  Unchecked = 'UNCHECKED',
}

export type DealTalentSelectionsFilterInput = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  matchingCode?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<DealTalentSelectionStatus>>;
  talentId?: InputMaybe<Scalars['String']['input']>;
};

export type DealsFilterInput = {
  availability?: InputMaybe<Scalars['Boolean']['input']>;
  budget?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  businessUnit?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<DealCategory>>;
  closingDate?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  expectingCloseDate?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  expectingStartDate?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  field?: InputMaybe<Array<Scalars['String']['input']>>;
  fullRemote?: InputMaybe<Scalars['Boolean']['input']>;
  fullTime?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  maxBudget?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  query?: InputMaybe<Scalars['String']['input']>;
  ratecard?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  ratecardMax?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  scope?: InputMaybe<Array<DealScope>>;
  stage?: InputMaybe<Array<DealStage>>;
  startDate?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  targetBudget?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tools?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<DealStrategy>>;
  updatedAt?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  userId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DeleteResponse = {
  api_version: Scalars['String']['output'];
  deleted_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
  timestamp: Scalars['String']['output'];
};

export type DocumentOutputType = {
  documentId: Scalars['String']['output'];
  type: DocumentType;
  url: Scalars['String']['output'];
};

/** Document types */
export enum DocumentType {
  Cv = 'CV',
  Portfolio = 'PORTFOLIO',
  Presentation = 'PRESENTATION',
}

export type EditMetadataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EditMetadataResponse = {
  api_version: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
  timestamp: Scalars['String']['output'];
};

export type Education = {
  items: Array<EducationObject>;
  section_name: Scalars['String']['output'];
};

export type EducationObject = {
  date?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  institute?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  title_name?: Maybe<Scalars['String']['output']>;
};

export type EmailTemplate = {
  body: Array<Scalars['String']['output']>;
  category?: Maybe<TemplateCategory>;
  country: Country;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  heading: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sign: Array<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  title: Scalars['String']['output'];
  typology?: Maybe<TemplateType>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  wishes: Scalars['String']['output'];
};

export type EmailTemplateByType = {
  templates?: Maybe<Array<EmailTemplate>>;
  typology?: Maybe<TemplateType>;
};

export type EmailTemplateCommonData = {
  category?: Maybe<TemplateCategory>;
  typologies?: Maybe<Array<TemplateType>>;
};

export type EmailTemplateFilterInput = {
  category?: InputMaybe<TemplateCategory>;
  country?: InputMaybe<Country>;
  typology?: InputMaybe<TemplateType>;
};

export type EmailTemplateInput = {
  body?: InputMaybe<Array<Scalars['String']['input']>>;
  heading?: InputMaybe<Scalars['String']['input']>;
  sign?: InputMaybe<Array<Scalars['String']['input']>>;
  subject?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  wishes?: InputMaybe<Scalars['String']['input']>;
};

export type EmailTemplateOrder = {
  direction: OrderDirection;
  field: EmailTemplatesOrderField;
};

/** Properties by which experience connections can be ordered. */
export enum EmailTemplatesOrderField {
  Category = 'category',
  Country = 'country',
  CreatedAt = 'createdAt',
  Title = 'title',
  Typology = 'typology',
  UpdatedAt = 'updatedAt',
}

/** Entity types enum */
export enum EntityType {
  Agency = 'AGENCY',
  CompanyRequest = 'COMPANY_REQUEST',
  Coupon = 'COUPON',
  Order = 'ORDER',
  Skill = 'SKILL',
  Talent = 'TALENT',
  Technology = 'TECHNOLOGY',
  Token = 'TOKEN',
  User = 'USER',
}

export type Experience = {
  availables: Scalars['Int']['output'];
  bookings: Array<Guest>;
  category?: Maybe<Array<Category>>;
  categorySlug: Scalars['String']['output'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  discount?: Maybe<Scalars['Int']['output']>;
  extra?: Maybe<Array<Extra>>;
  from: Scalars['DateTime']['output'];
  hasInsurance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isFlexible: Scalars['Boolean']['output'];
  location: Scalars['String']['output'];
  orders?: Maybe<Array<Order>>;
  price: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  to: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type ExperienceConnection = {
  edges?: Maybe<Array<ExperienceEdge>>;
  nodes?: Maybe<Array<Experience>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ExperienceCountItem = {
  CANTERA: Scalars['Int']['output'];
  ELIGIBLE: Scalars['Int']['output'];
  FUTURE: Scalars['Int']['output'];
  experience: Scalars['String']['output'];
};

export type ExperienceEdge = {
  cursor: Scalars['String']['output'];
  node: Experience;
};

/** Experience goal types */
export enum ExperienceGoal {
  AdHoc = 'AD_HOC',
  ReadyMade = 'READY_MADE',
}

export type ExperienceOrder = {
  direction: OrderDirection;
  field: ExperienceOrderField;
};

/** Properties by which experience connections can be ordered. */
export enum ExperienceOrderField {
  Availables = 'availables',
  CreatedAt = 'createdAt',
  Discount = 'discount',
  From = 'from',
  Id = 'id',
  Location = 'location',
  Price = 'price',
  UpdatedAt = 'updatedAt',
}

/** Experience types */
export enum ExperienceType {
  AdHocWorkspace = 'AD_HOC_WORKSPACE',
  CmWorkspace = 'CM_WORKSPACE',
  Coworking = 'COWORKING',
  Learning = 'LEARNING',
}

export type ExperiencesFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Expertise = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ExpertiseAnalytics = {
  expertise: Scalars['String']['output'];
  skills?: Maybe<Array<SkillsCount>>;
  technologies?: Maybe<Array<TechnologiesCount>>;
};

export type ExpertiseAnalyticsFilterInput = {
  entity?: InputMaybe<EntityType>;
  skills?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<TalentStatus>>;
  technologies?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExpertiseAnalyticsResponse = {
  expertises: Array<ExpertiseAnalytics>;
};

export type ExpertiseCount = {
  count: Scalars['Int']['output'];
  expertise: Scalars['String']['output'];
  status?: Maybe<Array<AnalyticsStatusCount>>;
};

export type ExpertiseCountItem = {
  CANTERA: Scalars['Int']['output'];
  ELIGIBLE: Scalars['Int']['output'];
  FUTURE: Scalars['Int']['output'];
  expertise: Scalars['String']['output'];
};

export type ExpertiseEdge = {
  cursor: Scalars['String']['output'];
  node: Expertise;
};

export type ExpertiseInput = {
  name: Scalars['String']['input'];
  skills: Array<Scalars['String']['input']>;
  technologies?: InputMaybe<Array<TechnologyInput>>;
};

export type ExpertiseRequestInput = {
  name: Scalars['String']['input'];
  skills: Array<SkillsRequestInput>;
};

export type ExpertiseRequestType = {
  name: Scalars['String']['output'];
  skills: Array<SkillsRequestType>;
};

export type ExpertisesConnection = {
  edges?: Maybe<Array<ExpertiseEdge>>;
  nodes?: Maybe<Array<Expertise>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Extra = {
  flexible?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  insurance?: Maybe<Scalars['Int']['output']>;
  singleRoom?: Maybe<Scalars['Int']['output']>;
};

export type ExtraField = {
  items?: Maybe<Scalars['String']['output']>;
  section_name: Scalars['String']['output'];
};

export type FormatTemplateResumeInput = {
  format?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<Scalars['JSON']['input']>;
};

export type FormatTemplateResumeOutput = {
  format?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  layout?: Maybe<Scalars['JSON']['output']>;
};

export type FreelanceCount = {
  count: Scalars['Int']['output'];
  freelance: Scalars['String']['output'];
  status?: Maybe<Array<AnalyticsStatusCount>>;
};

export type FreelanceCountItem = {
  CANTERA: Scalars['Int']['output'];
  ELIGIBLE: Scalars['Int']['output'];
  FUTURE: Scalars['Int']['output'];
  freelance: Scalars['String']['output'];
};

/** Gender */
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
}

export type GenericAnalyticsOutput = {
  experiences: Array<ExperienceCountItem>;
  expertises: Array<ExpertiseCountItem>;
  freelances: Array<FreelanceCountItem>;
  languages: Array<LangCountItem>;
  statuses: Array<StatusCountItem>;
  technologies: Array<TechCountItem>;
};

export type Guest = {
  birthDate: Scalars['DateTime']['output'];
  company?: Maybe<Scalars['String']['output']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  isOwner: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  role: Scalars['String']['output'];
  roomType: RoomTypes;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type GuestInput = {
  birthDate: Scalars['DateTime']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  isOwner: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  role: Scalars['String']['input'];
  roomType: RoomTypes;
  talentId?: InputMaybe<Scalars['String']['input']>;
};

export type Industry = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InferenceReview = {
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  prediction: Scalars['String']['output'];
  review: Scalars['String']['output'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type LangCountItem = {
  count: Scalars['Int']['output'];
  fill: Scalars['String']['output'];
  language: Scalars['String']['output'];
};

export type Language = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LanguageInput = {
  language: Scalars['String']['input'];
  level: Scalars['String']['input'];
};

export type LanguageObject = {
  language?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
};

export type LanguageType = {
  language: Scalars['String']['output'];
  languageId: Scalars['String']['output'];
  level: Scalars['String']['output'];
};

export type Languages = {
  items: Array<LanguageObject>;
  section_name: Scalars['String']['output'];
};

export type LeveledSkillFilterInput = {
  levels?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Location = {
  items?: Maybe<Scalars['String']['output']>;
  section_name: Scalars['String']['output'];
};

export type Log = {
  details: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  entityType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  operationType: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  addAgency: Agency;
  addAgencyDocument: Scalars['Boolean']['output'];
  addTalent: Talent;
  addTalentDocument: Scalars['Boolean']['output'];
  archiveDeals: Scalars['Boolean']['output'];
  authenticateExternalDomain: Scalars['Boolean']['output'];
  autoMatching: AutoMatchingOutput;
  createAgency: Agency;
  createCategory: Category;
  createCompanyRequest: CompanyRequest;
  createCoupon: Coupon;
  createDealTalentSelection: Array<DealTalentSelection>;
  createExperience: Experience;
  createFreeOrder: Order;
  createInferenceReview: InferenceReview;
  createOrder: Order;
  createPaymentIntent: Payment;
  createSkill: Skill;
  createTalent: Talent;
  createTechnology: Technology;
  createUser: User;
  deleteDealTalentSelection: Array<Scalars['String']['output']>;
  deleteUnverifiedEntities: Scalars['Boolean']['output'];
  exportAgencies: Scalars['String']['output'];
  exportTalents: Scalars['String']['output'];
  generateClientToken: Scalars['String']['output'];
  generateSignature: Scalars['Boolean']['output'];
  hardDeleteAgencies: Scalars['Boolean']['output'];
  hardDeleteCoupon: Scalars['Boolean']['output'];
  hardDeleteSkills: Scalars['Boolean']['output'];
  hardDeleteTalents: Scalars['Boolean']['output'];
  hardDeleteTechnologies: Scalars['Boolean']['output'];
  hardDeleteUser: Scalars['Boolean']['output'];
  inviteAgency: Scalars['Boolean']['output'];
  inviteTalent: Scalars['Boolean']['output'];
  login: Auth;
  logout: Scalars['Boolean']['output'];
  migrateToAgency: Scalars['Boolean']['output'];
  migrateToTalent: Scalars['Boolean']['output'];
  redeemCoupon: RedeemedCouponType;
  refreshToken: Token;
  rejectAgency: Scalars['Boolean']['output'];
  rejectTalent: Scalars['Boolean']['output'];
  remindInviteAgency: Scalars['Boolean']['output'];
  remindInviteTalent: Scalars['Boolean']['output'];
  removeAgencyLanguage: Scalars['Boolean']['output'];
  removeAgencyTool: Scalars['Boolean']['output'];
  removeTalentLanguage: Scalars['Boolean']['output'];
  removeTalentTool: Scalars['Boolean']['output'];
  resendVerificationCode: VerificationCodeType;
  resetPassword: Scalars['Boolean']['output'];
  restoreAgencies: Scalars['Boolean']['output'];
  restoreTalents: Scalars['Boolean']['output'];
  restoreUser: Scalars['Boolean']['output'];
  resumeDelete: DeleteResponse;
  resumeDuplicate: SaveCvObjectResponse;
  resumeSave: SaveCvObjectResponse;
  resumeSetDefault: SetDefaultResponse;
  resumeTranslate: TranslateResponse;
  resumeUpdate: EditMetadataResponse;
  sendDealSelection: SendDealMatchingOutput;
  sendResetPasswordEmail: Scalars['Boolean']['output'];
  sendVerificationCode: VerificationCodeType;
  softDeleteAgencies: Scalars['Boolean']['output'];
  softDeleteCoupon: Scalars['Boolean']['output'];
  softDeleteOrder: Scalars['Boolean']['output'];
  softDeleteTalents: Scalars['Boolean']['output'];
  softDeleteUser: Scalars['Boolean']['output'];
  submitBookingRequest: Scalars['Boolean']['output'];
  updateAgency: Agency;
  updateAgencyBiography: Agency;
  updateAgencyDocument: Scalars['Boolean']['output'];
  updateAgencyNotes: Agency;
  updateAgencyStatus: Scalars['Boolean']['output'];
  updateCoupon: Coupon;
  updateDeal: Deal;
  updateDealTalentSelection: DealTalentSelection;
  updateEmailTemplate: EmailTemplate;
  updateEntitiesExpertises: Scalars['Boolean']['output'];
  updateExperience: Experience;
  updateInferenceReview: InferenceReview;
  updateSkill: Skill;
  updateTalent: Talent;
  updateTalentBiography: Talent;
  updateTalentDocument: Scalars['Boolean']['output'];
  updateTalentNotes: Talent;
  updateTalentStatus: Scalars['Boolean']['output'];
  updateTechnology: Technology;
  updateUser: User;
  verifyCode: VerificationCodeType;
};

export type MutationAddAgencyArgs = {
  input: CreateAgencyInput;
};

export type MutationAddAgencyDocumentArgs = {
  data: AddAgencyDocumentInput;
};

export type MutationAddTalentArgs = {
  input: CreateTalentInput;
};

export type MutationAddTalentDocumentArgs = {
  data: AddTalentDocumentInput;
};

export type MutationArchiveDealsArgs = {
  archive: Scalars['Boolean']['input'];
  ids: Array<Scalars['String']['input']>;
};

export type MutationAuthenticateExternalDomainArgs = {
  externalDomain: Scalars['String']['input'];
};

export type MutationAutoMatchingArgs = {
  id: Scalars['String']['input'];
};

export type MutationCreateAgencyArgs = {
  agencyId: Scalars['String']['input'];
  input: CreateAgencyInput;
  isBioss?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateCategoryArgs = {
  data: CreateCategoryInput;
};

export type MutationCreateCompanyRequestArgs = {
  input: CreateCompanyRequestInput;
};

export type MutationCreateCouponArgs = {
  data: CreateCouponInput;
};

export type MutationCreateDealTalentSelectionArgs = {
  dealId: Scalars['String']['input'];
  talentId: Array<Scalars['String']['input']>;
};

export type MutationCreateExperienceArgs = {
  data: CreateExperienceInput;
};

export type MutationCreateFreeOrderArgs = {
  data: CreateFreeOrderInput;
};

export type MutationCreateInferenceReviewArgs = {
  input: CreateInferenceReviewInput;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationCreatePaymentIntentArgs = {
  data: CreatePaymentInput;
};

export type MutationCreateSkillArgs = {
  input: CreateSkillInput;
};

export type MutationCreateTalentArgs = {
  input: CreateTalentInput;
  isBioss?: InputMaybe<Scalars['Boolean']['input']>;
  talentId: Scalars['String']['input'];
};

export type MutationCreateTechnologyArgs = {
  input: CreateTechnologyInput;
};

export type MutationCreateUserArgs = {
  data: CreateUserInput;
};

export type MutationDeleteDealTalentSelectionArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationExportAgenciesArgs = {
  agencyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<AgenciesFilterInput>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type MutationExportTalentsArgs = {
  filters?: InputMaybe<TalentsFilterInput>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  talentIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationGenerateSignatureArgs = {
  payload: Scalars['String']['input'];
};

export type MutationHardDeleteAgenciesArgs = {
  agencyIds: Array<Scalars['String']['input']>;
};

export type MutationHardDeleteCouponArgs = {
  couponId: Scalars['String']['input'];
};

export type MutationHardDeleteSkillsArgs = {
  skillIds: Array<Scalars['String']['input']>;
};

export type MutationHardDeleteTalentsArgs = {
  talentIds: Array<Scalars['String']['input']>;
};

export type MutationHardDeleteTechnologiesArgs = {
  technologyIds: Array<Scalars['String']['input']>;
};

export type MutationHardDeleteUserArgs = {
  userIds: Array<Scalars['String']['input']>;
};

export type MutationInviteAgencyArgs = {
  agencyId: Scalars['String']['input'];
};

export type MutationInviteTalentArgs = {
  talentId: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  data: LoginInput;
};

export type MutationMigrateToAgencyArgs = {
  talentId: Scalars['String']['input'];
};

export type MutationMigrateToTalentArgs = {
  agencyId: Scalars['String']['input'];
};

export type MutationRedeemCouponArgs = {
  input: RedeemCouponInput;
};

export type MutationRefreshTokenArgs = {
  token: Scalars['JWT']['input'];
};

export type MutationRejectAgencyArgs = {
  agencyId: Scalars['String']['input'];
  status: TalentStatus;
};

export type MutationRejectTalentArgs = {
  status: TalentStatus;
  talentId: Scalars['String']['input'];
};

export type MutationRemindInviteAgencyArgs = {
  agencyId: Scalars['String']['input'];
};

export type MutationRemindInviteTalentArgs = {
  talentId: Scalars['String']['input'];
};

export type MutationRemoveAgencyLanguageArgs = {
  agencyId: Scalars['String']['input'];
  languageId: Scalars['String']['input'];
};

export type MutationRemoveAgencyToolArgs = {
  agencyId: Scalars['String']['input'];
  technologyId: Scalars['String']['input'];
};

export type MutationRemoveTalentLanguageArgs = {
  languageId: Scalars['String']['input'];
  talentId: Scalars['String']['input'];
};

export type MutationRemoveTalentToolArgs = {
  talentId: Scalars['String']['input'];
  technologyId: Scalars['String']['input'];
};

export type MutationResendVerificationCodeArgs = {
  input: SendVerificationCodeInput;
  isBioss?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};

export type MutationRestoreAgenciesArgs = {
  agencyIds: Array<Scalars['String']['input']>;
};

export type MutationRestoreTalentsArgs = {
  talentIds: Array<Scalars['String']['input']>;
};

export type MutationRestoreUserArgs = {
  userIds: Array<Scalars['String']['input']>;
};

export type MutationResumeDeleteArgs = {
  id: Scalars['String']['input'];
  talentId: Scalars['String']['input'];
};

export type MutationResumeDuplicateArgs = {
  id: Scalars['String']['input'];
};

export type MutationResumeSaveArgs = {
  data: SaveCvObjectInput;
};

export type MutationResumeSetDefaultArgs = {
  id: Scalars['String']['input'];
  talentId: Scalars['String']['input'];
};

export type MutationResumeTranslateArgs = {
  id: Scalars['String']['input'];
  language: Scalars['String']['input'];
};

export type MutationResumeUpdateArgs = {
  data: EditMetadataInput;
};

export type MutationSendDealSelectionArgs = {
  id: Scalars['String']['input'];
};

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String']['input'];
};

export type MutationSendVerificationCodeArgs = {
  input: SendVerificationCodeInput;
  isBioss?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSoftDeleteAgenciesArgs = {
  agencyIds: Array<Scalars['String']['input']>;
};

export type MutationSoftDeleteCouponArgs = {
  couponId: Scalars['String']['input'];
};

export type MutationSoftDeleteOrderArgs = {
  orderId: Scalars['String']['input'];
};

export type MutationSoftDeleteTalentsArgs = {
  talentIds: Array<Scalars['String']['input']>;
};

export type MutationSoftDeleteUserArgs = {
  userIds: Array<Scalars['String']['input']>;
};

export type MutationSubmitBookingRequestArgs = {
  experienceId: Scalars['String']['input'];
  input: BookingRequestInput;
};

export type MutationUpdateAgencyArgs = {
  agencyId: Scalars['String']['input'];
  newAgencyData: UpdateAgencyInput;
};

export type MutationUpdateAgencyBiographyArgs = {
  agencyId: Scalars['String']['input'];
  biography: Scalars['String']['input'];
};

export type MutationUpdateAgencyDocumentArgs = {
  data: UpdateAgencyDocumentInput;
};

export type MutationUpdateAgencyNotesArgs = {
  agencyId: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type MutationUpdateAgencyStatusArgs = {
  agencyId: Scalars['String']['input'];
  status: TalentStatus;
};

export type MutationUpdateCouponArgs = {
  couponId: Scalars['String']['input'];
  data: UpdateCouponInput;
};

export type MutationUpdateDealArgs = {
  data: UpdateDealInput;
  id: Scalars['String']['input'];
};

export type MutationUpdateDealTalentSelectionArgs = {
  data: UpdateDealTalentSelectionInput;
  id: Scalars['String']['input'];
};

export type MutationUpdateEmailTemplateArgs = {
  data: EmailTemplateInput;
  id: Scalars['String']['input'];
};

export type MutationUpdateEntitiesExpertisesArgs = {
  entity: EntityType;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<TalentStatus>;
};

export type MutationUpdateExperienceArgs = {
  data: UpdateExperienceInput;
  experienceId: Scalars['String']['input'];
};

export type MutationUpdateInferenceReviewArgs = {
  input: UpdateInferenceReviewInput;
};

export type MutationUpdateSkillArgs = {
  input: UpdateSkillInput;
  skillId: Scalars['String']['input'];
};

export type MutationUpdateTalentArgs = {
  newTalentData: UpdateTalentInput;
  talentId: Scalars['String']['input'];
};

export type MutationUpdateTalentBiographyArgs = {
  biography: Scalars['String']['input'];
  talentId: Scalars['String']['input'];
};

export type MutationUpdateTalentDocumentArgs = {
  data: UpdateTalentDocumentInput;
};

export type MutationUpdateTalentNotesArgs = {
  notes: Scalars['String']['input'];
  talentId: Scalars['String']['input'];
};

export type MutationUpdateTalentStatusArgs = {
  status: TalentStatus;
  talentId: Scalars['String']['input'];
};

export type MutationUpdateTechnologyArgs = {
  input: UpdateTechnologyInput;
  technologyId: Scalars['String']['input'];
};

export type MutationUpdateUserArgs = {
  newUserData: UpdateUserInput;
  userId: Scalars['String']['input'];
};

export type MutationVerifyCodeArgs = {
  input: VerifyCodeInput;
};

export type NameAndSurname = {
  items: Scalars['String']['output'];
  section_name: Scalars['String']['output'];
};

export type NextActionType = {
  redirect_to_url?: Maybe<RedirectToUrl>;
  type?: Maybe<Scalars['String']['output']>;
};

export type NotesDetailsType = {
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type OnboardingData = {
  languages: Languages;
  location: Location;
  specialities: Specialities;
};

/** Onboarding type */
export enum OnboardingType {
  Agency = 'AGENCY',
  Talent = 'TALENT',
}

export type Order = {
  billing?: Maybe<BillingType>;
  coupon?: Maybe<Scalars['String']['output']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  experience?: Maybe<Experience>;
  experienceId: Scalars['String']['output'];
  flexible?: Maybe<Scalars['Boolean']['output']>;
  guests?: Maybe<Array<Guest>>;
  id: Scalars['ID']['output'];
  insurance?: Maybe<Scalars['Boolean']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
  totalAmount: Scalars['Float']['output'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderConnection = {
  edges?: Maybe<Array<OrderEdge>>;
  nodes?: Maybe<Array<Order>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type OrderEdge = {
  cursor: Scalars['String']['output'];
  node: Order;
};

/** Order status enum */
export enum OrderStatus {
  Confirmed = 'CONFIRMED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
}

export type OrdersFilterInput = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  experienceId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatus>;
};

export type OrdersOrder = {
  direction: OrderDirection;
  field: OrdersOrderField;
};

/** Properties by which order connections can be ordered. */
export enum OrdersOrderField {
  CreatedAt = 'createdAt',
  Id = 'id',
}

export type PageInfo = {
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Payment = {
  amount: Scalars['Int']['output'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  nextAction?: Maybe<NextActionType>;
  order: Order;
  status: Scalars['String']['output'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type PortfolioLinkInput = {
  link: PortfolioType;
  url: Scalars['String']['input'];
};

export type PortfolioLinkType = {
  link?: Maybe<PortfolioType>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Portfolio types enum */
export enum PortfolioType {
  Behance = 'BEHANCE',
  Dribbble = 'DRIBBBLE',
  Github = 'GITHUB',
  Linkedin = 'LINKEDIN',
  Medium = 'MEDIUM',
  Url = 'URL',
}

/** The various statuses a prediction can have */
export enum PredictionStatus {
  Cantera = 'CANTERA',
  Eligible = 'ELIGIBLE',
  Future = 'FUTURE',
  Undefined = 'UNDEFINED',
}

export type PrivateBillingInput = {
  birthCity: Scalars['String']['input'];
  birthProvince: Scalars['String']['input'];
  nationality: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  registeredResidence: RegisteredResidence;
  taxIdCode: Scalars['String']['input'];
};

export type PrivateBillingType = {
  birthCity: Scalars['String']['output'];
  birthProvince: Scalars['String']['output'];
  nationality: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  registeredResidence: Residence;
  taxIdCode: Scalars['String']['output'];
};

export type Query = {
  agencies: AgencyConnection;
  agenciesCounter: Array<StatusCount>;
  agency: Agency;
  categories: CategoryConnection;
  category: Category;
  commonAnalytics: Array<CommonAnalyticsResponse>;
  companiesRequest: CompaniesRequestConnection;
  coupon: Coupon;
  coupons: CouponConnection;
  deal: Deal;
  dealAllStar: SimpleDealConnection;
  dealCommonData: DealCommonData;
  dealTalentSelections: DealTalentSelectionConnection;
  deals: DealConnection;
  emailTemplate: EmailTemplate;
  emailTemplates: Array<EmailTemplateByType>;
  emailTemplatesByType: Array<EmailTemplate>;
  emailTemplatesCommonData: Array<EmailTemplateCommonData>;
  experience: Experience;
  experiences: ExperienceConnection;
  expertiseAnalytics: ExpertiseAnalyticsResponse;
  expertises: ExpertisesConnection;
  genericAnalytics: GenericAnalyticsOutput;
  onboardingCommonData: TalentCommonData;
  order: Order;
  orders: OrderConnection;
  resumeHealthCheck: ResumeHealthCheckOutput;
  resumeListAll: Array<ResumeTemplateEntry>;
  resumeRetrieve: ResumeRetrieveOutput;
  resumeRetrieveById: ResumeRetrieveOutput;
  searchAgencies: AgencyConnection;
  searchTalents: TalentConnection;
  skill: Skill;
  skills: SkillsConnection;
  talent: Talent;
  talentCommonData: TalentCommonData;
  talents: TalentConnection;
  talentsAnalytics: TalentsAnalyticsResponse;
  talentsCounter: Array<StatusCount>;
  technologies: TechnologiesConnection;
  technology: Technology;
  topIndustries: Array<Industry>;
  topLanguages: Array<Language>;
  topTools: Array<Technology>;
  user: User;
  userAnalytics: UserAnalyticsResponse;
  userLogs: Array<Log>;
  users: UserConnection;
  usersAnalytics: Array<UserAnalyticsResponse>;
  workplaceAnalytics: WorkplaceAnalyticsResponse;
};

export type QueryAgenciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AgenciesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgenciesOrder>;
};

export type QueryAgencyArgs = {
  agencyId: Scalars['String']['input'];
};

export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CategoriesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CategoryOrder>;
};

export type QueryCategoryArgs = {
  categoryId: Scalars['String']['input'];
};

export type QueryCommonAnalyticsArgs = {
  filters?: InputMaybe<CommonsAnalyticsFilterInput>;
};

export type QueryCompaniesRequestArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCouponArgs = {
  couponId: Scalars['String']['input'];
};

export type QueryCouponsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CouponFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CouponsOrder>;
};

export type QueryDealArgs = {
  id: Scalars['String']['input'];
};

export type QueryDealAllStarArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DealsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealOrder>;
};

export type QueryDealTalentSelectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DealTalentSelectionsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDealsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DealsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DealOrder>;
};

export type QueryEmailTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryEmailTemplatesArgs = {
  filters?: InputMaybe<EmailTemplateFilterInput>;
  orderBy?: InputMaybe<EmailTemplateOrder>;
};

export type QueryEmailTemplatesByTypeArgs = {
  typology: TemplateType;
};

export type QueryExperienceArgs = {
  experienceId: Scalars['String']['input'];
};

export type QueryExperiencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ExperiencesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExperienceOrder>;
};

export type QueryExpertiseAnalyticsArgs = {
  filters?: InputMaybe<ExpertiseAnalyticsFilterInput>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrderArgs = {
  orderId: Scalars['String']['input'];
};

export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrdersFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrdersOrder>;
};

export type QueryResumeListAllArgs = {
  metadataOnly?: InputMaybe<Scalars['Boolean']['input']>;
  talentId: Scalars['String']['input'];
};

export type QueryResumeRetrieveArgs = {
  talentId: Scalars['String']['input'];
};

export type QueryResumeRetrieveByIdArgs = {
  id: Scalars['String']['input'];
  talentId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchAgenciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AgenciesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgenciesOrder>;
};

export type QuerySearchTalentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TalentsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TalentOrder>;
};

export type QuerySkillArgs = {
  skillId: Scalars['String']['input'];
};

export type QuerySkillsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTalentArgs = {
  talentId: Scalars['String']['input'];
};

export type QueryTalentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TalentsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TalentOrder>;
};

export type QueryTalentsAnalyticsArgs = {
  filters?: InputMaybe<TalentAnalyticsFilterInput>;
};

export type QueryTechnologiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTechnologyArgs = {
  technologyId: Scalars['String']['input'];
};

export type QueryTopIndustriesArgs = {
  filters?: InputMaybe<TopSearchInput>;
};

export type QueryTopLanguagesArgs = {
  filters?: InputMaybe<TopSearchInput>;
};

export type QueryTopToolsArgs = {
  filters?: InputMaybe<TopSearchInput>;
};

export type QueryUserArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUserAnalyticsArgs = {
  filters?: InputMaybe<UserAnalyticsFilterInput>;
};

export type QueryUserLogsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UsersFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrder>;
};

export type QueryUsersAnalyticsArgs = {
  filters: UserAnalyticsFilterInput;
};

export type QueryWorkplaceAnalyticsArgs = {
  filters?: InputMaybe<WorkplaceAnalyticsFilterInput>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

/** Possible operators to filter a list when the `filters` argument is passed. */
export enum QueryOperators {
  And = 'AND',
  Or = 'OR',
}

export type RedeemCouponInput = {
  code: Scalars['String']['input'];
  experienceId: Scalars['String']['input'];
  guests: Array<GuestInput>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RedeemedCouponType = {
  discountPercentage: Scalars['Int']['output'];
  discountedAmount: Scalars['Float']['output'];
  totalAmount: Scalars['Float']['output'];
};

export type RedirectToUrl = {
  return_url?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RegisteredResidence = {
  address: Scalars['String']['input'];
  addressDetails?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  province: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

/** Company request types */
export enum RequestType {
  TalentAsService = 'TALENT_AS_SERVICE',
  TalentRetention = 'TALENT_RETENTION',
}

export type ResetPasswordInput = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type Residence = {
  address: Scalars['String']['output'];
  addressDetails: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  province: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type ResumeHealthCheckOutput = {
  api_version: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
  timestamp: Scalars['String']['output'];
};

export type ResumeRetrieveOutput = {
  all_fields: AllFields;
  api_version: Scalars['String']['output'];
  baseline?: Maybe<BaselineResume>;
  description?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  list_all?: Maybe<BaseListAllResponse>;
  onboarding_data?: Maybe<OnboardingData>;
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
  talentId: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type ResumeTemplateEntry = {
  createdAt: Scalars['String']['output'];
  cvresumeObject: TemplateResumeOutput;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  talentId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

/** Room types */
export enum RoomTypes {
  Double = 'DOUBLE',
  Single = 'SINGLE',
}

export type SaveCvObjectInput = {
  cvresumeObject: TemplateResumeInput;
  id?: InputMaybe<Scalars['String']['input']>;
  talentId: Scalars['String']['input'];
};

export type SaveCvObjectResponse = {
  api_version: Scalars['String']['output'];
  created_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
  talentId: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type ScreeningResult = {
  expertises: Array<ExpertiseCount>;
  totalCount: Scalars['Int']['output'];
};

export type SendDealMatchingOutput = {
  dealId: Scalars['String']['output'];
  dealTalentSelections: Array<Scalars['String']['output']>;
};

export type SendVerificationCodeInput = {
  country: Country;
  email: Scalars['String']['input'];
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
  type: OnboardingType;
};

export type SetDefaultResponse = {
  api_version: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
  timestamp: Scalars['String']['output'];
};

export type SimpleDeal = {
  availability?: Maybe<Scalars['Boolean']['output']>;
  businessUnit?: Maybe<Scalars['String']['output']>;
  category?: Maybe<DealCategory>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  daysNumber?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expectingCloseDate?: Maybe<Scalars['DateTime']['output']>;
  expectingStartDate?: Maybe<Scalars['DateTime']['output']>;
  field?: Maybe<Array<Scalars['String']['output']>>;
  fullRemote?: Maybe<Scalars['Boolean']['output']>;
  fullTime?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  onsiteTrip?: Maybe<Scalars['String']['output']>;
  partTime?: Maybe<Scalars['String']['output']>;
  poolCount?: Maybe<Scalars['Int']['output']>;
  ratecard?: Maybe<Scalars['Float']['output']>;
  ratecardMax?: Maybe<Scalars['Float']['output']>;
  refCode: Scalars['String']['output'];
  scope?: Maybe<Array<DealScope>>;
  shortlistCount?: Maybe<Scalars['Int']['output']>;
  speciality?: Maybe<Scalars['String']['output']>;
  stage?: Maybe<DealStage>;
  title: Scalars['String']['output'];
  tools?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<DealStrategy>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type SimpleDealConnection = {
  edges?: Maybe<Array<SimpleDealEdge>>;
  nodes?: Maybe<Array<SimpleDeal>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SimpleDealEdge = {
  cursor: Scalars['String']['output'];
  node: SimpleDeal;
};

export type Skill = {
  expertise: Expertise;
  expertiseId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SkillDetailsInput = {
  rate?: InputMaybe<Scalars['String']['input']>;
  seniority?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['Int']['input'];
};

export type SkillEdge = {
  cursor: Scalars['String']['output'];
  node: Skill;
};

export type SkillFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SkillsConnection = {
  edges?: Maybe<Array<SkillEdge>>;
  nodes?: Maybe<Array<Skill>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SkillsCount = {
  count: Scalars['Int']['output'];
  skill: Scalars['String']['output'];
  status?: Maybe<Array<AnalyticsStatusCount>>;
};

export type SkillsData = {
  expertise: Scalars['String']['output'];
  skills: Array<Scalars['String']['output']>;
};

export type SkillsRequestInput = {
  skill: Scalars['String']['input'];
  units: Scalars['Int']['input'];
  unitsDetails: Array<SkillDetailsInput>;
};

export type SkillsRequestType = {
  skill: Scalars['String']['output'];
  units: Scalars['String']['output'];
};

export type SoftSkills = {
  items: Array<Scalars['String']['output']>;
  section_name: Scalars['String']['output'];
};

export type Specialities = {
  items?: Maybe<Scalars['String']['output']>;
  section_name: Scalars['String']['output'];
};

export type SpecialityType = {
  expertise: Scalars['String']['output'];
  skills: Array<Scalars['String']['output']>;
};

export type StatusCount = {
  _count: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export type StatusCountItem = {
  count: Scalars['Int']['output'];
  fill: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type Talent = {
  additionalInfo?: Maybe<Scalars['String']['output']>;
  biography?: Maybe<Scalars['String']['output']>;
  collaborationNotes?: Maybe<Scalars['String']['output']>;
  collaborations?: Maybe<Array<Scalars['String']['output']>>;
  country?: Maybe<Country>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  digitalNomad?: Maybe<Scalars['Boolean']['output']>;
  documents?: Maybe<Array<DocumentOutputType>>;
  email?: Maybe<Scalars['String']['output']>;
  experience?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  freelance?: Maybe<Scalars['String']['output']>;
  future?: Maybe<Scalars['String']['output']>;
  github?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Scalars['String']['output']>>;
  inferenceReview?: Maybe<InferenceReview>;
  internationalProjects?: Maybe<Scalars['Boolean']['output']>;
  interviewRecapSent?: Maybe<Scalars['Boolean']['output']>;
  interviewSent?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Array<LanguageType>>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  newsletter?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notesDetails?: Maybe<NotesDetailsType>;
  openToEmployment?: Maybe<Scalars['Boolean']['output']>;
  portfolioLinks?: Maybe<Array<PortfolioLinkType>>;
  prediction?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['String']['output']>;
  rejectSent?: Maybe<Scalars['Boolean']['output']>;
  reminderSent?: Maybe<Scalars['Boolean']['output']>;
  search?: Maybe<Array<Scalars['String']['output']>>;
  specialities?: Maybe<Array<SpecialityType>>;
  status?: Maybe<TalentStatus>;
  termsAcceptance?: Maybe<Scalars['Boolean']['output']>;
  tools?: Maybe<Array<ToolType>>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  workplace?: Maybe<Scalars['String']['output']>;
  zohoId?: Maybe<Scalars['String']['output']>;
};

export type TalentAnalyticsFilterInput = {
  entity: EntityType;
  timeRange?: InputMaybe<Array<TimeRange>>;
};

export type TalentAsServiceInput = {
  expertise: Array<ExpertiseRequestInput>;
  project: Scalars['String']['input'];
  projectEnd: Scalars['DateTime']['input'];
  projectStart: Scalars['DateTime']['input'];
};

export type TalentAsServiceType = {
  expertise: Array<ExpertiseRequestType>;
  project: Scalars['String']['output'];
  projectEnd: Scalars['DateTime']['output'];
  projectStart: Scalars['DateTime']['output'];
};

export type TalentCommonData = {
  collaborations: Array<Scalars['String']['output']>;
  countries: Array<Country>;
  employees: Array<Scalars['String']['output']>;
  freelance: Array<Scalars['String']['output']>;
  industries: Array<Scalars['String']['output']>;
  languages: Array<Scalars['String']['output']>;
  prediction: Array<PredictionStatus>;
  rates: Array<Scalars['String']['output']>;
  seniorities: Array<Scalars['String']['output']>;
  specialities: Array<SkillsData>;
  status: Array<TalentStatus>;
  tools: Array<ToolsData>;
  workplaces: Array<Scalars['String']['output']>;
};

export type TalentConnection = {
  edges?: Maybe<Array<TalentEdge>>;
  nodes?: Maybe<Array<Talent>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TalentEdge = {
  cursor: Scalars['String']['output'];
  node: Talent;
};

export type TalentOrder = {
  direction: OrderDirection;
  field: TalentOrderField;
};

/** Properties by which talent connections can be ordered. */
export enum TalentOrderField {
  Country = 'country',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
}

export type TalentRetentionInput = {
  destination?: InputMaybe<Array<Scalars['String']['input']>>;
  duration?: InputMaybe<Scalars['String']['input']>;
  experienceGoal: ExperienceGoal;
  experienceType: ExperienceType;
  package?: InputMaybe<Scalars['String']['input']>;
  people?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TalentRetentionType = {
  destination?: Maybe<Array<Scalars['String']['output']>>;
  duration?: Maybe<Scalars['String']['output']>;
  experienceGoal: ExperienceGoal;
  experienceType: ExperienceType;
  package: Scalars['String']['output'];
  people?: Maybe<Scalars['String']['output']>;
  where?: Maybe<Array<Scalars['String']['output']>>;
};

/** Talent status */
export enum TalentStatus {
  AllStar = 'ALL_STAR',
  Cantera = 'CANTERA',
  Eligible = 'ELIGIBLE',
  Future = 'FUTURE',
  Inbox = 'INBOX',
  Invited = 'INVITED',
  NotVerified = 'NOT_VERIFIED',
}

export type TalentsAnalyticsResponse = {
  status?: Maybe<Array<TalentsStatus>>;
  totalCount: Scalars['Int']['output'];
};

export type TalentsFilterInput = {
  collaborations?: InputMaybe<Array<Scalars['String']['input']>>;
  country?: InputMaybe<Country>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  digitalNomad?: InputMaybe<Scalars['Boolean']['input']>;
  freelance?: InputMaybe<Array<Scalars['String']['input']>>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  internationalProjects?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<LeveledSkillFilterInput>>;
  openToEmployment?: InputMaybe<Scalars['Boolean']['input']>;
  operator?: InputMaybe<QueryOperators>;
  prediction?: InputMaybe<Array<PredictionStatus>>;
  rates?: InputMaybe<Array<Scalars['String']['input']>>;
  seniorities?: InputMaybe<Array<Scalars['String']['input']>>;
  specialities?: InputMaybe<Array<SkillFilterInput>>;
  status?: InputMaybe<TalentStatus>;
  statuses?: InputMaybe<Array<TalentStatus>>;
  tools?: InputMaybe<Array<LeveledSkillFilterInput>>;
  workplaces?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TalentsStatus = {
  ranges: Array<TimerangeCount>;
  status: TalentStatus;
};

export type TechCountItem = {
  count: Scalars['Int']['output'];
  technology: Scalars['String']['output'];
};

export type TechnicalSkills = {
  items: Array<Scalars['String']['output']>;
  section_name: Scalars['String']['output'];
};

export type TechnologiesConnection = {
  edges?: Maybe<Array<TechnologyEdge>>;
  nodes?: Maybe<Array<Technology>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TechnologiesCount = {
  count: Scalars['Int']['output'];
  status?: Maybe<Array<AnalyticsStatusCount>>;
  technology: Scalars['String']['output'];
};

export type Technology = {
  expertises: Array<Expertise>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TechnologyEdge = {
  cursor: Scalars['String']['output'];
  node: Technology;
};

export type TechnologyInput = {
  level: Scalars['String']['input'];
  technology: Scalars['String']['input'];
};

export type TechnologyType = {
  level: Scalars['String']['output'];
  technology: Scalars['String']['output'];
  technologyId: Scalars['String']['output'];
};

/** Email template category enum */
export enum TemplateCategory {
  Experience = 'EXPERIENCE',
  Talent = 'TALENT',
  User = 'USER',
}

export type TemplateResumeInput = {
  bio?: InputMaybe<FormatTemplateResumeInput>;
  education?: InputMaybe<Array<FormatTemplateResumeInput>>;
  extra_field?: InputMaybe<FormatTemplateResumeInput>;
  languages?: InputMaybe<Array<FormatTemplateResumeInput>>;
  location?: InputMaybe<FormatTemplateResumeInput>;
  name_and_surname?: InputMaybe<FormatTemplateResumeInput>;
  speciality?: InputMaybe<FormatTemplateResumeInput>;
  technical_skills?: InputMaybe<Array<FormatTemplateResumeInput>>;
  work_experiences?: InputMaybe<Array<FormatTemplateResumeInput>>;
};

export type TemplateResumeOutput = {
  bio?: Maybe<FormatTemplateResumeOutput>;
  education?: Maybe<Array<FormatTemplateResumeOutput>>;
  extra_field?: Maybe<FormatTemplateResumeOutput>;
  languages?: Maybe<Array<FormatTemplateResumeOutput>>;
  location?: Maybe<FormatTemplateResumeOutput>;
  name_and_surname?: Maybe<FormatTemplateResumeOutput>;
  speciality?: Maybe<FormatTemplateResumeOutput>;
  technical_skills?: Maybe<Array<FormatTemplateResumeOutput>>;
  work_experiences?: Maybe<Array<FormatTemplateResumeOutput>>;
};

/** Email template typology enum */
export enum TemplateType {
  CompanyRequest = 'COMPANY_REQUEST',
  RemindInvite = 'REMIND_INVITE',
  ResetPwd = 'RESET_PWD',
  SendBookingReq = 'SEND_BOOKING_REQ',
  SendCode = 'SEND_CODE',
  SendCodeBioss = 'SEND_CODE_BIOSS',
  SendInterviewRecap = 'SEND_INTERVIEW_RECAP',
  SendInvite = 'SEND_INVITE',
  SendOrderRecap = 'SEND_ORDER_RECAP',
  SendReject = 'SEND_REJECT',
  SendWelcome = 'SEND_WELCOME',
  SendWelcomeBioss = 'SEND_WELCOME_BIOSS',
}

/** Time frames types */
export enum TimeRange {
  Month = 'MONTH',
  Today = 'TODAY',
  Total = 'TOTAL',
  Week = 'WEEK',
}

export type TimerangeCount = {
  count: Scalars['Int']['output'];
  range: TimeRange;
};

export type Token = {
  /** JWT access token */
  accessToken: Scalars['JWT']['output'];
  /** JWT refresh token */
  refreshToken: Scalars['JWT']['output'];
};

export type ToolType = {
  expertise: Scalars['String']['output'];
  technologies: Array<TechnologyType>;
};

export type ToolsData = {
  expertise: Scalars['String']['output'];
  technologies: Array<Scalars['String']['output']>;
};

export type TopSearchInput = {
  count: Scalars['Int']['input'];
  entity: EntityType;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TranslateResponse = {
  api_version: Scalars['String']['output'];
  created_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
  timestamp: Scalars['String']['output'];
};

export type UpdateAgencyDocumentInput = {
  agencyId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
};

export type UpdateAgencyInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  biography?: InputMaybe<Scalars['String']['input']>;
  collaborations?: InputMaybe<Array<Scalars['String']['input']>>;
  collaborationsNotes?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  email?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<Scalars['String']['input']>;
  experience?: InputMaybe<Scalars['String']['input']>;
  expertise?: InputMaybe<Array<ExpertiseInput>>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  internationalProjects?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<LanguageInput>>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  portfolioLinks?: InputMaybe<Array<PortfolioLinkInput>>;
  status?: InputMaybe<TalentStatus>;
  vat?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  workplace?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCouponInput = {
  associatedDomain?: InputMaybe<Scalars['String']['input']>;
  associatedEmail?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  experienceId?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  maxRedemptions?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDealInput = {
  availability?: InputMaybe<Scalars['Boolean']['input']>;
  budget?: InputMaybe<Scalars['Int']['input']>;
  closingDate?: InputMaybe<Scalars['DateTime']['input']>;
  expectingCloseDate?: InputMaybe<Scalars['DateTime']['input']>;
  expectingStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  field?: InputMaybe<Array<Scalars['String']['input']>>;
  fullRemote?: InputMaybe<Scalars['Boolean']['input']>;
  fullTime?: InputMaybe<Scalars['Boolean']['input']>;
  maxBudget?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  ratecard?: InputMaybe<Scalars['Int']['input']>;
  ratecardMax?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  targetBudget?: InputMaybe<Scalars['Int']['input']>;
  tools?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateDealTalentSelectionInput = {
  agreedRatecard?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DealTalentSelectionStatus>;
  talentPresentation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExperienceInput = {
  availables?: InputMaybe<Scalars['Int']['input']>;
  categorySlug?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  hasInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isFlexible?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateInferenceReviewInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  review?: InputMaybe<TalentStatus>;
  talentId: Scalars['String']['input'];
};

export type UpdateSkillInput = {
  expertiseId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTalentDocumentInput = {
  documentId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  talentId: Scalars['String']['input'];
};

export type UpdateTalentInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  biography?: InputMaybe<Scalars['String']['input']>;
  collaborations?: InputMaybe<Array<Scalars['String']['input']>>;
  collaborationsNotes?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  digitalNomad?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  experience?: InputMaybe<Scalars['String']['input']>;
  expertise?: InputMaybe<Array<ExpertiseInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  freelance?: InputMaybe<Scalars['String']['input']>;
  github?: InputMaybe<Scalars['String']['input']>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  internationalProjects?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<LanguageInput>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  openToEmployment?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioLinks?: InputMaybe<Array<PortfolioLinkInput>>;
  rate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TalentStatus>;
  workplace?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTechnologyInput = {
  expertiseIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  calendlyUrl?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  sign?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  calendlyUrl?: Maybe<Scalars['String']['output']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  role: UserRole;
  sign?: Maybe<Scalars['String']['output']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};

export type UserAnalytics = {
  interview?: Maybe<ScreeningResult>;
  range: Scalars['String']['output'];
  screening?: Maybe<ScreeningResult>;
  totalCount: Scalars['Int']['output'];
};

export type UserAnalyticsFilterInput = {
  entity: Array<EntityType>;
  months?: InputMaybe<Array<Scalars['Int']['input']>>;
  timeRange?: InputMaybe<Array<TimeRange>>;
  years?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserAnalyticsResponse = {
  analytics: Array<UserAnalytics>;
  firstname: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
};

export type UserConnection = {
  edges?: Maybe<Array<UserEdge>>;
  nodes?: Maybe<Array<User>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserEdge = {
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserOrder = {
  direction: OrderDirection;
  field: UserOrderField;
};

/** Properties by which user connections can be ordered. */
export enum UserOrderField {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
}

/** User roles */
export enum UserRole {
  Admin = 'ADMIN',
  AllStar = 'ALL_STAR',
  Client = 'CLIENT',
  EventManager = 'EVENT_MANAGER',
  Junior = 'JUNIOR',
  Mentor = 'MENTOR',
  Supervisor = 'SUPERVISOR',
}

export type UsersFilterInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<UserRole>;
};

export type VatBillingInput = {
  businessName: Scalars['String']['input'];
  registeredResidence: RegisteredResidence;
  vat: Scalars['String']['input'];
};

export type VatBillingType = {
  businessName: Scalars['String']['output'];
  registeredResidence: Residence;
  vat: Scalars['String']['output'];
};

export type VerificationCodeType = {
  id: Scalars['String']['output'];
};

export type VerifyCodeInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  type: OnboardingType;
};

export type WorkExperience = {
  items: Array<WorkExperienceObject>;
  section_name: Scalars['String']['output'];
};

export type WorkExperienceObject = {
  company?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  from_date?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  to_date?: Maybe<Scalars['String']['output']>;
};

export type WorkplaceAnalyticsFilterInput = {
  entity?: InputMaybe<EntityType>;
  freelance?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<TalentStatus>>;
  workplaces?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkplaceAnalyticsResponse = {
  freelance?: Maybe<Array<FreelanceCount>>;
  workplaces?: Maybe<Array<WorkplaceCount>>;
};

export type WorkplaceCount = {
  count: Scalars['Int']['output'];
  status?: Maybe<Array<AnalyticsStatusCount>>;
  workplace: Scalars['String']['output'];
};

export type AgencyFragment = {
  additionalInfo?: string;
  biography?: string;
  collaborationNotes?: string;
  collaborations?: Array<string>;
  companyName?: string;
  country?: Country;
  createdAt: any;
  deletedAt?: any;
  email?: string;
  employees?: string;
  experience?: string;
  id: string;
  industries?: Array<string>;
  internationalProjects?: boolean;
  interviewSent?: boolean;
  mobile?: string;
  newsletter?: boolean;
  notes?: string;
  rejectSent?: boolean;
  reminderSent?: boolean;
  search?: Array<string>;
  status?: TalentStatus;
  termsAcceptance?: boolean;
  updatedAt: any;
  vat?: string;
  website?: string;
  workplace?: string;
  documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
  languages: Array<{ language: string; languageId: string; level: string }>;
  notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
  portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
  specialities?: Array<{ expertise: string; skills: Array<string> }>;
  tools?: Array<{
    expertise: string;
    technologies: Array<{ level: string; technology: string; technologyId: string }>;
  }>;
};

export type AnalyticsFragment = {
  range: string;
  totalCount: number;
  interview?: {
    totalCount: number;
    expertises: Array<{
      count: number;
      expertise: string;
      status?: Array<{ count: number; status: string }>;
    }>;
  };
  screening?: {
    totalCount: number;
    expertises: Array<{
      count: number;
      expertise: string;
      status?: Array<{ count: number; status: string }>;
    }>;
  };
};

export type BillingFragment = {
  termsAcceptance?: boolean;
  private?: {
    birthCity: string;
    birthProvince: string;
    nationality: string;
    phone: string;
    taxIdCode: string;
    registeredResidence: {
      address: string;
      addressDetails: string;
      city: string;
      country: string;
      province: string;
      zipCode: string;
    };
  };
  vat?: {
    businessName: string;
    vat: string;
    registeredResidence: {
      address: string;
      addressDetails: string;
      city: string;
      country: string;
      province: string;
      zipCode: string;
    };
  };
};

export type BookingsFragment = {
  id: string;
  birthDate: any;
  company?: string;
  createdAt: any;
  email: string;
  firstName: string;
  gender?: Gender;
  isOwner: boolean;
  lastName: string;
  role: string;
  roomType: RoomTypes;
  updatedAt: any;
};

export type CategoryFragment = {
  id: string;
  createdAt: any;
  updatedAt: any;
  slug: string;
  title: string;
  experiences?: Array<{
    id: string;
    availables: number;
    categorySlug: string;
    createdAt: any;
    discount?: number;
    from: any;
    hasInsurance: boolean;
    isActive: boolean;
    isFlexible: boolean;
    location: string;
    price: number;
    slug: string;
    to: any;
    updatedAt: any;
    bookings: Array<{
      id: string;
      birthDate: any;
      company?: string;
      createdAt: any;
      email: string;
      firstName: string;
      gender?: Gender;
      isOwner: boolean;
      lastName: string;
      role: string;
      roomType: RoomTypes;
      updatedAt: any;
    }>;
    category?: Array<{ id: string; createdAt: any; updatedAt: any; slug: string; title: string }>;
    extra?: Array<{ id: string; flexible?: number; insurance?: number; singleRoom?: number }>;
    orders?: Array<{
      id: string;
      coupon?: string;
      createdAt: any;
      experienceId: string;
      flexible?: boolean;
      insurance?: boolean;
      paymentId?: string;
      status?: OrderStatus;
      totalAmount: number;
      updatedAt: any;
      billing?: {
        termsAcceptance?: boolean;
        private?: {
          birthCity: string;
          birthProvince: string;
          nationality: string;
          phone: string;
          taxIdCode: string;
          registeredResidence: {
            address: string;
            addressDetails: string;
            city: string;
            country: string;
            province: string;
            zipCode: string;
          };
        };
        vat?: {
          businessName: string;
          vat: string;
          registeredResidence: {
            address: string;
            addressDetails: string;
            city: string;
            country: string;
            province: string;
            zipCode: string;
          };
        };
      };
      experience?: {
        id: string;
        availables: number;
        categorySlug: string;
        createdAt: any;
        discount?: number;
        from: any;
        hasInsurance: boolean;
        isActive: boolean;
        isFlexible: boolean;
        location: string;
        price: number;
        slug: string;
        to: any;
        updatedAt: any;
        bookings: Array<{
          id: string;
          birthDate: any;
          company?: string;
          createdAt: any;
          email: string;
          firstName: string;
          gender?: Gender;
          isOwner: boolean;
          lastName: string;
          role: string;
          roomType: RoomTypes;
          updatedAt: any;
        }>;
        category?: Array<{
          id: string;
          createdAt: any;
          updatedAt: any;
          slug: string;
          title: string;
          experiences?: Array<{
            id: string;
            availables: number;
            categorySlug: string;
            createdAt: any;
            discount?: number;
            from: any;
            hasInsurance: boolean;
            isActive: boolean;
            isFlexible: boolean;
            location: string;
            price: number;
            slug: string;
            to: any;
            updatedAt: any;
            bookings: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
            category?: Array<{
              id: string;
              createdAt: any;
              updatedAt: any;
              slug: string;
              title: string;
            }>;
            extra?: Array<{
              id: string;
              flexible?: number;
              insurance?: number;
              singleRoom?: number;
            }>;
            orders?: Array<{
              id: string;
              coupon?: string;
              createdAt: any;
              experienceId: string;
              flexible?: boolean;
              insurance?: boolean;
              paymentId?: string;
              status?: OrderStatus;
              totalAmount: number;
              updatedAt: any;
              billing?: {
                termsAcceptance?: boolean;
                private?: {
                  birthCity: string;
                  birthProvince: string;
                  nationality: string;
                  phone: string;
                  taxIdCode: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
                vat?: {
                  businessName: string;
                  vat: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
              };
              guests?: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
            }>;
          }>;
        }>;
        orders?: Array<{
          id: string;
          coupon?: string;
          createdAt: any;
          experienceId: string;
          flexible?: boolean;
          insurance?: boolean;
          paymentId?: string;
          status?: OrderStatus;
          totalAmount: number;
          updatedAt: any;
          billing?: {
            termsAcceptance?: boolean;
            private?: {
              birthCity: string;
              birthProvince: string;
              nationality: string;
              phone: string;
              taxIdCode: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
            vat?: {
              businessName: string;
              vat: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
          };
          guests?: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
        }>;
      };
      guests?: Array<{
        id: string;
        birthDate: any;
        company?: string;
        createdAt: any;
        email: string;
        firstName: string;
        gender?: Gender;
        isOwner: boolean;
        lastName: string;
        role: string;
        roomType: RoomTypes;
        updatedAt: any;
      }>;
    }>;
  }>;
};

export type CouponFragment = {
  associatedDomain?: string;
  associatedEmail?: string;
  code: string;
  createdAt: any;
  discount: number;
  experienceId?: string;
  expirationDate?: any;
  id: string;
  maxRedemptions: number;
  name: string;
  updatedAt: any;
  deletedAt?: any;
  experience?: {
    id: string;
    availables: number;
    categorySlug: string;
    createdAt: any;
    discount?: number;
    from: any;
    hasInsurance: boolean;
    isActive: boolean;
    isFlexible: boolean;
    location: string;
    price: number;
    slug: string;
    to: any;
    updatedAt: any;
    bookings: Array<{
      id: string;
      birthDate: any;
      company?: string;
      createdAt: any;
      email: string;
      firstName: string;
      gender?: Gender;
      isOwner: boolean;
      lastName: string;
      role: string;
      roomType: RoomTypes;
      updatedAt: any;
    }>;
    category?: Array<{
      id: string;
      createdAt: any;
      updatedAt: any;
      slug: string;
      title: string;
      experiences?: Array<{
        id: string;
        availables: number;
        categorySlug: string;
        createdAt: any;
        discount?: number;
        from: any;
        hasInsurance: boolean;
        isActive: boolean;
        isFlexible: boolean;
        location: string;
        price: number;
        slug: string;
        to: any;
        updatedAt: any;
        bookings: Array<{
          id: string;
          birthDate: any;
          company?: string;
          createdAt: any;
          email: string;
          firstName: string;
          gender?: Gender;
          isOwner: boolean;
          lastName: string;
          role: string;
          roomType: RoomTypes;
          updatedAt: any;
        }>;
        category?: Array<{
          id: string;
          createdAt: any;
          updatedAt: any;
          slug: string;
          title: string;
        }>;
        extra?: Array<{ id: string; flexible?: number; insurance?: number; singleRoom?: number }>;
        orders?: Array<{
          id: string;
          coupon?: string;
          createdAt: any;
          experienceId: string;
          flexible?: boolean;
          insurance?: boolean;
          paymentId?: string;
          status?: OrderStatus;
          totalAmount: number;
          updatedAt: any;
          billing?: {
            termsAcceptance?: boolean;
            private?: {
              birthCity: string;
              birthProvince: string;
              nationality: string;
              phone: string;
              taxIdCode: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
            vat?: {
              businessName: string;
              vat: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
          };
          experience?: {
            id: string;
            availables: number;
            categorySlug: string;
            createdAt: any;
            discount?: number;
            from: any;
            hasInsurance: boolean;
            isActive: boolean;
            isFlexible: boolean;
            location: string;
            price: number;
            slug: string;
            to: any;
            updatedAt: any;
            bookings: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
            category?: Array<{
              id: string;
              createdAt: any;
              updatedAt: any;
              slug: string;
              title: string;
              experiences?: Array<{
                id: string;
                availables: number;
                categorySlug: string;
                createdAt: any;
                discount?: number;
                from: any;
                hasInsurance: boolean;
                isActive: boolean;
                isFlexible: boolean;
                location: string;
                price: number;
                slug: string;
                to: any;
                updatedAt: any;
                bookings: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
                category?: Array<{
                  id: string;
                  createdAt: any;
                  updatedAt: any;
                  slug: string;
                  title: string;
                }>;
                extra?: Array<{
                  id: string;
                  flexible?: number;
                  insurance?: number;
                  singleRoom?: number;
                }>;
                orders?: Array<{
                  id: string;
                  coupon?: string;
                  createdAt: any;
                  experienceId: string;
                  flexible?: boolean;
                  insurance?: boolean;
                  paymentId?: string;
                  status?: OrderStatus;
                  totalAmount: number;
                  updatedAt: any;
                  billing?: {
                    termsAcceptance?: boolean;
                    private?: {
                      birthCity: string;
                      birthProvince: string;
                      nationality: string;
                      phone: string;
                      taxIdCode: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                    vat?: {
                      businessName: string;
                      vat: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                  };
                  guests?: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                }>;
              }>;
            }>;
            orders?: Array<{
              id: string;
              coupon?: string;
              createdAt: any;
              experienceId: string;
              flexible?: boolean;
              insurance?: boolean;
              paymentId?: string;
              status?: OrderStatus;
              totalAmount: number;
              updatedAt: any;
              billing?: {
                termsAcceptance?: boolean;
                private?: {
                  birthCity: string;
                  birthProvince: string;
                  nationality: string;
                  phone: string;
                  taxIdCode: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
                vat?: {
                  businessName: string;
                  vat: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
              };
              guests?: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
            }>;
          };
          guests?: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
        }>;
      }>;
    }>;
  };
  redemptions?: Array<{
    coupon?: string;
    createdAt: any;
    experienceId: string;
    flexible?: boolean;
    id: string;
    insurance?: boolean;
    paymentId?: string;
    status?: OrderStatus;
    totalAmount: number;
    updatedAt: any;
    billing?: {
      termsAcceptance?: boolean;
      private?: {
        birthCity: string;
        birthProvince: string;
        nationality: string;
        phone: string;
        taxIdCode: string;
        registeredResidence: {
          address: string;
          addressDetails: string;
          city: string;
          country: string;
          province: string;
          zipCode: string;
        };
      };
      vat?: {
        businessName: string;
        vat: string;
        registeredResidence: {
          address: string;
          addressDetails: string;
          city: string;
          country: string;
          province: string;
          zipCode: string;
        };
      };
    };
    experience?: {
      id: string;
      availables: number;
      categorySlug: string;
      createdAt: any;
      discount?: number;
      from: any;
      hasInsurance: boolean;
      isActive: boolean;
      isFlexible: boolean;
      location: string;
      price: number;
      slug: string;
      to: any;
      updatedAt: any;
      bookings: Array<{
        id: string;
        birthDate: any;
        company?: string;
        createdAt: any;
        email: string;
        firstName: string;
        gender?: Gender;
        isOwner: boolean;
        lastName: string;
        role: string;
        roomType: RoomTypes;
        updatedAt: any;
      }>;
      category?: Array<{
        id: string;
        createdAt: any;
        updatedAt: any;
        slug: string;
        title: string;
        experiences?: Array<{
          id: string;
          availables: number;
          categorySlug: string;
          createdAt: any;
          discount?: number;
          from: any;
          hasInsurance: boolean;
          isActive: boolean;
          isFlexible: boolean;
          location: string;
          price: number;
          slug: string;
          to: any;
          updatedAt: any;
          bookings: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
          category?: Array<{
            id: string;
            createdAt: any;
            updatedAt: any;
            slug: string;
            title: string;
          }>;
          extra?: Array<{ id: string; flexible?: number; insurance?: number; singleRoom?: number }>;
          orders?: Array<{
            id: string;
            coupon?: string;
            createdAt: any;
            experienceId: string;
            flexible?: boolean;
            insurance?: boolean;
            paymentId?: string;
            status?: OrderStatus;
            totalAmount: number;
            updatedAt: any;
            billing?: {
              termsAcceptance?: boolean;
              private?: {
                birthCity: string;
                birthProvince: string;
                nationality: string;
                phone: string;
                taxIdCode: string;
                registeredResidence: {
                  address: string;
                  addressDetails: string;
                  city: string;
                  country: string;
                  province: string;
                  zipCode: string;
                };
              };
              vat?: {
                businessName: string;
                vat: string;
                registeredResidence: {
                  address: string;
                  addressDetails: string;
                  city: string;
                  country: string;
                  province: string;
                  zipCode: string;
                };
              };
            };
            experience?: {
              id: string;
              availables: number;
              categorySlug: string;
              createdAt: any;
              discount?: number;
              from: any;
              hasInsurance: boolean;
              isActive: boolean;
              isFlexible: boolean;
              location: string;
              price: number;
              slug: string;
              to: any;
              updatedAt: any;
              bookings: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
              category?: Array<{
                id: string;
                createdAt: any;
                updatedAt: any;
                slug: string;
                title: string;
                experiences?: Array<{
                  id: string;
                  availables: number;
                  categorySlug: string;
                  createdAt: any;
                  discount?: number;
                  from: any;
                  hasInsurance: boolean;
                  isActive: boolean;
                  isFlexible: boolean;
                  location: string;
                  price: number;
                  slug: string;
                  to: any;
                  updatedAt: any;
                  bookings: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                  category?: Array<{
                    id: string;
                    createdAt: any;
                    updatedAt: any;
                    slug: string;
                    title: string;
                  }>;
                  extra?: Array<{
                    id: string;
                    flexible?: number;
                    insurance?: number;
                    singleRoom?: number;
                  }>;
                  orders?: Array<{
                    id: string;
                    coupon?: string;
                    createdAt: any;
                    experienceId: string;
                    flexible?: boolean;
                    insurance?: boolean;
                    paymentId?: string;
                    status?: OrderStatus;
                    totalAmount: number;
                    updatedAt: any;
                    billing?: {
                      termsAcceptance?: boolean;
                      private?: {
                        birthCity: string;
                        birthProvince: string;
                        nationality: string;
                        phone: string;
                        taxIdCode: string;
                        registeredResidence: {
                          address: string;
                          addressDetails: string;
                          city: string;
                          country: string;
                          province: string;
                          zipCode: string;
                        };
                      };
                      vat?: {
                        businessName: string;
                        vat: string;
                        registeredResidence: {
                          address: string;
                          addressDetails: string;
                          city: string;
                          country: string;
                          province: string;
                          zipCode: string;
                        };
                      };
                    };
                    guests?: Array<{
                      id: string;
                      birthDate: any;
                      company?: string;
                      createdAt: any;
                      email: string;
                      firstName: string;
                      gender?: Gender;
                      isOwner: boolean;
                      lastName: string;
                      role: string;
                      roomType: RoomTypes;
                      updatedAt: any;
                    }>;
                  }>;
                }>;
              }>;
              orders?: Array<{
                id: string;
                coupon?: string;
                createdAt: any;
                experienceId: string;
                flexible?: boolean;
                insurance?: boolean;
                paymentId?: string;
                status?: OrderStatus;
                totalAmount: number;
                updatedAt: any;
                billing?: {
                  termsAcceptance?: boolean;
                  private?: {
                    birthCity: string;
                    birthProvince: string;
                    nationality: string;
                    phone: string;
                    taxIdCode: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                  vat?: {
                    businessName: string;
                    vat: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                };
                guests?: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
              }>;
            };
            guests?: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
          }>;
        }>;
      }>;
    };
    guests?: Array<{
      id: string;
      birthDate: any;
      company?: string;
      createdAt: any;
      email: string;
      firstName: string;
      gender?: Gender;
      isOwner: boolean;
      lastName: string;
      role: string;
      roomType: RoomTypes;
      updatedAt: any;
    }>;
  }>;
};

export type EmailTemplateFragment = {
  body: Array<string>;
  category?: TemplateCategory;
  country: Country;
  createdAt: any;
  heading: string;
  id: string;
  sign: Array<string>;
  subject: string;
  title: string;
  typology?: TemplateType;
  updatedAt: any;
  wishes: string;
};

export type ExpFragment = {
  id: string;
  availables: number;
  categorySlug: string;
  createdAt: any;
  discount?: number;
  from: any;
  hasInsurance: boolean;
  isActive: boolean;
  isFlexible: boolean;
  location: string;
  price: number;
  slug: string;
  to: any;
  updatedAt: any;
  bookings: Array<{
    id: string;
    birthDate: any;
    company?: string;
    createdAt: any;
    email: string;
    firstName: string;
    gender?: Gender;
    isOwner: boolean;
    lastName: string;
    role: string;
    roomType: RoomTypes;
    updatedAt: any;
  }>;
  category?: Array<{
    id: string;
    createdAt: any;
    updatedAt: any;
    slug: string;
    title: string;
    experiences?: Array<{
      id: string;
      availables: number;
      categorySlug: string;
      createdAt: any;
      discount?: number;
      from: any;
      hasInsurance: boolean;
      isActive: boolean;
      isFlexible: boolean;
      location: string;
      price: number;
      slug: string;
      to: any;
      updatedAt: any;
      bookings: Array<{
        id: string;
        birthDate: any;
        company?: string;
        createdAt: any;
        email: string;
        firstName: string;
        gender?: Gender;
        isOwner: boolean;
        lastName: string;
        role: string;
        roomType: RoomTypes;
        updatedAt: any;
      }>;
      category?: Array<{ id: string; createdAt: any; updatedAt: any; slug: string; title: string }>;
      extra?: Array<{ id: string; flexible?: number; insurance?: number; singleRoom?: number }>;
      orders?: Array<{
        id: string;
        coupon?: string;
        createdAt: any;
        experienceId: string;
        flexible?: boolean;
        insurance?: boolean;
        paymentId?: string;
        status?: OrderStatus;
        totalAmount: number;
        updatedAt: any;
        billing?: {
          termsAcceptance?: boolean;
          private?: {
            birthCity: string;
            birthProvince: string;
            nationality: string;
            phone: string;
            taxIdCode: string;
            registeredResidence: {
              address: string;
              addressDetails: string;
              city: string;
              country: string;
              province: string;
              zipCode: string;
            };
          };
          vat?: {
            businessName: string;
            vat: string;
            registeredResidence: {
              address: string;
              addressDetails: string;
              city: string;
              country: string;
              province: string;
              zipCode: string;
            };
          };
        };
        experience?: {
          id: string;
          availables: number;
          categorySlug: string;
          createdAt: any;
          discount?: number;
          from: any;
          hasInsurance: boolean;
          isActive: boolean;
          isFlexible: boolean;
          location: string;
          price: number;
          slug: string;
          to: any;
          updatedAt: any;
          bookings: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
          category?: Array<{
            id: string;
            createdAt: any;
            updatedAt: any;
            slug: string;
            title: string;
            experiences?: Array<{
              id: string;
              availables: number;
              categorySlug: string;
              createdAt: any;
              discount?: number;
              from: any;
              hasInsurance: boolean;
              isActive: boolean;
              isFlexible: boolean;
              location: string;
              price: number;
              slug: string;
              to: any;
              updatedAt: any;
              bookings: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
              category?: Array<{
                id: string;
                createdAt: any;
                updatedAt: any;
                slug: string;
                title: string;
              }>;
              extra?: Array<{
                id: string;
                flexible?: number;
                insurance?: number;
                singleRoom?: number;
              }>;
              orders?: Array<{
                id: string;
                coupon?: string;
                createdAt: any;
                experienceId: string;
                flexible?: boolean;
                insurance?: boolean;
                paymentId?: string;
                status?: OrderStatus;
                totalAmount: number;
                updatedAt: any;
                billing?: {
                  termsAcceptance?: boolean;
                  private?: {
                    birthCity: string;
                    birthProvince: string;
                    nationality: string;
                    phone: string;
                    taxIdCode: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                  vat?: {
                    businessName: string;
                    vat: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                };
                guests?: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
              }>;
            }>;
          }>;
          orders?: Array<{
            id: string;
            coupon?: string;
            createdAt: any;
            experienceId: string;
            flexible?: boolean;
            insurance?: boolean;
            paymentId?: string;
            status?: OrderStatus;
            totalAmount: number;
            updatedAt: any;
            billing?: {
              termsAcceptance?: boolean;
              private?: {
                birthCity: string;
                birthProvince: string;
                nationality: string;
                phone: string;
                taxIdCode: string;
                registeredResidence: {
                  address: string;
                  addressDetails: string;
                  city: string;
                  country: string;
                  province: string;
                  zipCode: string;
                };
              };
              vat?: {
                businessName: string;
                vat: string;
                registeredResidence: {
                  address: string;
                  addressDetails: string;
                  city: string;
                  country: string;
                  province: string;
                  zipCode: string;
                };
              };
            };
            guests?: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
          }>;
        };
        guests?: Array<{
          id: string;
          birthDate: any;
          company?: string;
          createdAt: any;
          email: string;
          firstName: string;
          gender?: Gender;
          isOwner: boolean;
          lastName: string;
          role: string;
          roomType: RoomTypes;
          updatedAt: any;
        }>;
      }>;
    }>;
  }>;
};

export type ExtraFragment = {
  id: string;
  flexible?: number;
  insurance?: number;
  singleRoom?: number;
};

export type InferenceReviewFragment = {
  createdAt: any;
  id: string;
  note?: string;
  prediction: string;
  review: string;
  updatedAt: any;
};

export type OrderFragment = {
  id: string;
  coupon?: string;
  createdAt: any;
  experienceId: string;
  flexible?: boolean;
  insurance?: boolean;
  paymentId?: string;
  status?: OrderStatus;
  totalAmount: number;
  updatedAt: any;
  billing?: {
    termsAcceptance?: boolean;
    private?: {
      birthCity: string;
      birthProvince: string;
      nationality: string;
      phone: string;
      taxIdCode: string;
      registeredResidence: {
        address: string;
        addressDetails: string;
        city: string;
        country: string;
        province: string;
        zipCode: string;
      };
    };
    vat?: {
      businessName: string;
      vat: string;
      registeredResidence: {
        address: string;
        addressDetails: string;
        city: string;
        country: string;
        province: string;
        zipCode: string;
      };
    };
  };
  experience?: {
    id: string;
    availables: number;
    categorySlug: string;
    createdAt: any;
    discount?: number;
    from: any;
    hasInsurance: boolean;
    isActive: boolean;
    isFlexible: boolean;
    location: string;
    price: number;
    slug: string;
    to: any;
    updatedAt: any;
    bookings: Array<{
      id: string;
      birthDate: any;
      company?: string;
      createdAt: any;
      email: string;
      firstName: string;
      gender?: Gender;
      isOwner: boolean;
      lastName: string;
      role: string;
      roomType: RoomTypes;
      updatedAt: any;
    }>;
    category?: Array<{
      id: string;
      createdAt: any;
      updatedAt: any;
      slug: string;
      title: string;
      experiences?: Array<{
        id: string;
        availables: number;
        categorySlug: string;
        createdAt: any;
        discount?: number;
        from: any;
        hasInsurance: boolean;
        isActive: boolean;
        isFlexible: boolean;
        location: string;
        price: number;
        slug: string;
        to: any;
        updatedAt: any;
        bookings: Array<{
          id: string;
          birthDate: any;
          company?: string;
          createdAt: any;
          email: string;
          firstName: string;
          gender?: Gender;
          isOwner: boolean;
          lastName: string;
          role: string;
          roomType: RoomTypes;
          updatedAt: any;
        }>;
        category?: Array<{
          id: string;
          createdAt: any;
          updatedAt: any;
          slug: string;
          title: string;
        }>;
        extra?: Array<{ id: string; flexible?: number; insurance?: number; singleRoom?: number }>;
        orders?: Array<{
          id: string;
          coupon?: string;
          createdAt: any;
          experienceId: string;
          flexible?: boolean;
          insurance?: boolean;
          paymentId?: string;
          status?: OrderStatus;
          totalAmount: number;
          updatedAt: any;
          billing?: {
            termsAcceptance?: boolean;
            private?: {
              birthCity: string;
              birthProvince: string;
              nationality: string;
              phone: string;
              taxIdCode: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
            vat?: {
              businessName: string;
              vat: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
          };
          guests?: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
        }>;
      }>;
    }>;
    orders?: Array<{
      id: string;
      coupon?: string;
      createdAt: any;
      experienceId: string;
      flexible?: boolean;
      insurance?: boolean;
      paymentId?: string;
      status?: OrderStatus;
      totalAmount: number;
      updatedAt: any;
      billing?: {
        termsAcceptance?: boolean;
        private?: {
          birthCity: string;
          birthProvince: string;
          nationality: string;
          phone: string;
          taxIdCode: string;
          registeredResidence: {
            address: string;
            addressDetails: string;
            city: string;
            country: string;
            province: string;
            zipCode: string;
          };
        };
        vat?: {
          businessName: string;
          vat: string;
          registeredResidence: {
            address: string;
            addressDetails: string;
            city: string;
            country: string;
            province: string;
            zipCode: string;
          };
        };
      };
      guests?: Array<{
        id: string;
        birthDate: any;
        company?: string;
        createdAt: any;
        email: string;
        firstName: string;
        gender?: Gender;
        isOwner: boolean;
        lastName: string;
        role: string;
        roomType: RoomTypes;
        updatedAt: any;
      }>;
    }>;
  };
  guests?: Array<{
    id: string;
    birthDate: any;
    company?: string;
    createdAt: any;
    email: string;
    firstName: string;
    gender?: Gender;
    isOwner: boolean;
    lastName: string;
    role: string;
    roomType: RoomTypes;
    updatedAt: any;
  }>;
};

export type PageInfoFragment = {
  endCursor?: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
};

export type ResidenceFragment = {
  address: string;
  addressDetails: string;
  city: string;
  country: string;
  province: string;
  zipCode: string;
};

export type ScreeningResultFragment = {
  totalCount: number;
  expertises: Array<{
    count: number;
    expertise: string;
    status?: Array<{ count: number; status: string }>;
  }>;
};

export type SkillFragment = {
  id: string;
  name: string;
  expertiseId: string;
  expertise: { id: string; name: string };
};

export type TalentFragment = {
  additionalInfo?: string;
  biography?: string;
  collaborationNotes?: string;
  collaborations?: Array<string>;
  country?: Country;
  createdAt: any;
  deletedAt?: any;
  digitalNomad?: boolean;
  prediction?: string;
  email?: string;
  experience?: string;
  firstName?: string;
  freelance?: string;
  github?: string;
  id: string;
  zohoId?: string;
  industries?: Array<string>;
  internationalProjects?: boolean;
  interviewSent?: boolean;
  interviewRecapSent?: boolean;
  lastName?: string;
  mobile?: string;
  newsletter?: boolean;
  notes?: string;
  openToEmployment?: boolean;
  rate?: string;
  rejectSent?: boolean;
  reminderSent?: boolean;
  search?: Array<string>;
  status?: TalentStatus;
  termsAcceptance?: boolean;
  updatedAt: any;
  workplace?: string;
  documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
  inferenceReview?: {
    createdAt: any;
    id: string;
    note?: string;
    prediction: string;
    review: string;
    updatedAt: any;
    user: {
      id: string;
      calendlyUrl?: string;
      createdAt: any;
      deletedAt?: any;
      email: string;
      firstname: string;
      lastname: string;
      role: UserRole;
      sign?: string;
      updatedAt: any;
      username: string;
    };
  };
  languages?: Array<{ language: string; languageId: string; level: string }>;
  notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
  portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
  specialities?: Array<{ expertise: string; skills: Array<string> }>;
  tools?: Array<{
    expertise: string;
    technologies: Array<{ level: string; technology: string; technologyId: string }>;
  }>;
};

export type TechnologyFragment = {
  id: string;
  name: string;
  expertises: Array<{ id: string; name: string }>;
};

export type NodeFragment = {
  id: string;
  calendlyUrl?: string;
  createdAt: any;
  deletedAt?: any;
  email: string;
  firstname: string;
  lastname: string;
  role: UserRole;
  sign?: string;
  updatedAt: any;
  username: string;
};

export type AddAgencyMutationVariables = Exact<{
  input: CreateAgencyInput;
}>;

export type AddAgencyMutation = {
  addAgency: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    companyName?: string;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    email?: string;
    employees?: string;
    experience?: string;
    id: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    vat?: string;
    website?: string;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    languages: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type AddAgencyDocumentMutationVariables = Exact<{
  data: AddAgencyDocumentInput;
}>;

export type AddAgencyDocumentMutation = { addAgencyDocument: boolean };

export type CreateFreeOrderMutationVariables = Exact<{
  data: CreateFreeOrderInput;
}>;

export type CreateFreeOrderMutation = {
  createFreeOrder: {
    coupon?: string;
    experienceId: string;
    guests?: Array<{
      birthDate: any;
      company?: string;
      createdAt: any;
      email: string;
      firstName: string;
      gender?: Gender;
      id: string;
      isOwner: boolean;
      lastName: string;
      role: string;
      roomType: RoomTypes;
      updatedAt: any;
    }>;
  };
};

export type AddTalentMutationVariables = Exact<{
  input: CreateTalentInput;
}>;

export type AddTalentMutation = {
  addTalent: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    digitalNomad?: boolean;
    prediction?: string;
    email?: string;
    experience?: string;
    firstName?: string;
    freelance?: string;
    github?: string;
    id: string;
    zohoId?: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    interviewRecapSent?: boolean;
    lastName?: string;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    openToEmployment?: boolean;
    rate?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    inferenceReview?: {
      createdAt: any;
      id: string;
      note?: string;
      prediction: string;
      review: string;
      updatedAt: any;
      user: {
        id: string;
        calendlyUrl?: string;
        createdAt: any;
        deletedAt?: any;
        email: string;
        firstname: string;
        lastname: string;
        role: UserRole;
        sign?: string;
        updatedAt: any;
        username: string;
      };
    };
    languages?: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type AddTalentDocumentMutationVariables = Exact<{
  data: AddTalentDocumentInput;
}>;

export type AddTalentDocumentMutation = { addTalentDocument: boolean };

export type ArchiveDealsMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  archive: Scalars['Boolean']['input'];
}>;

export type ArchiveDealsMutation = { archiveDeals: boolean };

export type AuthenticateExternalDomainMutationVariables = Exact<{
  externalDomain: Scalars['String']['input'];
}>;

export type AuthenticateExternalDomainMutation = { authenticateExternalDomain: boolean };

export type AutoMatchingMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AutoMatchingMutation = {
  autoMatching: {
    api_version: string;
    count: string;
    environment: string;
    query: string;
    status: string;
  };
};

export type CreateCouponMutationVariables = Exact<{
  data: CreateCouponInput;
}>;

export type CreateCouponMutation = {
  createCoupon: {
    associatedDomain?: string;
    associatedEmail?: string;
    code: string;
    discount: number;
    experienceId?: string;
    expirationDate?: any;
    maxRedemptions: number;
    name: string;
  };
};

export type CreateDealTalentSelectionMutationVariables = Exact<{
  dealId: Scalars['String']['input'];
  talentId: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type CreateDealTalentSelectionMutation = {
  createDealTalentSelection: Array<{ id: string }>;
};

export type CreateInferenceReviewMutationVariables = Exact<{
  input: CreateInferenceReviewInput;
}>;

export type CreateInferenceReviewMutation = {
  createInferenceReview: { note?: string; review: string };
};

export type CreateSkillMutationVariables = Exact<{
  input: CreateSkillInput;
}>;

export type CreateSkillMutation = { createSkill: { name: string; expertiseId: string } };

export type CreateTechnologyMutationVariables = Exact<{
  input: CreateTechnologyInput;
}>;

export type CreateTechnologyMutation = {
  createTechnology: { id: string; name: string; expertises: Array<{ id: string; name: string }> };
};

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;

export type CreateUserMutation = {
  createUser: {
    id: string;
    calendlyUrl?: string;
    createdAt: any;
    deletedAt?: any;
    email: string;
    firstname: string;
    lastname: string;
    role: UserRole;
    sign?: string;
    updatedAt: any;
    username: string;
  };
};

export type DeleteDealTalentSelectionMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DeleteDealTalentSelectionMutation = { deleteDealTalentSelection: Array<string> };

export type ExportAgenciesMutationVariables = Exact<{
  filters?: InputMaybe<AgenciesFilterInput>;
  agencyIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportAgenciesMutation = { exportAgencies: string };

export type ExportTalentsMutationVariables = Exact<{
  filters?: InputMaybe<TalentsFilterInput>;
  talentIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExportTalentsMutation = { exportTalents: string };

export type HardDeleteAgencieMutationVariables = Exact<{
  agencyIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type HardDeleteAgencieMutation = { hardDeleteAgencies: boolean };

export type HardDeleteCouponMutationVariables = Exact<{
  couponId: Scalars['String']['input'];
}>;

export type HardDeleteCouponMutation = { hardDeleteCoupon: boolean };

export type HardDeleteSkillsMutationVariables = Exact<{
  skillIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type HardDeleteSkillsMutation = { hardDeleteSkills: boolean };

export type HardDeleteTalentMutationVariables = Exact<{
  talentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type HardDeleteTalentMutation = { hardDeleteTalents: boolean };

export type HardDeleteTechnologiesMutationVariables = Exact<{
  technologyIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type HardDeleteTechnologiesMutation = { hardDeleteTechnologies: boolean };

export type HardDeleteUserMutationVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type HardDeleteUserMutation = { hardDeleteUser: boolean };

export type InviteAgencyMutationMutationVariables = Exact<{
  agencyId: Scalars['String']['input'];
}>;

export type InviteAgencyMutationMutation = { inviteAgency: boolean };

export type InviteTalentMutationMutationVariables = Exact<{
  talentId: Scalars['String']['input'];
}>;

export type InviteTalentMutationMutation = { inviteTalent: boolean };

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;

export type LoginMutation = {
  login: {
    accessToken: any;
    refreshToken: any;
    user: {
      id: string;
      calendlyUrl?: string;
      createdAt: any;
      deletedAt?: any;
      email: string;
      firstname: string;
      lastname: string;
      role: UserRole;
      sign?: string;
      updatedAt: any;
      username: string;
    };
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { logout: boolean };

export type MigrateToAgencyMutationMutationVariables = Exact<{
  talentId: Scalars['String']['input'];
}>;

export type MigrateToAgencyMutationMutation = { migrateToAgency: boolean };

export type MigrateToTalentMutationMutationVariables = Exact<{
  agencyId: Scalars['String']['input'];
}>;

export type MigrateToTalentMutationMutation = { migrateToTalent: boolean };

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars['JWT']['input'];
}>;

export type RefreshTokenMutation = { refreshToken: { accessToken: any; refreshToken: any } };

export type RejectAgencyMutationMutationVariables = Exact<{
  status: TalentStatus;
  agencyId: Scalars['String']['input'];
}>;

export type RejectAgencyMutationMutation = { rejectAgency: boolean };

export type RejectTalentMutationMutationVariables = Exact<{
  status: TalentStatus;
  talentId: Scalars['String']['input'];
}>;

export type RejectTalentMutationMutation = { rejectTalent: boolean };

export type RemindInviteAgencyMutationMutationVariables = Exact<{
  agencyId: Scalars['String']['input'];
}>;

export type RemindInviteAgencyMutationMutation = { remindInviteAgency: boolean };

export type RemindInviteTalentMutationMutationVariables = Exact<{
  talentId: Scalars['String']['input'];
}>;

export type RemindInviteTalentMutationMutation = { remindInviteTalent: boolean };

export type RemoveAgencyLanguageMutationMutationVariables = Exact<{
  languageId: Scalars['String']['input'];
  agencyId: Scalars['String']['input'];
}>;

export type RemoveAgencyLanguageMutationMutation = { removeAgencyLanguage: boolean };

export type RemoveAgencyToolMutationMutationVariables = Exact<{
  technologyId: Scalars['String']['input'];
  agencyId: Scalars['String']['input'];
}>;

export type RemoveAgencyToolMutationMutation = { removeAgencyTool: boolean };

export type RemoveTalentLanguageMutationMutationVariables = Exact<{
  languageId: Scalars['String']['input'];
  talentId: Scalars['String']['input'];
}>;

export type RemoveTalentLanguageMutationMutation = { removeTalentLanguage: boolean };

export type RemoveTalentToolMutationMutationVariables = Exact<{
  technologyId: Scalars['String']['input'];
  talentId: Scalars['String']['input'];
}>;

export type RemoveTalentToolMutationMutation = { removeTalentTool: boolean };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { resetPassword: boolean };

export type RestoreAgenciesMutationVariables = Exact<{
  agencyIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RestoreAgenciesMutation = { restoreAgencies: boolean };

export type RestoreTalentsMutationVariables = Exact<{
  talentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RestoreTalentsMutation = { restoreTalents: boolean };

export type RestoreUsersMutationVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RestoreUsersMutation = { restoreUser: boolean };

export type SendDealSelectionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type SendDealSelectionMutation = { sendDealSelection: { dealId: string } };

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SendResetPasswordEmailMutation = { sendResetPasswordEmail: boolean };

export type SoftDeleteAgenciesMutationVariables = Exact<{
  agencyIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SoftDeleteAgenciesMutation = { softDeleteAgencies: boolean };

export type SoftDeleteCouponMutationVariables = Exact<{
  couponId: Scalars['String']['input'];
}>;

export type SoftDeleteCouponMutation = { softDeleteCoupon: boolean };

export type SoftDeleteTalentsMutationVariables = Exact<{
  talentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SoftDeleteTalentsMutation = { softDeleteTalents: boolean };

export type SoftDeleteUserMutationVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SoftDeleteUserMutation = { softDeleteUser: boolean };

export type UpdateAgencyMutationVariables = Exact<{
  agencyId: Scalars['String']['input'];
  newAgencyData: UpdateAgencyInput;
}>;

export type UpdateAgencyMutation = {
  updateAgency: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    companyName?: string;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    email?: string;
    employees?: string;
    experience?: string;
    id: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    vat?: string;
    website?: string;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    languages: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type UpdateAgencyBiographyMutationVariables = Exact<{
  agencyId: Scalars['String']['input'];
  biography: Scalars['String']['input'];
}>;

export type UpdateAgencyBiographyMutation = {
  updateAgencyBiography: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    companyName?: string;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    email?: string;
    employees?: string;
    experience?: string;
    id: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    vat?: string;
    website?: string;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    languages: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type UpdateAgencyDocumentMutationVariables = Exact<{
  data: UpdateAgencyDocumentInput;
}>;

export type UpdateAgencyDocumentMutation = { updateAgencyDocument: boolean };

export type UpdateAgencyNotesMutationVariables = Exact<{
  agencyId: Scalars['String']['input'];
  notes: Scalars['String']['input'];
}>;

export type UpdateAgencyNotesMutation = {
  updateAgencyNotes: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    companyName?: string;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    email?: string;
    employees?: string;
    experience?: string;
    id: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    vat?: string;
    website?: string;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    languages: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type UpdateCouponMutationVariables = Exact<{
  couponId: Scalars['String']['input'];
  data: UpdateCouponInput;
}>;

export type UpdateCouponMutation = {
  updateCoupon: {
    associatedDomain?: string;
    associatedEmail?: string;
    code: string;
    createdAt: any;
    discount: number;
    experienceId?: string;
    expirationDate?: any;
    id: string;
    maxRedemptions: number;
    name: string;
    updatedAt: any;
    deletedAt?: any;
    experience?: {
      id: string;
      availables: number;
      categorySlug: string;
      createdAt: any;
      discount?: number;
      from: any;
      hasInsurance: boolean;
      isActive: boolean;
      isFlexible: boolean;
      location: string;
      price: number;
      slug: string;
      to: any;
      updatedAt: any;
      bookings: Array<{
        id: string;
        birthDate: any;
        company?: string;
        createdAt: any;
        email: string;
        firstName: string;
        gender?: Gender;
        isOwner: boolean;
        lastName: string;
        role: string;
        roomType: RoomTypes;
        updatedAt: any;
      }>;
      category?: Array<{
        id: string;
        createdAt: any;
        updatedAt: any;
        slug: string;
        title: string;
        experiences?: Array<{
          id: string;
          availables: number;
          categorySlug: string;
          createdAt: any;
          discount?: number;
          from: any;
          hasInsurance: boolean;
          isActive: boolean;
          isFlexible: boolean;
          location: string;
          price: number;
          slug: string;
          to: any;
          updatedAt: any;
          bookings: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
          category?: Array<{
            id: string;
            createdAt: any;
            updatedAt: any;
            slug: string;
            title: string;
          }>;
          extra?: Array<{ id: string; flexible?: number; insurance?: number; singleRoom?: number }>;
          orders?: Array<{
            id: string;
            coupon?: string;
            createdAt: any;
            experienceId: string;
            flexible?: boolean;
            insurance?: boolean;
            paymentId?: string;
            status?: OrderStatus;
            totalAmount: number;
            updatedAt: any;
            billing?: {
              termsAcceptance?: boolean;
              private?: {
                birthCity: string;
                birthProvince: string;
                nationality: string;
                phone: string;
                taxIdCode: string;
                registeredResidence: {
                  address: string;
                  addressDetails: string;
                  city: string;
                  country: string;
                  province: string;
                  zipCode: string;
                };
              };
              vat?: {
                businessName: string;
                vat: string;
                registeredResidence: {
                  address: string;
                  addressDetails: string;
                  city: string;
                  country: string;
                  province: string;
                  zipCode: string;
                };
              };
            };
            experience?: {
              id: string;
              availables: number;
              categorySlug: string;
              createdAt: any;
              discount?: number;
              from: any;
              hasInsurance: boolean;
              isActive: boolean;
              isFlexible: boolean;
              location: string;
              price: number;
              slug: string;
              to: any;
              updatedAt: any;
              bookings: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
              category?: Array<{
                id: string;
                createdAt: any;
                updatedAt: any;
                slug: string;
                title: string;
                experiences?: Array<{
                  id: string;
                  availables: number;
                  categorySlug: string;
                  createdAt: any;
                  discount?: number;
                  from: any;
                  hasInsurance: boolean;
                  isActive: boolean;
                  isFlexible: boolean;
                  location: string;
                  price: number;
                  slug: string;
                  to: any;
                  updatedAt: any;
                  bookings: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                  category?: Array<{
                    id: string;
                    createdAt: any;
                    updatedAt: any;
                    slug: string;
                    title: string;
                  }>;
                  extra?: Array<{
                    id: string;
                    flexible?: number;
                    insurance?: number;
                    singleRoom?: number;
                  }>;
                  orders?: Array<{
                    id: string;
                    coupon?: string;
                    createdAt: any;
                    experienceId: string;
                    flexible?: boolean;
                    insurance?: boolean;
                    paymentId?: string;
                    status?: OrderStatus;
                    totalAmount: number;
                    updatedAt: any;
                    billing?: {
                      termsAcceptance?: boolean;
                      private?: {
                        birthCity: string;
                        birthProvince: string;
                        nationality: string;
                        phone: string;
                        taxIdCode: string;
                        registeredResidence: {
                          address: string;
                          addressDetails: string;
                          city: string;
                          country: string;
                          province: string;
                          zipCode: string;
                        };
                      };
                      vat?: {
                        businessName: string;
                        vat: string;
                        registeredResidence: {
                          address: string;
                          addressDetails: string;
                          city: string;
                          country: string;
                          province: string;
                          zipCode: string;
                        };
                      };
                    };
                    guests?: Array<{
                      id: string;
                      birthDate: any;
                      company?: string;
                      createdAt: any;
                      email: string;
                      firstName: string;
                      gender?: Gender;
                      isOwner: boolean;
                      lastName: string;
                      role: string;
                      roomType: RoomTypes;
                      updatedAt: any;
                    }>;
                  }>;
                }>;
              }>;
              orders?: Array<{
                id: string;
                coupon?: string;
                createdAt: any;
                experienceId: string;
                flexible?: boolean;
                insurance?: boolean;
                paymentId?: string;
                status?: OrderStatus;
                totalAmount: number;
                updatedAt: any;
                billing?: {
                  termsAcceptance?: boolean;
                  private?: {
                    birthCity: string;
                    birthProvince: string;
                    nationality: string;
                    phone: string;
                    taxIdCode: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                  vat?: {
                    businessName: string;
                    vat: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                };
                guests?: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
              }>;
            };
            guests?: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
          }>;
        }>;
      }>;
    };
    redemptions?: Array<{
      coupon?: string;
      createdAt: any;
      experienceId: string;
      flexible?: boolean;
      id: string;
      insurance?: boolean;
      paymentId?: string;
      status?: OrderStatus;
      totalAmount: number;
      updatedAt: any;
      billing?: {
        termsAcceptance?: boolean;
        private?: {
          birthCity: string;
          birthProvince: string;
          nationality: string;
          phone: string;
          taxIdCode: string;
          registeredResidence: {
            address: string;
            addressDetails: string;
            city: string;
            country: string;
            province: string;
            zipCode: string;
          };
        };
        vat?: {
          businessName: string;
          vat: string;
          registeredResidence: {
            address: string;
            addressDetails: string;
            city: string;
            country: string;
            province: string;
            zipCode: string;
          };
        };
      };
      experience?: {
        id: string;
        availables: number;
        categorySlug: string;
        createdAt: any;
        discount?: number;
        from: any;
        hasInsurance: boolean;
        isActive: boolean;
        isFlexible: boolean;
        location: string;
        price: number;
        slug: string;
        to: any;
        updatedAt: any;
        bookings: Array<{
          id: string;
          birthDate: any;
          company?: string;
          createdAt: any;
          email: string;
          firstName: string;
          gender?: Gender;
          isOwner: boolean;
          lastName: string;
          role: string;
          roomType: RoomTypes;
          updatedAt: any;
        }>;
        category?: Array<{
          id: string;
          createdAt: any;
          updatedAt: any;
          slug: string;
          title: string;
          experiences?: Array<{
            id: string;
            availables: number;
            categorySlug: string;
            createdAt: any;
            discount?: number;
            from: any;
            hasInsurance: boolean;
            isActive: boolean;
            isFlexible: boolean;
            location: string;
            price: number;
            slug: string;
            to: any;
            updatedAt: any;
            bookings: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
            category?: Array<{
              id: string;
              createdAt: any;
              updatedAt: any;
              slug: string;
              title: string;
            }>;
            extra?: Array<{
              id: string;
              flexible?: number;
              insurance?: number;
              singleRoom?: number;
            }>;
            orders?: Array<{
              id: string;
              coupon?: string;
              createdAt: any;
              experienceId: string;
              flexible?: boolean;
              insurance?: boolean;
              paymentId?: string;
              status?: OrderStatus;
              totalAmount: number;
              updatedAt: any;
              billing?: {
                termsAcceptance?: boolean;
                private?: {
                  birthCity: string;
                  birthProvince: string;
                  nationality: string;
                  phone: string;
                  taxIdCode: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
                vat?: {
                  businessName: string;
                  vat: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
              };
              experience?: {
                id: string;
                availables: number;
                categorySlug: string;
                createdAt: any;
                discount?: number;
                from: any;
                hasInsurance: boolean;
                isActive: boolean;
                isFlexible: boolean;
                location: string;
                price: number;
                slug: string;
                to: any;
                updatedAt: any;
                bookings: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
                category?: Array<{
                  id: string;
                  createdAt: any;
                  updatedAt: any;
                  slug: string;
                  title: string;
                  experiences?: Array<{
                    id: string;
                    availables: number;
                    categorySlug: string;
                    createdAt: any;
                    discount?: number;
                    from: any;
                    hasInsurance: boolean;
                    isActive: boolean;
                    isFlexible: boolean;
                    location: string;
                    price: number;
                    slug: string;
                    to: any;
                    updatedAt: any;
                    bookings: Array<{
                      id: string;
                      birthDate: any;
                      company?: string;
                      createdAt: any;
                      email: string;
                      firstName: string;
                      gender?: Gender;
                      isOwner: boolean;
                      lastName: string;
                      role: string;
                      roomType: RoomTypes;
                      updatedAt: any;
                    }>;
                    category?: Array<{
                      id: string;
                      createdAt: any;
                      updatedAt: any;
                      slug: string;
                      title: string;
                    }>;
                    extra?: Array<{
                      id: string;
                      flexible?: number;
                      insurance?: number;
                      singleRoom?: number;
                    }>;
                    orders?: Array<{
                      id: string;
                      coupon?: string;
                      createdAt: any;
                      experienceId: string;
                      flexible?: boolean;
                      insurance?: boolean;
                      paymentId?: string;
                      status?: OrderStatus;
                      totalAmount: number;
                      updatedAt: any;
                      billing?: {
                        termsAcceptance?: boolean;
                        private?: {
                          birthCity: string;
                          birthProvince: string;
                          nationality: string;
                          phone: string;
                          taxIdCode: string;
                          registeredResidence: {
                            address: string;
                            addressDetails: string;
                            city: string;
                            country: string;
                            province: string;
                            zipCode: string;
                          };
                        };
                        vat?: {
                          businessName: string;
                          vat: string;
                          registeredResidence: {
                            address: string;
                            addressDetails: string;
                            city: string;
                            country: string;
                            province: string;
                            zipCode: string;
                          };
                        };
                      };
                      guests?: Array<{
                        id: string;
                        birthDate: any;
                        company?: string;
                        createdAt: any;
                        email: string;
                        firstName: string;
                        gender?: Gender;
                        isOwner: boolean;
                        lastName: string;
                        role: string;
                        roomType: RoomTypes;
                        updatedAt: any;
                      }>;
                    }>;
                  }>;
                }>;
                orders?: Array<{
                  id: string;
                  coupon?: string;
                  createdAt: any;
                  experienceId: string;
                  flexible?: boolean;
                  insurance?: boolean;
                  paymentId?: string;
                  status?: OrderStatus;
                  totalAmount: number;
                  updatedAt: any;
                  billing?: {
                    termsAcceptance?: boolean;
                    private?: {
                      birthCity: string;
                      birthProvince: string;
                      nationality: string;
                      phone: string;
                      taxIdCode: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                    vat?: {
                      businessName: string;
                      vat: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                  };
                  guests?: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                }>;
              };
              guests?: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
            }>;
          }>;
        }>;
      };
      guests?: Array<{
        id: string;
        birthDate: any;
        company?: string;
        createdAt: any;
        email: string;
        firstName: string;
        gender?: Gender;
        isOwner: boolean;
        lastName: string;
        role: string;
        roomType: RoomTypes;
        updatedAt: any;
      }>;
    }>;
  };
};

export type UpdateDealMutationVariables = Exact<{
  data: UpdateDealInput;
  id: Scalars['String']['input'];
}>;

export type UpdateDealMutation = { updateDeal: { id: string } };

export type UpdateDealTalentSelectionMutationVariables = Exact<{
  data: UpdateDealTalentSelectionInput;
  id: Scalars['String']['input'];
}>;

export type UpdateDealTalentSelectionMutation = { updateDealTalentSelection: { id: string } };

export type UpdateEmailTemplateMutationVariables = Exact<{
  data: EmailTemplateInput;
  id: Scalars['String']['input'];
}>;

export type UpdateEmailTemplateMutation = {
  updateEmailTemplate: {
    body: Array<string>;
    category?: TemplateCategory;
    country: Country;
    createdAt: any;
    heading: string;
    id: string;
    sign: Array<string>;
    subject: string;
    title: string;
    typology?: TemplateType;
    updatedAt: any;
    wishes: string;
  };
};

export type UpdateInferenceReviewMutationVariables = Exact<{
  input: UpdateInferenceReviewInput;
}>;

export type UpdateInferenceReviewMutation = {
  updateInferenceReview: {
    createdAt: any;
    id: string;
    note?: string;
    prediction: string;
    review: string;
    updatedAt: any;
  };
};

export type UpdateSkillMutationVariables = Exact<{
  skillId: Scalars['String']['input'];
  input: UpdateSkillInput;
}>;

export type UpdateSkillMutation = {
  updateSkill: {
    id: string;
    name: string;
    expertiseId: string;
    expertise: { id: string; name: string };
  };
};

export type UpdateTalentMutationVariables = Exact<{
  talentId: Scalars['String']['input'];
  newTalentData: UpdateTalentInput;
}>;

export type UpdateTalentMutation = {
  updateTalent: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    digitalNomad?: boolean;
    prediction?: string;
    email?: string;
    experience?: string;
    firstName?: string;
    freelance?: string;
    github?: string;
    id: string;
    zohoId?: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    interviewRecapSent?: boolean;
    lastName?: string;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    openToEmployment?: boolean;
    rate?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    inferenceReview?: {
      createdAt: any;
      id: string;
      note?: string;
      prediction: string;
      review: string;
      updatedAt: any;
      user: {
        id: string;
        calendlyUrl?: string;
        createdAt: any;
        deletedAt?: any;
        email: string;
        firstname: string;
        lastname: string;
        role: UserRole;
        sign?: string;
        updatedAt: any;
        username: string;
      };
    };
    languages?: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type UpdateTalentBiographyMutationVariables = Exact<{
  talentId: Scalars['String']['input'];
  biography: Scalars['String']['input'];
}>;

export type UpdateTalentBiographyMutation = {
  updateTalentBiography: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    digitalNomad?: boolean;
    prediction?: string;
    email?: string;
    experience?: string;
    firstName?: string;
    freelance?: string;
    github?: string;
    id: string;
    zohoId?: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    interviewRecapSent?: boolean;
    lastName?: string;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    openToEmployment?: boolean;
    rate?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    inferenceReview?: {
      createdAt: any;
      id: string;
      note?: string;
      prediction: string;
      review: string;
      updatedAt: any;
      user: {
        id: string;
        calendlyUrl?: string;
        createdAt: any;
        deletedAt?: any;
        email: string;
        firstname: string;
        lastname: string;
        role: UserRole;
        sign?: string;
        updatedAt: any;
        username: string;
      };
    };
    languages?: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type UpdateTalentDocumentMutationVariables = Exact<{
  data: UpdateTalentDocumentInput;
}>;

export type UpdateTalentDocumentMutation = { updateTalentDocument: boolean };

export type UpdateTalentNotesMutationVariables = Exact<{
  talentId: Scalars['String']['input'];
  notes: Scalars['String']['input'];
}>;

export type UpdateTalentNotesMutation = {
  updateTalentNotes: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    digitalNomad?: boolean;
    prediction?: string;
    email?: string;
    experience?: string;
    firstName?: string;
    freelance?: string;
    github?: string;
    id: string;
    zohoId?: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    interviewRecapSent?: boolean;
    lastName?: string;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    openToEmployment?: boolean;
    rate?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    inferenceReview?: {
      createdAt: any;
      id: string;
      note?: string;
      prediction: string;
      review: string;
      updatedAt: any;
      user: {
        id: string;
        calendlyUrl?: string;
        createdAt: any;
        deletedAt?: any;
        email: string;
        firstname: string;
        lastname: string;
        role: UserRole;
        sign?: string;
        updatedAt: any;
        username: string;
      };
    };
    languages?: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type UpdateTechnologyMutationVariables = Exact<{
  technologyId: Scalars['String']['input'];
  input: UpdateTechnologyInput;
}>;

export type UpdateTechnologyMutation = {
  updateTechnology: { id: string; name: string; expertises: Array<{ id: string; name: string }> };
};

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  newUserData: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  updateUser: {
    id: string;
    calendlyUrl?: string;
    createdAt: any;
    deletedAt?: any;
    email: string;
    firstname: string;
    lastname: string;
    role: UserRole;
    sign?: string;
    updatedAt: any;
    username: string;
  };
};

export type GetAgenciesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AgenciesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgenciesOrder>;
}>;

export type GetAgenciesQuery = {
  agencies: {
    totalCount: number;
    edges?: Array<{
      cursor: string;
      node: {
        additionalInfo?: string;
        biography?: string;
        collaborationNotes?: string;
        collaborations?: Array<string>;
        companyName?: string;
        country?: Country;
        createdAt: any;
        deletedAt?: any;
        email?: string;
        employees?: string;
        experience?: string;
        id: string;
        industries?: Array<string>;
        internationalProjects?: boolean;
        interviewSent?: boolean;
        mobile?: string;
        newsletter?: boolean;
        notes?: string;
        rejectSent?: boolean;
        reminderSent?: boolean;
        search?: Array<string>;
        status?: TalentStatus;
        termsAcceptance?: boolean;
        updatedAt: any;
        vat?: string;
        website?: string;
        workplace?: string;
        documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
        languages: Array<{ language: string; languageId: string; level: string }>;
        notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
        portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
        specialities?: Array<{ expertise: string; skills: Array<string> }>;
        tools?: Array<{
          expertise: string;
          technologies: Array<{ level: string; technology: string; technologyId: string }>;
        }>;
      };
    }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type AgenciesCounterQueryVariables = Exact<{ [key: string]: never }>;

export type AgenciesCounterQuery = { agenciesCounter: Array<{ _count: number; status: string }> };

export type GetAgenciesIdsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AgenciesFilterInput>;
  keyword: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgenciesOrder>;
}>;

export type GetAgenciesIdsQuery = { searchAgencies: { edges?: Array<{ node: { id: string } }> } };

export type AgencyQueryQueryVariables = Exact<{
  agencyId: Scalars['String']['input'];
}>;

export type AgencyQueryQuery = {
  agency: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    companyName?: string;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    email?: string;
    employees?: string;
    experience?: string;
    id: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    vat?: string;
    website?: string;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    languages: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type GetAgencyInterviewQueryVariables = Exact<{
  agencyId: Scalars['String']['input'];
}>;

export type GetAgencyInterviewQuery = {
  agency: {
    companyName?: string;
    email?: string;
    notes?: string;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
  };
};

export type CommonAnalyticsQueryVariables = Exact<{
  filters?: InputMaybe<CommonsAnalyticsFilterInput>;
}>;

export type CommonAnalyticsQuery = {
  commonAnalytics: Array<{
    range: string;
    totalCount: number;
    expertises?: Array<{ expertise: string; count: number }>;
  }>;
};

export type CouponQueryQueryVariables = Exact<{
  couponId: Scalars['String']['input'];
}>;

export type CouponQueryQuery = {
  coupon: {
    associatedDomain?: string;
    associatedEmail?: string;
    code: string;
    createdAt: any;
    discount: number;
    experienceId?: string;
    expirationDate?: any;
    id: string;
    maxRedemptions: number;
    name: string;
    updatedAt: any;
    deletedAt?: any;
    experience?: {
      id: string;
      availables: number;
      categorySlug: string;
      createdAt: any;
      discount?: number;
      from: any;
      hasInsurance: boolean;
      isActive: boolean;
      isFlexible: boolean;
      location: string;
      price: number;
      slug: string;
      to: any;
      updatedAt: any;
      bookings: Array<{
        id: string;
        birthDate: any;
        company?: string;
        createdAt: any;
        email: string;
        firstName: string;
        gender?: Gender;
        isOwner: boolean;
        lastName: string;
        role: string;
        roomType: RoomTypes;
        updatedAt: any;
      }>;
      category?: Array<{
        id: string;
        createdAt: any;
        updatedAt: any;
        slug: string;
        title: string;
        experiences?: Array<{
          id: string;
          availables: number;
          categorySlug: string;
          createdAt: any;
          discount?: number;
          from: any;
          hasInsurance: boolean;
          isActive: boolean;
          isFlexible: boolean;
          location: string;
          price: number;
          slug: string;
          to: any;
          updatedAt: any;
          bookings: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
          category?: Array<{
            id: string;
            createdAt: any;
            updatedAt: any;
            slug: string;
            title: string;
          }>;
          extra?: Array<{ id: string; flexible?: number; insurance?: number; singleRoom?: number }>;
          orders?: Array<{
            id: string;
            coupon?: string;
            createdAt: any;
            experienceId: string;
            flexible?: boolean;
            insurance?: boolean;
            paymentId?: string;
            status?: OrderStatus;
            totalAmount: number;
            updatedAt: any;
            billing?: {
              termsAcceptance?: boolean;
              private?: {
                birthCity: string;
                birthProvince: string;
                nationality: string;
                phone: string;
                taxIdCode: string;
                registeredResidence: {
                  address: string;
                  addressDetails: string;
                  city: string;
                  country: string;
                  province: string;
                  zipCode: string;
                };
              };
              vat?: {
                businessName: string;
                vat: string;
                registeredResidence: {
                  address: string;
                  addressDetails: string;
                  city: string;
                  country: string;
                  province: string;
                  zipCode: string;
                };
              };
            };
            experience?: {
              id: string;
              availables: number;
              categorySlug: string;
              createdAt: any;
              discount?: number;
              from: any;
              hasInsurance: boolean;
              isActive: boolean;
              isFlexible: boolean;
              location: string;
              price: number;
              slug: string;
              to: any;
              updatedAt: any;
              bookings: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
              category?: Array<{
                id: string;
                createdAt: any;
                updatedAt: any;
                slug: string;
                title: string;
                experiences?: Array<{
                  id: string;
                  availables: number;
                  categorySlug: string;
                  createdAt: any;
                  discount?: number;
                  from: any;
                  hasInsurance: boolean;
                  isActive: boolean;
                  isFlexible: boolean;
                  location: string;
                  price: number;
                  slug: string;
                  to: any;
                  updatedAt: any;
                  bookings: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                  category?: Array<{
                    id: string;
                    createdAt: any;
                    updatedAt: any;
                    slug: string;
                    title: string;
                  }>;
                  extra?: Array<{
                    id: string;
                    flexible?: number;
                    insurance?: number;
                    singleRoom?: number;
                  }>;
                  orders?: Array<{
                    id: string;
                    coupon?: string;
                    createdAt: any;
                    experienceId: string;
                    flexible?: boolean;
                    insurance?: boolean;
                    paymentId?: string;
                    status?: OrderStatus;
                    totalAmount: number;
                    updatedAt: any;
                    billing?: {
                      termsAcceptance?: boolean;
                      private?: {
                        birthCity: string;
                        birthProvince: string;
                        nationality: string;
                        phone: string;
                        taxIdCode: string;
                        registeredResidence: {
                          address: string;
                          addressDetails: string;
                          city: string;
                          country: string;
                          province: string;
                          zipCode: string;
                        };
                      };
                      vat?: {
                        businessName: string;
                        vat: string;
                        registeredResidence: {
                          address: string;
                          addressDetails: string;
                          city: string;
                          country: string;
                          province: string;
                          zipCode: string;
                        };
                      };
                    };
                    guests?: Array<{
                      id: string;
                      birthDate: any;
                      company?: string;
                      createdAt: any;
                      email: string;
                      firstName: string;
                      gender?: Gender;
                      isOwner: boolean;
                      lastName: string;
                      role: string;
                      roomType: RoomTypes;
                      updatedAt: any;
                    }>;
                  }>;
                }>;
              }>;
              orders?: Array<{
                id: string;
                coupon?: string;
                createdAt: any;
                experienceId: string;
                flexible?: boolean;
                insurance?: boolean;
                paymentId?: string;
                status?: OrderStatus;
                totalAmount: number;
                updatedAt: any;
                billing?: {
                  termsAcceptance?: boolean;
                  private?: {
                    birthCity: string;
                    birthProvince: string;
                    nationality: string;
                    phone: string;
                    taxIdCode: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                  vat?: {
                    businessName: string;
                    vat: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                };
                guests?: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
              }>;
            };
            guests?: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
          }>;
        }>;
      }>;
    };
    redemptions?: Array<{
      coupon?: string;
      createdAt: any;
      experienceId: string;
      flexible?: boolean;
      id: string;
      insurance?: boolean;
      paymentId?: string;
      status?: OrderStatus;
      totalAmount: number;
      updatedAt: any;
      billing?: {
        termsAcceptance?: boolean;
        private?: {
          birthCity: string;
          birthProvince: string;
          nationality: string;
          phone: string;
          taxIdCode: string;
          registeredResidence: {
            address: string;
            addressDetails: string;
            city: string;
            country: string;
            province: string;
            zipCode: string;
          };
        };
        vat?: {
          businessName: string;
          vat: string;
          registeredResidence: {
            address: string;
            addressDetails: string;
            city: string;
            country: string;
            province: string;
            zipCode: string;
          };
        };
      };
      experience?: {
        id: string;
        availables: number;
        categorySlug: string;
        createdAt: any;
        discount?: number;
        from: any;
        hasInsurance: boolean;
        isActive: boolean;
        isFlexible: boolean;
        location: string;
        price: number;
        slug: string;
        to: any;
        updatedAt: any;
        bookings: Array<{
          id: string;
          birthDate: any;
          company?: string;
          createdAt: any;
          email: string;
          firstName: string;
          gender?: Gender;
          isOwner: boolean;
          lastName: string;
          role: string;
          roomType: RoomTypes;
          updatedAt: any;
        }>;
        category?: Array<{
          id: string;
          createdAt: any;
          updatedAt: any;
          slug: string;
          title: string;
          experiences?: Array<{
            id: string;
            availables: number;
            categorySlug: string;
            createdAt: any;
            discount?: number;
            from: any;
            hasInsurance: boolean;
            isActive: boolean;
            isFlexible: boolean;
            location: string;
            price: number;
            slug: string;
            to: any;
            updatedAt: any;
            bookings: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
            category?: Array<{
              id: string;
              createdAt: any;
              updatedAt: any;
              slug: string;
              title: string;
            }>;
            extra?: Array<{
              id: string;
              flexible?: number;
              insurance?: number;
              singleRoom?: number;
            }>;
            orders?: Array<{
              id: string;
              coupon?: string;
              createdAt: any;
              experienceId: string;
              flexible?: boolean;
              insurance?: boolean;
              paymentId?: string;
              status?: OrderStatus;
              totalAmount: number;
              updatedAt: any;
              billing?: {
                termsAcceptance?: boolean;
                private?: {
                  birthCity: string;
                  birthProvince: string;
                  nationality: string;
                  phone: string;
                  taxIdCode: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
                vat?: {
                  businessName: string;
                  vat: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
              };
              experience?: {
                id: string;
                availables: number;
                categorySlug: string;
                createdAt: any;
                discount?: number;
                from: any;
                hasInsurance: boolean;
                isActive: boolean;
                isFlexible: boolean;
                location: string;
                price: number;
                slug: string;
                to: any;
                updatedAt: any;
                bookings: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
                category?: Array<{
                  id: string;
                  createdAt: any;
                  updatedAt: any;
                  slug: string;
                  title: string;
                  experiences?: Array<{
                    id: string;
                    availables: number;
                    categorySlug: string;
                    createdAt: any;
                    discount?: number;
                    from: any;
                    hasInsurance: boolean;
                    isActive: boolean;
                    isFlexible: boolean;
                    location: string;
                    price: number;
                    slug: string;
                    to: any;
                    updatedAt: any;
                    bookings: Array<{
                      id: string;
                      birthDate: any;
                      company?: string;
                      createdAt: any;
                      email: string;
                      firstName: string;
                      gender?: Gender;
                      isOwner: boolean;
                      lastName: string;
                      role: string;
                      roomType: RoomTypes;
                      updatedAt: any;
                    }>;
                    category?: Array<{
                      id: string;
                      createdAt: any;
                      updatedAt: any;
                      slug: string;
                      title: string;
                    }>;
                    extra?: Array<{
                      id: string;
                      flexible?: number;
                      insurance?: number;
                      singleRoom?: number;
                    }>;
                    orders?: Array<{
                      id: string;
                      coupon?: string;
                      createdAt: any;
                      experienceId: string;
                      flexible?: boolean;
                      insurance?: boolean;
                      paymentId?: string;
                      status?: OrderStatus;
                      totalAmount: number;
                      updatedAt: any;
                      billing?: {
                        termsAcceptance?: boolean;
                        private?: {
                          birthCity: string;
                          birthProvince: string;
                          nationality: string;
                          phone: string;
                          taxIdCode: string;
                          registeredResidence: {
                            address: string;
                            addressDetails: string;
                            city: string;
                            country: string;
                            province: string;
                            zipCode: string;
                          };
                        };
                        vat?: {
                          businessName: string;
                          vat: string;
                          registeredResidence: {
                            address: string;
                            addressDetails: string;
                            city: string;
                            country: string;
                            province: string;
                            zipCode: string;
                          };
                        };
                      };
                      guests?: Array<{
                        id: string;
                        birthDate: any;
                        company?: string;
                        createdAt: any;
                        email: string;
                        firstName: string;
                        gender?: Gender;
                        isOwner: boolean;
                        lastName: string;
                        role: string;
                        roomType: RoomTypes;
                        updatedAt: any;
                      }>;
                    }>;
                  }>;
                }>;
                orders?: Array<{
                  id: string;
                  coupon?: string;
                  createdAt: any;
                  experienceId: string;
                  flexible?: boolean;
                  insurance?: boolean;
                  paymentId?: string;
                  status?: OrderStatus;
                  totalAmount: number;
                  updatedAt: any;
                  billing?: {
                    termsAcceptance?: boolean;
                    private?: {
                      birthCity: string;
                      birthProvince: string;
                      nationality: string;
                      phone: string;
                      taxIdCode: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                    vat?: {
                      businessName: string;
                      vat: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                  };
                  guests?: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                }>;
              };
              guests?: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
            }>;
          }>;
        }>;
      };
      guests?: Array<{
        id: string;
        birthDate: any;
        company?: string;
        createdAt: any;
        email: string;
        firstName: string;
        gender?: Gender;
        isOwner: boolean;
        lastName: string;
        role: string;
        roomType: RoomTypes;
        updatedAt: any;
      }>;
    }>;
  };
};

export type GetCouponsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CouponFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CouponsOrder>;
}>;

export type GetCouponsQuery = {
  coupons: {
    totalCount: number;
    edges?: Array<{
      cursor: string;
      node: {
        associatedDomain?: string;
        associatedEmail?: string;
        code: string;
        createdAt: any;
        discount: number;
        experienceId?: string;
        expirationDate?: any;
        id: string;
        maxRedemptions: number;
        name: string;
        updatedAt: any;
        deletedAt?: any;
        experience?: {
          id: string;
          availables: number;
          categorySlug: string;
          createdAt: any;
          discount?: number;
          from: any;
          hasInsurance: boolean;
          isActive: boolean;
          isFlexible: boolean;
          location: string;
          price: number;
          slug: string;
          to: any;
          updatedAt: any;
          bookings: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
          category?: Array<{
            id: string;
            createdAt: any;
            updatedAt: any;
            slug: string;
            title: string;
            experiences?: Array<{
              id: string;
              availables: number;
              categorySlug: string;
              createdAt: any;
              discount?: number;
              from: any;
              hasInsurance: boolean;
              isActive: boolean;
              isFlexible: boolean;
              location: string;
              price: number;
              slug: string;
              to: any;
              updatedAt: any;
              bookings: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
              category?: Array<{
                id: string;
                createdAt: any;
                updatedAt: any;
                slug: string;
                title: string;
              }>;
              extra?: Array<{
                id: string;
                flexible?: number;
                insurance?: number;
                singleRoom?: number;
              }>;
              orders?: Array<{
                id: string;
                coupon?: string;
                createdAt: any;
                experienceId: string;
                flexible?: boolean;
                insurance?: boolean;
                paymentId?: string;
                status?: OrderStatus;
                totalAmount: number;
                updatedAt: any;
                billing?: {
                  termsAcceptance?: boolean;
                  private?: {
                    birthCity: string;
                    birthProvince: string;
                    nationality: string;
                    phone: string;
                    taxIdCode: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                  vat?: {
                    businessName: string;
                    vat: string;
                    registeredResidence: {
                      address: string;
                      addressDetails: string;
                      city: string;
                      country: string;
                      province: string;
                      zipCode: string;
                    };
                  };
                };
                experience?: {
                  id: string;
                  availables: number;
                  categorySlug: string;
                  createdAt: any;
                  discount?: number;
                  from: any;
                  hasInsurance: boolean;
                  isActive: boolean;
                  isFlexible: boolean;
                  location: string;
                  price: number;
                  slug: string;
                  to: any;
                  updatedAt: any;
                  bookings: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                  category?: Array<{
                    id: string;
                    createdAt: any;
                    updatedAt: any;
                    slug: string;
                    title: string;
                    experiences?: Array<{
                      id: string;
                      availables: number;
                      categorySlug: string;
                      createdAt: any;
                      discount?: number;
                      from: any;
                      hasInsurance: boolean;
                      isActive: boolean;
                      isFlexible: boolean;
                      location: string;
                      price: number;
                      slug: string;
                      to: any;
                      updatedAt: any;
                      bookings: Array<{
                        id: string;
                        birthDate: any;
                        company?: string;
                        createdAt: any;
                        email: string;
                        firstName: string;
                        gender?: Gender;
                        isOwner: boolean;
                        lastName: string;
                        role: string;
                        roomType: RoomTypes;
                        updatedAt: any;
                      }>;
                      category?: Array<{
                        id: string;
                        createdAt: any;
                        updatedAt: any;
                        slug: string;
                        title: string;
                      }>;
                      extra?: Array<{
                        id: string;
                        flexible?: number;
                        insurance?: number;
                        singleRoom?: number;
                      }>;
                      orders?: Array<{
                        id: string;
                        coupon?: string;
                        createdAt: any;
                        experienceId: string;
                        flexible?: boolean;
                        insurance?: boolean;
                        paymentId?: string;
                        status?: OrderStatus;
                        totalAmount: number;
                        updatedAt: any;
                        billing?: {
                          termsAcceptance?: boolean;
                          private?: {
                            birthCity: string;
                            birthProvince: string;
                            nationality: string;
                            phone: string;
                            taxIdCode: string;
                            registeredResidence: {
                              address: string;
                              addressDetails: string;
                              city: string;
                              country: string;
                              province: string;
                              zipCode: string;
                            };
                          };
                          vat?: {
                            businessName: string;
                            vat: string;
                            registeredResidence: {
                              address: string;
                              addressDetails: string;
                              city: string;
                              country: string;
                              province: string;
                              zipCode: string;
                            };
                          };
                        };
                        guests?: Array<{
                          id: string;
                          birthDate: any;
                          company?: string;
                          createdAt: any;
                          email: string;
                          firstName: string;
                          gender?: Gender;
                          isOwner: boolean;
                          lastName: string;
                          role: string;
                          roomType: RoomTypes;
                          updatedAt: any;
                        }>;
                      }>;
                    }>;
                  }>;
                  orders?: Array<{
                    id: string;
                    coupon?: string;
                    createdAt: any;
                    experienceId: string;
                    flexible?: boolean;
                    insurance?: boolean;
                    paymentId?: string;
                    status?: OrderStatus;
                    totalAmount: number;
                    updatedAt: any;
                    billing?: {
                      termsAcceptance?: boolean;
                      private?: {
                        birthCity: string;
                        birthProvince: string;
                        nationality: string;
                        phone: string;
                        taxIdCode: string;
                        registeredResidence: {
                          address: string;
                          addressDetails: string;
                          city: string;
                          country: string;
                          province: string;
                          zipCode: string;
                        };
                      };
                      vat?: {
                        businessName: string;
                        vat: string;
                        registeredResidence: {
                          address: string;
                          addressDetails: string;
                          city: string;
                          country: string;
                          province: string;
                          zipCode: string;
                        };
                      };
                    };
                    guests?: Array<{
                      id: string;
                      birthDate: any;
                      company?: string;
                      createdAt: any;
                      email: string;
                      firstName: string;
                      gender?: Gender;
                      isOwner: boolean;
                      lastName: string;
                      role: string;
                      roomType: RoomTypes;
                      updatedAt: any;
                    }>;
                  }>;
                };
                guests?: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
              }>;
            }>;
          }>;
        };
        redemptions?: Array<{
          coupon?: string;
          createdAt: any;
          experienceId: string;
          flexible?: boolean;
          id: string;
          insurance?: boolean;
          paymentId?: string;
          status?: OrderStatus;
          totalAmount: number;
          updatedAt: any;
          billing?: {
            termsAcceptance?: boolean;
            private?: {
              birthCity: string;
              birthProvince: string;
              nationality: string;
              phone: string;
              taxIdCode: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
            vat?: {
              businessName: string;
              vat: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
          };
          experience?: {
            id: string;
            availables: number;
            categorySlug: string;
            createdAt: any;
            discount?: number;
            from: any;
            hasInsurance: boolean;
            isActive: boolean;
            isFlexible: boolean;
            location: string;
            price: number;
            slug: string;
            to: any;
            updatedAt: any;
            bookings: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
            category?: Array<{
              id: string;
              createdAt: any;
              updatedAt: any;
              slug: string;
              title: string;
              experiences?: Array<{
                id: string;
                availables: number;
                categorySlug: string;
                createdAt: any;
                discount?: number;
                from: any;
                hasInsurance: boolean;
                isActive: boolean;
                isFlexible: boolean;
                location: string;
                price: number;
                slug: string;
                to: any;
                updatedAt: any;
                bookings: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
                category?: Array<{
                  id: string;
                  createdAt: any;
                  updatedAt: any;
                  slug: string;
                  title: string;
                }>;
                extra?: Array<{
                  id: string;
                  flexible?: number;
                  insurance?: number;
                  singleRoom?: number;
                }>;
                orders?: Array<{
                  id: string;
                  coupon?: string;
                  createdAt: any;
                  experienceId: string;
                  flexible?: boolean;
                  insurance?: boolean;
                  paymentId?: string;
                  status?: OrderStatus;
                  totalAmount: number;
                  updatedAt: any;
                  billing?: {
                    termsAcceptance?: boolean;
                    private?: {
                      birthCity: string;
                      birthProvince: string;
                      nationality: string;
                      phone: string;
                      taxIdCode: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                    vat?: {
                      businessName: string;
                      vat: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                  };
                  experience?: {
                    id: string;
                    availables: number;
                    categorySlug: string;
                    createdAt: any;
                    discount?: number;
                    from: any;
                    hasInsurance: boolean;
                    isActive: boolean;
                    isFlexible: boolean;
                    location: string;
                    price: number;
                    slug: string;
                    to: any;
                    updatedAt: any;
                    bookings: Array<{
                      id: string;
                      birthDate: any;
                      company?: string;
                      createdAt: any;
                      email: string;
                      firstName: string;
                      gender?: Gender;
                      isOwner: boolean;
                      lastName: string;
                      role: string;
                      roomType: RoomTypes;
                      updatedAt: any;
                    }>;
                    category?: Array<{
                      id: string;
                      createdAt: any;
                      updatedAt: any;
                      slug: string;
                      title: string;
                      experiences?: Array<{
                        id: string;
                        availables: number;
                        categorySlug: string;
                        createdAt: any;
                        discount?: number;
                        from: any;
                        hasInsurance: boolean;
                        isActive: boolean;
                        isFlexible: boolean;
                        location: string;
                        price: number;
                        slug: string;
                        to: any;
                        updatedAt: any;
                        bookings: Array<{
                          id: string;
                          birthDate: any;
                          company?: string;
                          createdAt: any;
                          email: string;
                          firstName: string;
                          gender?: Gender;
                          isOwner: boolean;
                          lastName: string;
                          role: string;
                          roomType: RoomTypes;
                          updatedAt: any;
                        }>;
                        category?: Array<{
                          id: string;
                          createdAt: any;
                          updatedAt: any;
                          slug: string;
                          title: string;
                        }>;
                        extra?: Array<{
                          id: string;
                          flexible?: number;
                          insurance?: number;
                          singleRoom?: number;
                        }>;
                        orders?: Array<{
                          id: string;
                          coupon?: string;
                          createdAt: any;
                          experienceId: string;
                          flexible?: boolean;
                          insurance?: boolean;
                          paymentId?: string;
                          status?: OrderStatus;
                          totalAmount: number;
                          updatedAt: any;
                          billing?: {
                            termsAcceptance?: boolean;
                            private?: {
                              birthCity: string;
                              birthProvince: string;
                              nationality: string;
                              phone: string;
                              taxIdCode: string;
                              registeredResidence: {
                                address: string;
                                addressDetails: string;
                                city: string;
                                country: string;
                                province: string;
                                zipCode: string;
                              };
                            };
                            vat?: {
                              businessName: string;
                              vat: string;
                              registeredResidence: {
                                address: string;
                                addressDetails: string;
                                city: string;
                                country: string;
                                province: string;
                                zipCode: string;
                              };
                            };
                          };
                          guests?: Array<{
                            id: string;
                            birthDate: any;
                            company?: string;
                            createdAt: any;
                            email: string;
                            firstName: string;
                            gender?: Gender;
                            isOwner: boolean;
                            lastName: string;
                            role: string;
                            roomType: RoomTypes;
                            updatedAt: any;
                          }>;
                        }>;
                      }>;
                    }>;
                    orders?: Array<{
                      id: string;
                      coupon?: string;
                      createdAt: any;
                      experienceId: string;
                      flexible?: boolean;
                      insurance?: boolean;
                      paymentId?: string;
                      status?: OrderStatus;
                      totalAmount: number;
                      updatedAt: any;
                      billing?: {
                        termsAcceptance?: boolean;
                        private?: {
                          birthCity: string;
                          birthProvince: string;
                          nationality: string;
                          phone: string;
                          taxIdCode: string;
                          registeredResidence: {
                            address: string;
                            addressDetails: string;
                            city: string;
                            country: string;
                            province: string;
                            zipCode: string;
                          };
                        };
                        vat?: {
                          businessName: string;
                          vat: string;
                          registeredResidence: {
                            address: string;
                            addressDetails: string;
                            city: string;
                            country: string;
                            province: string;
                            zipCode: string;
                          };
                        };
                      };
                      guests?: Array<{
                        id: string;
                        birthDate: any;
                        company?: string;
                        createdAt: any;
                        email: string;
                        firstName: string;
                        gender?: Gender;
                        isOwner: boolean;
                        lastName: string;
                        role: string;
                        roomType: RoomTypes;
                        updatedAt: any;
                      }>;
                    }>;
                  };
                  guests?: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                }>;
              }>;
            }>;
          };
          guests?: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
        }>;
      };
    }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type GetDealQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetDealQuery = {
  deal: {
    id: string;
    title: string;
    companyName?: string;
    stage?: DealStage;
    createdAt: any;
    updatedAt: any;
    scope?: Array<DealScope>;
    expectingStartDate?: any;
    expectingCloseDate?: any;
    field?: Array<string>;
    ratecardMax?: number;
    refCode: string;
    zohoId: string;
    fullTime?: boolean;
    partTime?: string;
    onsiteTrip?: string;
    description?: string;
    speciality?: string;
    isArchived: boolean;
    note?: string;
    sentAt?: any;
    user?: { firstname: string; lastname: string };
  };
};

export type DealCommonDataQueryVariables = Exact<{ [key: string]: never }>;

export type DealCommonDataQuery = {
  dealCommonData: {
    availability: Array<boolean>;
    category: Array<DealCategory>;
    dealTalentSelectionStatus: Array<DealTalentSelectionStatus>;
    scope: Array<DealScope>;
    stage: Array<DealStage>;
    type: Array<DealStrategy>;
  };
};

export type GetDealTalentSelectionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<DealTalentSelectionsFilterInput>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDealTalentSelectionsQuery = {
  dealTalentSelections: {
    totalCount: number;
    nodes?: Array<{
      note?: string;
      status: DealTalentSelectionStatus;
      id: string;
      agreedRatecard?: string;
      manuallyAdded: boolean;
      talentPresentation?: string;
      maybeCount: Array<string>;
      shortlistCount: Array<string>;
      longListCount: Array<string>;
      notAvailableCount: Array<string>;
      rejectedCount: Array<string>;
      talent: {
        id: string;
        email?: string;
        firstName?: string;
        lastName?: string;
        mobile?: string;
        rate?: string;
        zohoId?: string;
        freelance?: string;
        documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
      };
    }>;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
      endCursor?: string;
    };
  };
};

export type GetDealsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<DealsFilterInput>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetDealsQuery = {
  deals: {
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
      endCursor?: string;
    };
    nodes?: Array<{
      id: string;
      title: string;
      companyName?: string;
      stage?: DealStage;
      createdAt: any;
      updatedAt: any;
      isArchived: boolean;
      poolCount?: number;
      shortlistCount?: number;
      user?: { firstname: string; lastname: string };
    }>;
  };
};

export type GetEmailTemplateQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetEmailTemplateQuery = {
  emailTemplate: {
    body: Array<string>;
    category?: TemplateCategory;
    country: Country;
    createdAt: any;
    heading: string;
    id: string;
    sign: Array<string>;
    subject: string;
    title: string;
    typology?: TemplateType;
    updatedAt: any;
    wishes: string;
  };
};

export type GetEmailTemplatesQueryVariables = Exact<{
  filters?: InputMaybe<EmailTemplateFilterInput>;
  orderBy?: InputMaybe<EmailTemplateOrder>;
}>;

export type GetEmailTemplatesQuery = {
  emailTemplates: Array<{
    typology?: TemplateType;
    templates?: Array<{
      body: Array<string>;
      category?: TemplateCategory;
      country: Country;
      createdAt: any;
      heading: string;
      id: string;
      sign: Array<string>;
      subject: string;
      title: string;
      typology?: TemplateType;
      updatedAt: any;
      wishes: string;
    }>;
  }>;
};

export type EmailTemplateCommonDataQueryVariables = Exact<{ [key: string]: never }>;

export type EmailTemplateCommonDataQuery = {
  emailTemplatesCommonData: Array<{
    category?: TemplateCategory;
    typologies?: Array<TemplateType>;
  }>;
};

export type ExperienceQueryQueryVariables = Exact<{
  experienceId: Scalars['String']['input'];
}>;

export type ExperienceQueryQuery = {
  experience: {
    id: string;
    availables: number;
    categorySlug: string;
    createdAt: any;
    discount?: number;
    from: any;
    hasInsurance: boolean;
    isActive: boolean;
    isFlexible: boolean;
    location: string;
    price: number;
    slug: string;
    to: any;
    updatedAt: any;
    bookings: Array<{
      id: string;
      birthDate: any;
      company?: string;
      createdAt: any;
      email: string;
      firstName: string;
      gender?: Gender;
      isOwner: boolean;
      lastName: string;
      role: string;
      roomType: RoomTypes;
      updatedAt: any;
    }>;
    category?: Array<{
      id: string;
      createdAt: any;
      updatedAt: any;
      slug: string;
      title: string;
      experiences?: Array<{
        id: string;
        availables: number;
        categorySlug: string;
        createdAt: any;
        discount?: number;
        from: any;
        hasInsurance: boolean;
        isActive: boolean;
        isFlexible: boolean;
        location: string;
        price: number;
        slug: string;
        to: any;
        updatedAt: any;
        bookings: Array<{
          id: string;
          birthDate: any;
          company?: string;
          createdAt: any;
          email: string;
          firstName: string;
          gender?: Gender;
          isOwner: boolean;
          lastName: string;
          role: string;
          roomType: RoomTypes;
          updatedAt: any;
        }>;
        category?: Array<{
          id: string;
          createdAt: any;
          updatedAt: any;
          slug: string;
          title: string;
        }>;
        extra?: Array<{ id: string; flexible?: number; insurance?: number; singleRoom?: number }>;
        orders?: Array<{
          id: string;
          coupon?: string;
          createdAt: any;
          experienceId: string;
          flexible?: boolean;
          insurance?: boolean;
          paymentId?: string;
          status?: OrderStatus;
          totalAmount: number;
          updatedAt: any;
          billing?: {
            termsAcceptance?: boolean;
            private?: {
              birthCity: string;
              birthProvince: string;
              nationality: string;
              phone: string;
              taxIdCode: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
            vat?: {
              businessName: string;
              vat: string;
              registeredResidence: {
                address: string;
                addressDetails: string;
                city: string;
                country: string;
                province: string;
                zipCode: string;
              };
            };
          };
          experience?: {
            id: string;
            availables: number;
            categorySlug: string;
            createdAt: any;
            discount?: number;
            from: any;
            hasInsurance: boolean;
            isActive: boolean;
            isFlexible: boolean;
            location: string;
            price: number;
            slug: string;
            to: any;
            updatedAt: any;
            bookings: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
            category?: Array<{
              id: string;
              createdAt: any;
              updatedAt: any;
              slug: string;
              title: string;
              experiences?: Array<{
                id: string;
                availables: number;
                categorySlug: string;
                createdAt: any;
                discount?: number;
                from: any;
                hasInsurance: boolean;
                isActive: boolean;
                isFlexible: boolean;
                location: string;
                price: number;
                slug: string;
                to: any;
                updatedAt: any;
                bookings: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
                category?: Array<{
                  id: string;
                  createdAt: any;
                  updatedAt: any;
                  slug: string;
                  title: string;
                }>;
                extra?: Array<{
                  id: string;
                  flexible?: number;
                  insurance?: number;
                  singleRoom?: number;
                }>;
                orders?: Array<{
                  id: string;
                  coupon?: string;
                  createdAt: any;
                  experienceId: string;
                  flexible?: boolean;
                  insurance?: boolean;
                  paymentId?: string;
                  status?: OrderStatus;
                  totalAmount: number;
                  updatedAt: any;
                  billing?: {
                    termsAcceptance?: boolean;
                    private?: {
                      birthCity: string;
                      birthProvince: string;
                      nationality: string;
                      phone: string;
                      taxIdCode: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                    vat?: {
                      businessName: string;
                      vat: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                  };
                  guests?: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                }>;
              }>;
            }>;
            orders?: Array<{
              id: string;
              coupon?: string;
              createdAt: any;
              experienceId: string;
              flexible?: boolean;
              insurance?: boolean;
              paymentId?: string;
              status?: OrderStatus;
              totalAmount: number;
              updatedAt: any;
              billing?: {
                termsAcceptance?: boolean;
                private?: {
                  birthCity: string;
                  birthProvince: string;
                  nationality: string;
                  phone: string;
                  taxIdCode: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
                vat?: {
                  businessName: string;
                  vat: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
              };
              guests?: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
            }>;
          };
          guests?: Array<{
            id: string;
            birthDate: any;
            company?: string;
            createdAt: any;
            email: string;
            firstName: string;
            gender?: Gender;
            isOwner: boolean;
            lastName: string;
            role: string;
            roomType: RoomTypes;
            updatedAt: any;
          }>;
        }>;
      }>;
    }>;
  };
};

export type GetExperiencesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ExperiencesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExperienceOrder>;
}>;

export type GetExperiencesQuery = {
  experiences: {
    totalCount: number;
    edges?: Array<{
      cursor: string;
      node: {
        id: string;
        availables: number;
        categorySlug: string;
        createdAt: any;
        discount?: number;
        from: any;
        hasInsurance: boolean;
        isActive: boolean;
        isFlexible: boolean;
        location: string;
        price: number;
        slug: string;
        to: any;
        updatedAt: any;
        bookings: Array<{
          id: string;
          birthDate: any;
          company?: string;
          createdAt: any;
          email: string;
          firstName: string;
          gender?: Gender;
          isOwner: boolean;
          lastName: string;
          role: string;
          roomType: RoomTypes;
          updatedAt: any;
        }>;
        category?: Array<{
          id: string;
          createdAt: any;
          updatedAt: any;
          slug: string;
          title: string;
          experiences?: Array<{
            id: string;
            availables: number;
            categorySlug: string;
            createdAt: any;
            discount?: number;
            from: any;
            hasInsurance: boolean;
            isActive: boolean;
            isFlexible: boolean;
            location: string;
            price: number;
            slug: string;
            to: any;
            updatedAt: any;
            bookings: Array<{
              id: string;
              birthDate: any;
              company?: string;
              createdAt: any;
              email: string;
              firstName: string;
              gender?: Gender;
              isOwner: boolean;
              lastName: string;
              role: string;
              roomType: RoomTypes;
              updatedAt: any;
            }>;
            category?: Array<{
              id: string;
              createdAt: any;
              updatedAt: any;
              slug: string;
              title: string;
            }>;
            extra?: Array<{
              id: string;
              flexible?: number;
              insurance?: number;
              singleRoom?: number;
            }>;
            orders?: Array<{
              id: string;
              coupon?: string;
              createdAt: any;
              experienceId: string;
              flexible?: boolean;
              insurance?: boolean;
              paymentId?: string;
              status?: OrderStatus;
              totalAmount: number;
              updatedAt: any;
              billing?: {
                termsAcceptance?: boolean;
                private?: {
                  birthCity: string;
                  birthProvince: string;
                  nationality: string;
                  phone: string;
                  taxIdCode: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
                vat?: {
                  businessName: string;
                  vat: string;
                  registeredResidence: {
                    address: string;
                    addressDetails: string;
                    city: string;
                    country: string;
                    province: string;
                    zipCode: string;
                  };
                };
              };
              experience?: {
                id: string;
                availables: number;
                categorySlug: string;
                createdAt: any;
                discount?: number;
                from: any;
                hasInsurance: boolean;
                isActive: boolean;
                isFlexible: boolean;
                location: string;
                price: number;
                slug: string;
                to: any;
                updatedAt: any;
                bookings: Array<{
                  id: string;
                  birthDate: any;
                  company?: string;
                  createdAt: any;
                  email: string;
                  firstName: string;
                  gender?: Gender;
                  isOwner: boolean;
                  lastName: string;
                  role: string;
                  roomType: RoomTypes;
                  updatedAt: any;
                }>;
                category?: Array<{
                  id: string;
                  createdAt: any;
                  updatedAt: any;
                  slug: string;
                  title: string;
                  experiences?: Array<{
                    id: string;
                    availables: number;
                    categorySlug: string;
                    createdAt: any;
                    discount?: number;
                    from: any;
                    hasInsurance: boolean;
                    isActive: boolean;
                    isFlexible: boolean;
                    location: string;
                    price: number;
                    slug: string;
                    to: any;
                    updatedAt: any;
                    bookings: Array<{
                      id: string;
                      birthDate: any;
                      company?: string;
                      createdAt: any;
                      email: string;
                      firstName: string;
                      gender?: Gender;
                      isOwner: boolean;
                      lastName: string;
                      role: string;
                      roomType: RoomTypes;
                      updatedAt: any;
                    }>;
                    category?: Array<{
                      id: string;
                      createdAt: any;
                      updatedAt: any;
                      slug: string;
                      title: string;
                    }>;
                    extra?: Array<{
                      id: string;
                      flexible?: number;
                      insurance?: number;
                      singleRoom?: number;
                    }>;
                    orders?: Array<{
                      id: string;
                      coupon?: string;
                      createdAt: any;
                      experienceId: string;
                      flexible?: boolean;
                      insurance?: boolean;
                      paymentId?: string;
                      status?: OrderStatus;
                      totalAmount: number;
                      updatedAt: any;
                      billing?: {
                        termsAcceptance?: boolean;
                        private?: {
                          birthCity: string;
                          birthProvince: string;
                          nationality: string;
                          phone: string;
                          taxIdCode: string;
                          registeredResidence: {
                            address: string;
                            addressDetails: string;
                            city: string;
                            country: string;
                            province: string;
                            zipCode: string;
                          };
                        };
                        vat?: {
                          businessName: string;
                          vat: string;
                          registeredResidence: {
                            address: string;
                            addressDetails: string;
                            city: string;
                            country: string;
                            province: string;
                            zipCode: string;
                          };
                        };
                      };
                      guests?: Array<{
                        id: string;
                        birthDate: any;
                        company?: string;
                        createdAt: any;
                        email: string;
                        firstName: string;
                        gender?: Gender;
                        isOwner: boolean;
                        lastName: string;
                        role: string;
                        roomType: RoomTypes;
                        updatedAt: any;
                      }>;
                    }>;
                  }>;
                }>;
                orders?: Array<{
                  id: string;
                  coupon?: string;
                  createdAt: any;
                  experienceId: string;
                  flexible?: boolean;
                  insurance?: boolean;
                  paymentId?: string;
                  status?: OrderStatus;
                  totalAmount: number;
                  updatedAt: any;
                  billing?: {
                    termsAcceptance?: boolean;
                    private?: {
                      birthCity: string;
                      birthProvince: string;
                      nationality: string;
                      phone: string;
                      taxIdCode: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                    vat?: {
                      businessName: string;
                      vat: string;
                      registeredResidence: {
                        address: string;
                        addressDetails: string;
                        city: string;
                        country: string;
                        province: string;
                        zipCode: string;
                      };
                    };
                  };
                  guests?: Array<{
                    id: string;
                    birthDate: any;
                    company?: string;
                    createdAt: any;
                    email: string;
                    firstName: string;
                    gender?: Gender;
                    isOwner: boolean;
                    lastName: string;
                    role: string;
                    roomType: RoomTypes;
                    updatedAt: any;
                  }>;
                }>;
              };
              guests?: Array<{
                id: string;
                birthDate: any;
                company?: string;
                createdAt: any;
                email: string;
                firstName: string;
                gender?: Gender;
                isOwner: boolean;
                lastName: string;
                role: string;
                roomType: RoomTypes;
                updatedAt: any;
              }>;
            }>;
          }>;
        }>;
      };
    }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type GetExpertiseQueryVariables = Exact<{ [key: string]: never }>;

export type GetExpertiseQuery = {
  expertises: {
    totalCount: number;
    edges?: Array<{ cursor: string; node: { id: string; name: string } }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type GetExpertiseAnalyticsQueryVariables = Exact<{
  filters?: InputMaybe<ExpertiseAnalyticsFilterInput>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetExpertiseAnalyticsQuery = {
  expertiseAnalytics: {
    expertises: Array<{
      expertise: string;
      skills?: Array<{
        skill: string;
        count: number;
        status?: Array<{ status: string; count: number }>;
      }>;
      technologies?: Array<{
        technology: string;
        count: number;
        status?: Array<{ status: string; count: number }>;
      }>;
    }>;
  };
};

export type ExperienceLocationQueryQueryVariables = Exact<{
  experienceId: Scalars['String']['input'];
}>;

export type ExperienceLocationQueryQuery = {
  experience: { location: string; from: any; to: any; bookings: Array<{ id: string }> };
};

export type SearchAgenciesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AgenciesFilterInput>;
  keyword: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgenciesOrder>;
}>;

export type SearchAgenciesQuery = {
  searchAgencies: {
    totalCount: number;
    edges?: Array<{
      cursor: string;
      node: {
        additionalInfo?: string;
        biography?: string;
        collaborationNotes?: string;
        collaborations?: Array<string>;
        companyName?: string;
        country?: Country;
        createdAt: any;
        deletedAt?: any;
        email?: string;
        employees?: string;
        experience?: string;
        id: string;
        industries?: Array<string>;
        internationalProjects?: boolean;
        interviewSent?: boolean;
        mobile?: string;
        newsletter?: boolean;
        notes?: string;
        rejectSent?: boolean;
        reminderSent?: boolean;
        search?: Array<string>;
        status?: TalentStatus;
        termsAcceptance?: boolean;
        updatedAt: any;
        vat?: string;
        website?: string;
        workplace?: string;
        documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
        languages: Array<{ language: string; languageId: string; level: string }>;
        notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
        portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
        specialities?: Array<{ expertise: string; skills: Array<string> }>;
        tools?: Array<{
          expertise: string;
          technologies: Array<{ level: string; technology: string; technologyId: string }>;
        }>;
      };
    }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type SearchTalentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TalentsFilterInput>;
  keyword: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TalentOrder>;
}>;

export type SearchTalentsQuery = {
  searchTalents: {
    totalCount: number;
    edges?: Array<{
      cursor: string;
      node: {
        additionalInfo?: string;
        biography?: string;
        collaborationNotes?: string;
        collaborations?: Array<string>;
        country?: Country;
        createdAt: any;
        deletedAt?: any;
        digitalNomad?: boolean;
        prediction?: string;
        email?: string;
        experience?: string;
        firstName?: string;
        freelance?: string;
        github?: string;
        id: string;
        zohoId?: string;
        industries?: Array<string>;
        internationalProjects?: boolean;
        interviewSent?: boolean;
        interviewRecapSent?: boolean;
        lastName?: string;
        mobile?: string;
        newsletter?: boolean;
        notes?: string;
        openToEmployment?: boolean;
        rate?: string;
        rejectSent?: boolean;
        reminderSent?: boolean;
        search?: Array<string>;
        status?: TalentStatus;
        termsAcceptance?: boolean;
        updatedAt: any;
        workplace?: string;
        documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
        inferenceReview?: {
          createdAt: any;
          id: string;
          note?: string;
          prediction: string;
          review: string;
          updatedAt: any;
          user: {
            id: string;
            calendlyUrl?: string;
            createdAt: any;
            deletedAt?: any;
            email: string;
            firstname: string;
            lastname: string;
            role: UserRole;
            sign?: string;
            updatedAt: any;
            username: string;
          };
        };
        languages?: Array<{ language: string; languageId: string; level: string }>;
        notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
        portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
        specialities?: Array<{ expertise: string; skills: Array<string> }>;
        tools?: Array<{
          expertise: string;
          technologies: Array<{ level: string; technology: string; technologyId: string }>;
        }>;
      };
    }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type GetSkillQueryVariables = Exact<{
  skillId: Scalars['String']['input'];
}>;

export type GetSkillQuery = {
  skill: { id: string; name: string; expertiseId: string; expertise: { id: string; name: string } };
};

export type GetSkillsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetSkillsQuery = {
  skills: {
    totalCount: number;
    edges?: Array<{
      cursor: string;
      node: {
        id: string;
        name: string;
        expertiseId: string;
        expertise: { id: string; name: string };
      };
    }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type TalentQueryQueryVariables = Exact<{
  talentId: Scalars['String']['input'];
}>;

export type TalentQueryQuery = {
  talent: {
    additionalInfo?: string;
    biography?: string;
    collaborationNotes?: string;
    collaborations?: Array<string>;
    country?: Country;
    createdAt: any;
    deletedAt?: any;
    digitalNomad?: boolean;
    prediction?: string;
    email?: string;
    experience?: string;
    firstName?: string;
    freelance?: string;
    github?: string;
    id: string;
    zohoId?: string;
    industries?: Array<string>;
    internationalProjects?: boolean;
    interviewSent?: boolean;
    interviewRecapSent?: boolean;
    lastName?: string;
    mobile?: string;
    newsletter?: boolean;
    notes?: string;
    openToEmployment?: boolean;
    rate?: string;
    rejectSent?: boolean;
    reminderSent?: boolean;
    search?: Array<string>;
    status?: TalentStatus;
    termsAcceptance?: boolean;
    updatedAt: any;
    workplace?: string;
    documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
    inferenceReview?: {
      createdAt: any;
      id: string;
      note?: string;
      prediction: string;
      review: string;
      updatedAt: any;
      user: {
        id: string;
        calendlyUrl?: string;
        createdAt: any;
        deletedAt?: any;
        email: string;
        firstname: string;
        lastname: string;
        role: UserRole;
        sign?: string;
        updatedAt: any;
        username: string;
      };
    };
    languages?: Array<{ language: string; languageId: string; level: string }>;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
    specialities?: Array<{ expertise: string; skills: Array<string> }>;
    tools?: Array<{
      expertise: string;
      technologies: Array<{ level: string; technology: string; technologyId: string }>;
    }>;
  };
};

export type TalentCommonDataQueryVariables = Exact<{ [key: string]: never }>;

export type TalentCommonDataQuery = {
  talentCommonData: {
    collaborations: Array<string>;
    countries: Array<Country>;
    freelance: Array<string>;
    industries: Array<string>;
    languages: Array<string>;
    rates: Array<string>;
    seniorities: Array<string>;
    status: Array<TalentStatus>;
    workplaces: Array<string>;
    prediction: Array<PredictionStatus>;
    specialities: Array<{ expertise: string; skills: Array<string> }>;
    tools: Array<{ expertise: string; technologies: Array<string> }>;
  };
};

export type GetTalentInterviewQueryVariables = Exact<{
  talentId: Scalars['String']['input'];
}>;

export type GetTalentInterviewQuery = {
  talent: {
    firstName?: string;
    lastName?: string;
    email?: string;
    notes?: string;
    biography?: string;
    prediction?: string;
    notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
    inferenceReview?: {
      createdAt: any;
      id: string;
      note?: string;
      prediction: string;
      review: string;
      updatedAt: any;
      user: {
        id: string;
        calendlyUrl?: string;
        createdAt: any;
        deletedAt?: any;
        email: string;
        firstname: string;
        lastname: string;
        role: UserRole;
        sign?: string;
        updatedAt: any;
        username: string;
      };
    };
  };
};

export type GetTalentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TalentsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TalentOrder>;
}>;

export type GetTalentsQuery = {
  talents: {
    totalCount: number;
    edges?: Array<{
      cursor: string;
      node: {
        additionalInfo?: string;
        biography?: string;
        collaborationNotes?: string;
        collaborations?: Array<string>;
        country?: Country;
        createdAt: any;
        deletedAt?: any;
        digitalNomad?: boolean;
        prediction?: string;
        email?: string;
        experience?: string;
        firstName?: string;
        freelance?: string;
        github?: string;
        id: string;
        zohoId?: string;
        industries?: Array<string>;
        internationalProjects?: boolean;
        interviewSent?: boolean;
        interviewRecapSent?: boolean;
        lastName?: string;
        mobile?: string;
        newsletter?: boolean;
        notes?: string;
        openToEmployment?: boolean;
        rate?: string;
        rejectSent?: boolean;
        reminderSent?: boolean;
        search?: Array<string>;
        status?: TalentStatus;
        termsAcceptance?: boolean;
        updatedAt: any;
        workplace?: string;
        documents?: Array<{ documentId: string; type: DocumentType; url: string }>;
        inferenceReview?: {
          createdAt: any;
          id: string;
          note?: string;
          prediction: string;
          review: string;
          updatedAt: any;
          user: {
            id: string;
            calendlyUrl?: string;
            createdAt: any;
            deletedAt?: any;
            email: string;
            firstname: string;
            lastname: string;
            role: UserRole;
            sign?: string;
            updatedAt: any;
            username: string;
          };
        };
        languages?: Array<{ language: string; languageId: string; level: string }>;
        notesDetails?: { firstName: string; lastName: string; updatedAt: any; userId: string };
        portfolioLinks?: Array<{ link?: PortfolioType; url?: string }>;
        specialities?: Array<{ expertise: string; skills: Array<string> }>;
        tools?: Array<{
          expertise: string;
          technologies: Array<{ level: string; technology: string; technologyId: string }>;
        }>;
      };
    }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type TalentsAnalyticsQueryVariables = Exact<{ [key: string]: never }>;

export type TalentsAnalyticsQuery = {
  talentsAnalytics: {
    totalCount: number;
    status?: Array<{ status: TalentStatus; ranges: Array<{ range: TimeRange; count: number }> }>;
  };
};

export type TalentsCounterQueryVariables = Exact<{ [key: string]: never }>;

export type TalentsCounterQuery = { talentsCounter: Array<{ _count: number; status: string }> };

export type GetTalentsIdsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TalentsFilterInput>;
  keyword: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TalentOrder>;
}>;

export type GetTalentsIdsQuery = { searchTalents: { edges?: Array<{ node: { id: string } }> } };

export type GetTechnologiesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetTechnologiesQuery = {
  technologies: {
    totalCount: number;
    edges?: Array<{
      cursor: string;
      node: { id: string; name: string; expertises: Array<{ id: string; name: string }> };
    }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type GetTechnologyQueryVariables = Exact<{
  technologyId: Scalars['String']['input'];
}>;

export type GetTechnologyQuery = {
  technology: { id: string; name: string; expertises: Array<{ id: string; name: string }> };
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type GetUserQuery = {
  user: {
    id: string;
    calendlyUrl?: string;
    createdAt: any;
    deletedAt?: any;
    email: string;
    firstname: string;
    lastname: string;
    role: UserRole;
    sign?: string;
    updatedAt: any;
    username: string;
  };
};

export type UserAnalyticsQueryVariables = Exact<{ [key: string]: never }>;

export type UserAnalyticsQuery = {
  userAnalytics: {
    firstname: string;
    analytics: Array<{
      range: string;
      totalCount: number;
      screening?: { expertises: Array<{ expertise: string; count: number }> };
      interview?: { expertises: Array<{ expertise: string; count: number }> };
    }>;
  };
};

export type GetUsersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UsersFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrder>;
}>;

export type GetUsersQuery = {
  users: {
    edges?: Array<{
      cursor: string;
      node: {
        id: string;
        calendlyUrl?: string;
        createdAt: any;
        deletedAt?: any;
        email: string;
        firstname: string;
        lastname: string;
        role: UserRole;
        sign?: string;
        updatedAt: any;
        username: string;
      };
    }>;
    pageInfo: {
      endCursor?: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  };
};

export type UsersAnalyticsQueryVariables = Exact<{
  filters: UserAnalyticsFilterInput;
}>;

export type UsersAnalyticsQuery = {
  usersAnalytics: Array<{
    firstname: string;
    lastname: string;
    analytics: Array<{
      range: string;
      totalCount: number;
      interview?: {
        totalCount: number;
        expertises: Array<{
          count: number;
          expertise: string;
          status?: Array<{ count: number; status: string }>;
        }>;
      };
      screening?: {
        totalCount: number;
        expertises: Array<{
          count: number;
          expertise: string;
          status?: Array<{ count: number; status: string }>;
        }>;
      };
    }>;
  }>;
};

export type GetWorkplaceAnalyticsQueryVariables = Exact<{
  filters?: InputMaybe<WorkplaceAnalyticsFilterInput>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWorkplaceAnalyticsQuery = {
  workplaceAnalytics: {
    workplaces?: Array<{
      workplace: string;
      count: number;
      status?: Array<{ status: string; count: number }>;
    }>;
    freelance?: Array<{
      freelance: string;
      count: number;
      status?: Array<{ status: string; count: number }>;
    }>;
  };
};
