import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { GetTalentInterviewQuery, GetTalentInterviewQueryVariables } from '@/gql/Gql.types';
import { usersFragment } from '../fragments/users';

const talentInterviewQuery = gql`
  query getTalentInterview($talentId: String!) {
    talent(talentId: $talentId) {
      firstName
      lastName
      email
      notes
      biography
      notesDetails {
        firstName
        lastName
        updatedAt
        userId
      }
      prediction
      inferenceReview {
        createdAt
        id
        note
        prediction
        review
        updatedAt
        user {
          ...node
        }
      }
    }
  }
  ${usersFragment}
`;

export const getTalentInterview = (
  props?: GetTalentInterviewQueryVariables,
  options?: UseQueryOptions<
    GetTalentInterviewQuery,
    GetTalentInterviewQueryVariables,
    GetTalentInterviewQuery,
    any[]
  >
): UseQueryReturnType<GetTalentInterviewQuery, GetTalentInterviewQueryVariables> =>
  useGraphql({
    query: talentInterviewQuery,
    variables: props,
    options,
  });
