<script setup lang="ts">
import { createInferenceReview } from '@/api/mutations/createInferenceReview';
import { exportCsvTalents } from '@/api/mutations/exportCsvTalents';
import { hardDeleteTalents } from '@/api/mutations/hardDeleteTalents';
import { inviteTalent } from '@/api/mutations/inviteTalent';
import { rejectTalent } from '@/api/mutations/rejectTalent';
import { remindInviteTalent } from '@/api/mutations/remindInviteTalent';
import { restoreTalents } from '@/api/mutations/restoreTalents';
import { softDeleteTalents } from '@/api/mutations/softDeleteTalents';
import { updateInferenceReview } from '@/api/mutations/updateInferenceReview';
import { updateTalentNotes } from '@/api/mutations/updateTalentNotes';
import { searchTalents } from '@/api/queries/searchTalents';
import { getTalentCommonData } from '@/api/queries/talentCommonData';
import { getTalentInterview } from '@/api/queries/talentInterview';
import { getTalents } from '@/api/queries/talents';
import { getTalentsCounter } from '@/api/queries/talentsCounter';
import Button from '@/components/atoms/Button.vue';
import Modal from '@/components/atoms/Modal.vue';
import MultiSelect from '@/components/atoms/MultiSelect.vue';
import Notification from '@/components/atoms/Notification.vue';
import Select from '@/components/atoms/Select.vue';
import Nav from '@/components/molecules/Nav.vue';
import Pagination from '@/components/molecules/Pagination.vue';
import TextAreaForm from '@/components/molecules/TextAreaForm.vue';
import ModalEvaluateStatus from '@/components/organisms/CreateUpdateForm/components/ModalEvaluateStatus.vue';
import Filters from '@/components/organisms/Filters/Filters.vue';
import Table from '@/components/organisms/Table/Table.vue';
import { useFilterDataMapper } from '@/composables/talents/useFilterDataMapper';
import { useMappedQueryFilter } from '@/composables/talents/useMappedQueryFilter';
import { useTabItemsMapper } from '@/composables/talents/useTabItemsMapper';
import { useTotalCount } from '@/composables/talents/useTotalCount';
import { IFiltersOptions, useFilters } from '@/composables/useFilters';
import { useMappedFilters } from '@/composables/useMappedFilters';
import { INavOptions, useNav } from '@/composables/useNav';
import { usePagination } from '@/composables/usePagination';
import { ITab, useTabOptions } from '@/composables/useTabOptions';
import { useTable } from '@/composables/useTable';
import { useTableItemsMapper } from '@/composables/useTableItemsMapper';
import { default as GenericStrings, default as strings } from '@/data/GenericStrings.json';
import talentsString from '@/data/Talents.json';
import {
  MIN_LENGTH_SEARCH,
  NOTIFICATION_DURATION,
  TIME_DELAY_SEARCH,
  TIME_TO_RESTORE_VARIABLES,
} from '@/data/constants';
import type {
  CreateInferenceReviewInput,
  MutationCreateInferenceReviewArgs,
  MutationExportTalentsArgs,
  MutationRejectTalentArgs,
  MutationUpdateTalentNotesArgs,
  QuerySearchTalentsArgs,
  QueryTalentsArgs,
  MutationUpdateTalentBiographyArgs,
  Talent,
} from '@/gql/Gql.types';
import { TalentStatus } from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';
import type { IColumn } from '@/models/tableModel';
import { ITableColumns } from '@/models/tableModel';
import { useTalentCommonData } from '@/stores/talentCommonData';
import { useUserStore } from '@/stores/user';
import moment from 'moment';
import 'moment/dist/locale/it';
import { storeToRefs } from 'pinia';
import { computed, onMounted, reactive, ref, watch, type ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useTalentsStore } from '@/stores/talents';

import { updateTalentBiography } from '@/api/mutations/updateTalentBiography';

const talentsStore = useTalentsStore();

type actionModal = 'interview' | 'remindInterview' | 'reject' | 'addNote';
type NotificationStatus = 'success' | 'error' | '';

const { selectAction } = strings;
const {
  buttonAction,
  selectVisibleTalents,
  inviteInterview,
  remindInviteInterview,
  interviewParagraphModal,
  interviewSentModal,
  rejectParagraphModal,
  rejectTalentSentModal,
  rejectError,
  restoreSuccessful,
  deleteTalentsSuccess,
  deleteTalentsError,
  addTalent,
  talent: talentString,
} = talentsString;

const {
  talents: stringTalents,
  futureTalent,
  cantera,
  moveIn: moveInString,
  confirmButton,
  understand,
  cancel,
  resultFound,
  addNote: addNoteString,
  notePlaceholder,
  saveNote,
  successAddNote: successAddNoteString,
  errorAddNote: errorAddNoteString,
  future,
  genericError,
  eligible,
  successSendReview,
  errorSendReview,
  successUpdateReview,
  errorUpdateReview,
  selectColumns,
  lastUpdate,
  user,
} = GenericStrings;

const route = useRoute();
const router = useRouter();

const {
  activeNav,
  setActiveNav,
  items: navItems,
} = useNav({ nav: INavOptions.Talents, pathName: route.matched[0].name as string });
const isDeleted = computed(() => activeNav.value.value === 'DELETED');

const {
  items: tabItems,
  setActiveTab,
  activeTab,
} = useTabOptions({
  tab: ITab.Talents,
  pathName: route.matched[0].name as string,
});

const userStore = useUserStore();
const { userRole } = storeToRefs(userStore);

const queryFilterMapped = computed(() => useMappedQueryFilter(route.query));

const filtersQuery = reactive(
  (function () {
    const json: string =
      localStorage.getItem('filter-talent') || '{"operator": "", "filters": [], "keyword": ""}';
    return !queryFilterMapped.value.operator && !queryFilterMapped.value.keyword
      ? JSON.parse(json)
      : queryFilterMapped.value;
  })()
);

const { columns, selectItems, resetSelection, selectedItems } = useTable();

const { data: tabCounter, refetch: refetchTabCounter } = getTalentsCounter();
const { data: filtersData } = getTalentCommonData();

const talentsVisibleResultString: string = localStorage.getItem('talents-visible-result') || '';
const searchTerms = ref('');
const page = ref(1);

const visibleResult = ref<number>(
  talentsVisibleResultString !== ''
    ? JSON.parse(talentsVisibleResultString)
    : +selectVisibleTalents.options[0].value
);

const initialVisibleResult = reactive(
  (function () {
    return talentsVisibleResultString !== ''
      ? {
          value: JSON.parse(talentsVisibleResultString),
          label: JSON.parse(talentsVisibleResultString),
        }
      : selectVisibleTalents.options[0];
  })()
);

const talentIdsSelected = ref({ talentIds: [''] });

const filterApplied = ref(false);
const submitted = ref(false);
const resultTalentsFound = ref(false);

const action = ref('');
const sendingActionTalent = ref(false);
const talentStatus = ref<TalentStatus>();
const actionOpenTalentModal = ref(false);
const typeModal = ref<actionModal>();

const talentId = reactive({ talentId: '' });
const notificationPopup = ref(false);
const note = ref('');
const bio = ref('');
const enableAddNote = ref(false);
const enableUpdateBio = ref(false);
const startDownload = ref(false);
const modalTimeout = ref();
const openModalEvaluateStatus = ref(false);
const currentNotification = ref();

const talentCommonData = useTalentCommonData();
const commonData = storeToRefs(talentCommonData);
const categories = computed(() => commonData.status.value || []);

const talentsColumns = computed(() =>
  !isDeleted.value ? columns[ITableColumns.Talents] : columns[ITableColumns.TalentsDelete]
);

const realColumns = ref(talentsColumns.value);

const columnsOptions = computed(() =>
  talentsColumns.value
    .map((column: IColumn) => ({
      label: column.label,
      value: column.label,
    }))
    .filter((col: IColumn) => col.label !== '' && col.label !== 'Actions' && col.label !== 'Name')
);

const jsonColumns: string = localStorage.getItem('talents-columns') || '';

const visibleColumns = ref(
  jsonColumns
    ? JSON.parse(jsonColumns)
    : talentsColumns.value
        .map((column: IColumn) => ({
          label: column.label,
          value: column.label,
        }))
        .filter((col: IColumn) => col.label !== '')
);

const removeColumn = (opt: Option[]) => {
  visibleColumns.value = [...opt];
};

watch(
  [visibleColumns, talentsColumns],
  () => {
    const col = visibleColumns.value.map((col: Option) => col.label);

    realColumns.value = talentsColumns.value.filter((columns: IColumn) => {
      return col.includes(columns.label);
    });

    localStorage.setItem('talents-columns', JSON.stringify(visibleColumns.value));
  },
  { immediate: true }
);

const optionsEvaluateStatus = computed(() =>
  categories.value
    .filter((cat) => cat !== 'ALL_STAR' && cat !== 'INVITED')
    .map((category) => ({ label: category, value: category }))
);

const params = reactive<QueryTalentsArgs>({
  first: visibleResult.value,
  filters: { deleted: isDeleted.value },
});

const searchParams = reactive<QuerySearchTalentsArgs>({
  first: visibleResult.value,
  keyword: '',
  filters: { deleted: isDeleted.value },
});

const enabled = (isSearch: boolean): ComputedRef<boolean> =>
  computed(() => isSearch === searchParams.keyword.length > MIN_LENGTH_SEARCH);

const { data, status, isLoading, refetch } = getTalents(params, { enabled: enabled(false) });

const {
  data: searchData,
  status: statusSearch,
  isLoading: loadingSearch,
  refetch: refetchSearch,
} = searchTalents(searchParams, { enabled: enabled(true) });

const talentsIds = computed(() =>
  searchTerms.value.length <= MIN_LENGTH_SEARCH
    ? data.value?.talents.edges?.map((talent) => talent.cursor)
    : searchData.value?.searchTalents.edges?.map((talent) => talent.cursor)
);

/**
 Clear params of filter (Talents & Search Talents), clear the searchterms
 reset the Tab to first element.
 */
const clearParam = () => {
  page.value = 1;
  searchTerms.value = '';
  startDownload.value = false;

  delete params.after;
  delete params.before;
  delete params.last;
  params.first = visibleResult.value;
  params.filters = { deleted: isDeleted.value };

  delete searchParams.after;
  delete searchParams.before;
  delete searchParams.last;
  searchParams.first = visibleResult.value;
  searchParams.filters = { deleted: isDeleted.value };
  searchParams.keyword = '';

  clearTimeout(modalTimeout.value);
};

watch([data, searchData], () => {
  localStorage.setItem('filter-talent', JSON.stringify(queryFilterMapped.value));
});

const clearQuery = () => {
  router.replace({ query: {} });
  for (const [key] of Object.entries(route.query)) {
    delete route.query[key];
  }
  localStorage.removeItem('filter-talent');
};

/**
 When activeNav change, set the value of the filters about deleted to true
 leaving the old filters params.
 Set the value of submitted to false.
 */
watch(activeNav, () => {
  clearParam();
  activeTab.value = tabItems[0];
  submitted.value = false;
  action.value = '';
  talentIdsSelected.value.talentIds.length = 0;
  talentIdsSelected.value.talentIds = [];

  params.filters = {
    ...(activeNav.value.value === 'DELETED' && { deleted: true }),
  };
  searchParams.filters = {
    ...(activeNav.value.value === 'DELETED' && { deleted: true }),
  };
});

// Count total of talents visible based on filters
const totalCount = computed(() =>
  useTotalCount({
    data: data.value?.talents,
    searchData: searchData.value?.searchTalents,
    searchTerms: searchTerms.value,
  })
);

const talentsCounter = computed(() => tabCounter.value?.talentsCounter ?? []);

const totalPages = computed(() => Math.ceil(totalCount.value / visibleResult.value));

const paginationTalentsValue = computed(
  () => totalCount.value - visibleResult.value * (totalPages.value - 1)
);

/**
 When change the visibleResult or write in input search, page set to 1, the pararms with first set to visibleResult
 and the fetch of data start after 400ms
 */
watch([visibleResult, searchTerms], (oldValue, _) => {
  const oldSearch = oldValue[1];
  page.value = 1;
  params.first = visibleResult.value;
  searchParams.first = visibleResult.value;
  localStorage.setItem('talents-visible-result', JSON.stringify(visibleResult.value));
  startDownload.value = false;

  delete params.after;
  delete searchParams.after;

  delete params.last;
  delete searchParams.last;

  const timeout = setTimeout(() => {
    oldSearch === searchTerms.value && searchTerms.value.length > MIN_LENGTH_SEARCH
      ? (searchParams.keyword = searchTerms.value)
      : (searchParams.keyword = '');
  }, TIME_DELAY_SEARCH);

  return () => clearTimeout(timeout);
});

onMounted(() => {
  if (
    typeof activeTab.value === 'object'
      ? activeTab.value.name === 'TOTAL'
      : activeTab.value === 'TOTAL'
  ) {
    delete params.filters?.status;
    delete searchParams.filters?.status;
  } else {
    params.filters = { ...params.filters, status: activeTab.value as TalentStatus };
    searchParams.filters = { ...searchParams.filters, status: activeTab.value as TalentStatus };
  }
  if (window.history.state.back.startsWith('/talents/edit')) {
    delete params.first;
    delete searchParams.first;
    delete params.last;
    delete searchParams.last;
    delete params.after;
    delete searchParams.after;
    delete params.before;
    delete searchParams.before;
    Object.assign(params, talentsStore.params);
    page.value = talentsStore.page;
  }
});

watch([params, searchParams, route, page], () => {
  if (!window.history.state.back.startsWith('/talents/edit')) {
    talentsStore.params = params;
    talentsStore.page = page.value;
  }
});

watch(page, () => {
  talentsStore.page = page.value;
});

// When change the activeTab, set filter with correct status, if status is equal TOTAL delete his status param
watch(activeTab, () => {
  if (
    typeof activeTab.value === 'object'
      ? activeTab.value.name === 'TOTAL'
      : activeTab.value === 'TOTAL'
  ) {
    delete params.filters?.status;
    delete searchParams.filters?.status;
  } else {
    params.filters = { ...params.filters, status: activeTab.value as TalentStatus };
    searchParams.filters = { ...searchParams.filters, status: activeTab.value as TalentStatus };
  }
});

// Set the correct number of item for each item's tab
const tabItemsMapped = computed(() =>
  useTabItemsMapper({
    counter: talentsCounter.value,
    tabItems: tabItems,
  })
);

/**
 Set the Search terms based on writing in input
 @param {string} text - text write in input
 */
const handleSearch = (text: string) => {
  searchTerms.value = text;
};

const { items: filtersItems } = useFilters({ filters: IFiltersOptions.Talents });

// Populate the filters with correct label and value
const talentsFilterData = computed(() =>
  useFilterDataMapper({
    filters: filtersData.value,
    filtersItems: filtersItems,
  })
);

const { getTalentsQueryMapper } = useTableItemsMapper();

// Set the items for table
const items = computed(() =>
  getTalentsQueryMapper({
    data:
      searchTerms.value.length > MIN_LENGTH_SEARCH
        ? searchData.value?.searchTalents
        : data.value?.talents,
    status: searchTerms.value.length > MIN_LENGTH_SEARCH ? statusSearch.value : status.value,
    isLoading: searchTerms.value.length > MIN_LENGTH_SEARCH ? loadingSearch.value : isLoading.value,
    actions: { moveIn, addNote, evaluateStatus },
    userRole: userRole.value,
  })
);

const paramsDownloadCSV = reactive<MutationExportTalentsArgs>({
  filters: {},
});

watch(selectedItems, () => {
  talentIdsSelected.value.talentIds = [...selectedItems.value];
});

// Set the filters or keyword of Talents and enable/start download the CSV
const enabledDownloadCSV = computed(() => startDownload.value);

watch(enabledDownloadCSV, () => {
  if (enabledDownloadCSV.value) {
    setTimeout(() => {
      startDownload.value = false;
    }, 300);
  }
});

watch(startDownload, () => {
  if (startDownload.value) {
    if (Object.keys(selectedItems.value).length !== 0) {
      paramsDownloadCSV.talentIds = selectedItems.value.map((items: Talent) => items.id);
    } else {
      paramsDownloadCSV.talentIds = talentsIds.value ? [...talentsIds.value] : [];
    }

    setTimeout(() => {
      startDownload.value = false;
    }, 300);
  }
});

const { data: downloadCSV, isLoading: exportLoading } = exportCsvTalents(paramsDownloadCSV, {
  enabled: enabledDownloadCSV,
});

/**
 Set params on filters
 @param {Object[]} filters - the object with all parameters
 */
const activeFilters = (filters: any[]) => {
  const status = params.filters?.status;
  if (!window.history.state.back.startsWith('/talents/edit')) {
    clearParam();
    if (params.after) params.first = visibleResult.value;
    if (params.before) params.first = visibleResult.value;
  }
  const mappedFilters = useMappedFilters(filters);
  startDownload.value = false;
  resultTalentsFound.value = true;
  filterApplied.value = true;

  params.filters = {
    ...mappedFilters,
    status: status,
    deleted: isDeleted.value,
  };
  searchParams.first = visibleResult.value;
  searchParams.filters = {
    ...mappedFilters,
    status: status,
    deleted: isDeleted.value,
  };
};

// Clear params on filters
const resetFilters = () => {
  clearParam();
  activeTab.value = tabItems[0];

  filterApplied.value = false;
  resultTalentsFound.value = false;
  filtersQuery.filters = [];
  filtersQuery.operator = '';

  clearQuery();
};

/**
 Set the action of the select (verify, increase level, delete)
 @param {Option} opt - option value selected
 @return {string}
 */
const setAction = (opt: Option) => (action.value = opt.value);

// If the action is not yet set, can't apply the action
const isApplyButtonDisabled = computed(
  () =>
    selectedItems.value.length <= 0 ||
    action.value === '' ||
    (userRole.value === 'JUNIOR' && activeNav.value.value === 'DELETED')
);

/**
 InviteTalent to interview, open modal, set ID of Talent
 @param {string} id - Id of talent.
 @return {void}
 */
const actions = (id: string) => {
  talentId.talentId = id;
  actionOpenTalentModal.value = true;
  if (modalTimeout.value) {
    clearTimeout(modalTimeout.value);
  }

  activeTab.value === 'INVITED'
    ? (typeModal.value = 'remindInterview')
    : (typeModal.value = 'interview');
};

/**
 Enable action of table
 @param {boolean} isInterview - If is interview (Interview) else Reject.
 @param {boolean} isRemind - if is remind (remind Interview)
 @return {boolean}
 */
const enabledActionTalent = (isInterview: boolean, isRemind: boolean): ComputedRef<boolean> =>
  computed(
    () =>
      sendingActionTalent.value &&
      isInterview === (typeModal.value === 'interview') &&
      isRemind === (typeModal.value === 'remindInterview')
  );

const enableFetchTalent = computed(() => !!talentId.talentId);

// Get data (Name, LastName, Email, Notes) of Talent
const {
  data: talent,
  isSuccess: successDataTalent,
  remove: removeDataTalent,
} = getTalentInterview(talentId, {
  enabled: enableFetchTalent,
});

const addNote = (id: string) => {
  talentId.talentId = id;
  actionOpenTalentModal.value = true;
  if (modalTimeout.value) {
    clearTimeout(modalTimeout.value);
  }
  typeModal.value = 'addNote';
};

const updateNote = () => {
  paramAddNoteTalent.notes = note.value.replace(/<[^>]+>/g, '');
  paramAddNoteTalent.talentId = talentId.talentId;
  enableAddNote.value = true;
};

const updateBio = () => {
  paramUpdateBiography.biography = bio.value.replace(/<[^>]+>/g, '');
  paramUpdateBiography.talentId = talentId.talentId;
  enableUpdateBio.value = true;
};

const paramAddNoteTalent = reactive<MutationUpdateTalentNotesArgs>({
  talentId: talentId.talentId,
  notes: note.value,
});

const paramUpdateBiography = reactive<MutationUpdateTalentBiographyArgs>({
  talentId: talentId.talentId,
  biography: bio.value,
});

const enabledAddNote = computed(() => enableAddNote.value && paramAddNoteTalent.notes.length > 0);
const enabledUpdateBio = computed(
  () => enableUpdateBio.value && paramUpdateBiography.biography.length > 0
);

const {
  isSuccess: successUpdateBiography,
  isError: errorUpdateBiography,
  remove: removeUpdateBiography,
} = updateTalentBiography(paramUpdateBiography, {
  enabled: enabledUpdateBio,
});

// Update note Talent
const {
  isSuccess: successAddNote,
  isError: errorAddNote,
  remove: removeAddNote,
} = updateTalentNotes(paramAddNoteTalent, { enabled: enabledAddNote });

// Invite Talent to interview
const {
  isSuccess: successSentInterview,
  isError: errorSentInterview,
  remove: removeSentInterval,
} = inviteTalent(talentId, {
  enabled: enabledActionTalent(true, false),
});

// Remind Talent
const {
  isSuccess: successRemindSentInterview,
  isError: errorRemindSentInterview,
  remove: removeRemindSentInterval,
} = remindInviteTalent(talentId, {
  enabled: enabledActionTalent(false, true),
});

// Reject the Talent
const moveIn = (status: TalentStatus, id: string) => {
  talentStatus.value = status;
  talentId.talentId = id;
  actionOpenTalentModal.value = true;
  if (modalTimeout.value) {
    clearTimeout(modalTimeout.value);
  }
  typeModal.value = 'reject';
};

const paramsRejectTalent = reactive<MutationRejectTalentArgs>({
  status: talentStatus.value as TalentStatus,
  talentId: talentId.talentId,
});

watch(sendingActionTalent, () => {
  paramsRejectTalent.status = talentStatus.value as TalentStatus;
  paramsRejectTalent.talentId = talentId.talentId;
});

const {
  isSuccess: successRejectTalent,
  isError: errorRejectTalent,
  remove: removeRejectTalent,
} = rejectTalent(paramsRejectTalent, {
  enabled: enabledActionTalent(false, false),
});

/**
 Enable delete of Talent
 @param {boolean} isHardDelete - If is true it's hardDelete else softDelete.
 @return {boolean}
 */
const enableDelete = (isHardDelete: boolean): ComputedRef<boolean> =>
  computed(
    () =>
      !!talentIdsSelected.value.talentIds &&
      action.value === 'delete' &&
      submitted.value &&
      isHardDelete === (activeNav.value.value === 'DELETED')
  );

const {
  isSuccess: successSoftDelete,
  isError: errorSoftDelete,
  remove: removeSoftDelete,
} = softDeleteTalents(talentIdsSelected.value, {
  enabled: enableDelete(false),
});

const {
  isSuccess: successHardDelete,
  isError: errorHardDelete,
  remove: removeHardDelete,
} = hardDeleteTalents(talentIdsSelected.value, {
  enabled: enableDelete(true),
});

const enableRestore = computed(
  () =>
    !!talentIdsSelected.value &&
    action.value === 'restore' &&
    submitted.value &&
    activeNav.value.value === 'DELETED'
);

const {
  isSuccess: successRestore,
  isError: errorRestore,
  remove: removeRestore,
} = restoreTalents(talentIdsSelected.value, {
  enabled: enableRestore,
});

const evaluateStatus = (id: string) => {
  talentId.talentId = id;
  openModalEvaluateStatus.value = true;
};

const inferenceReviewTalent = reactive<MutationCreateInferenceReviewArgs>({
  input: {
    talentId: talentId.talentId,
    review: '' as TalentStatus,
    note: '',
  },
});

const enableInferenceReviewTalent = (isCreadted: boolean): ComputedRef<boolean> =>
  computed(
    () =>
      isCreadted === !talent.value?.talent.inferenceReview?.createdAt &&
      inferenceReviewTalent.input.review.length > 0 &&
      !!talent.value?.talent.prediction
  );

const {
  isSuccess: successCreateInferenceReview,
  isError: errorCreateInferenceReview,
  remove: removeCreateInferenceReview,
} = createInferenceReview(inferenceReviewTalent, {
  enabled: enableInferenceReviewTalent(true),
});

const {
  isSuccess: successUpdateInferenceReview,
  isError: errorUpdateInferenceReview,
  remove: removeupdateInferenceReview,
} = updateInferenceReview(inferenceReviewTalent, {
  enabled: enableInferenceReviewTalent(false),
});

const removeAPIerror = () => {
  removeRejectTalent();
  removeSentInterval();
  removeSoftDelete();
  removeHardDelete();
  removeRestore();
  removeAddNote();
  removeDataTalent();
  removeRemindSentInterval();
  removeCreateInferenceReview();
  removeupdateInferenceReview();
  removeUpdateBiography();

  setTimeout(() => {
    enableAddNote.value = false;
    enableUpdateBio.value = false;
    submitted.value = false;
    sendingActionTalent.value = false;
    talentIdsSelected.value.talentIds.length = 0;
    openModalEvaluateStatus.value = false;
    talentId.talentId = '';
    inferenceReviewTalent.input.review = '' as TalentStatus;
  }, TIME_TO_RESTORE_VARIABLES);
};

watch(
  [
    successSentInterview,
    successRemindSentInterview,
    successRejectTalent,
    successRestore,
    successSoftDelete,
    successHardDelete,
    successAddNote,
    successCreateInferenceReview,
    successUpdateInferenceReview,
    successUpdateBiography,
    errorUpdateBiography,
  ],
  () => {
    searchTerms.value.length > MIN_LENGTH_SEARCH ? refetchSearch() : refetch();
    refetchTabCounter();
    removeAPIerror();
  }
);

const submit = () => {
  const talentToDelete = selectedItems.value.map((item) => (item as (typeof items.value)[0]).id);
  if (action.value === 'delete') {
    talentIdsSelected.value.talentIds = talentToDelete;
    submitted.value = true;
  }

  const talentToRestore = selectedItems.value.map((item) => (item as (typeof items.value)[0]).id);
  if (action.value === 'restore' && isDeleted.value) {
    talentIdsSelected.value.talentIds = talentToRestore;
    submitted.value = true;
  }
};

const showCorrectModaltext = computed(() => {
  switch (typeModal.value) {
    case 'interview':
      return {
        head: inviteInterview,
        paragraph: interviewParagraphModal,
        sent: interviewSentModal,
      };

    case 'remindInterview':
      return {
        head: remindInviteInterview,
        paragraph: interviewParagraphModal,
        sent: interviewSentModal,
      };

    case 'reject':
      if (talentStatus.value === 'CANTERA') {
        return {
          head: moveInString + ' ' + cantera,
          paragraph: rejectParagraphModal + ' ' + cantera,
          sent: rejectTalentSentModal,
        };
      } else if (talentStatus.value === 'FUTURE') {
        return {
          head: moveInString + ' ' + futureTalent,
          paragraph: rejectParagraphModal + ' ' + futureTalent,
          sent: rejectTalentSentModal,
        };
      } else {
        return {
          head: moveInString + ' ' + eligible,
          paragraph: rejectParagraphModal + ' ' + eligible,
          sent: rejectTalentSentModal,
        };
      }

    case 'addNote':
      return { head: addNoteString, paragraph: notePlaceholder, sent: '' };

    default:
      return { head: '', paragraph: '', sent: '' };
  }
});

const messageNotification = computed(() => {
  switch (true) {
    case successSentInterview.value:
      return {
        status: 'success',
        message: interviewSentModal,
      };

    case errorSentInterview.value:
      return {
        status: 'error',
        message: rejectError,
      };

    case successRemindSentInterview.value:
      return {
        status: 'success',
        message: interviewSentModal,
      };

    case errorRemindSentInterview.value:
      return {
        status: 'error',
        message: rejectError,
      };

    case successRejectTalent.value:
      return {
        status: 'success',
        message:
          rejectTalentSentModal +
          ' ' +
          (talentStatus.value === 'ELIGIBLE'
            ? eligible
            : talentStatus.value === 'FUTURE'
            ? future
            : cantera),
      };

    case errorRejectTalent.value:
      return {
        status: 'error',
        message: rejectError,
      };

    case successSoftDelete.value:
      return {
        status: 'success',
        message: deleteTalentsSuccess,
      };

    case errorSoftDelete.value:
      return {
        status: 'error',
        message: deleteTalentsError,
      };

    case successHardDelete.value:
      return {
        status: 'success',
        message: deleteTalentsSuccess,
      };

    case errorHardDelete.value:
      return {
        status: 'error',
        message: deleteTalentsError,
      };

    case successRestore.value:
      return {
        status: 'success',
        message: restoreSuccessful,
      };

    case errorRestore.value:
      return {
        status: 'error',
        message: genericError,
      };

    case successAddNote.value && successUpdateBiography.value:
      return {
        status: 'success',
        message: successAddNoteString,
      };

    case successAddNote.value:
      return {
        status: 'success',
        message: successAddNoteString,
      };

    case successUpdateBiography.value:
      return {
        status: 'success',
        message: successAddNoteString,
      };

    case errorAddNote.value || errorUpdateBiography.value:
      return {
        status: 'error',
        message: errorAddNoteString,
      };

    case successCreateInferenceReview.value:
      return {
        status: 'success',
        message: successSendReview,
      };

    case errorCreateInferenceReview.value:
      return {
        status: 'error',
        message: errorSendReview,
      };

    case successUpdateInferenceReview.value:
      return {
        status: 'success',
        message: successUpdateReview,
      };

    case errorUpdateInferenceReview.value:
      return {
        status: 'error',
        message: errorUpdateReview,
      };

    default:
      return {
        status: 'error',
        message: genericError,
      };
  }
});

watch(
  notificationPopup,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      currentNotification.value = messageNotification.value;
    }
  },
  { deep: true }
);

const talentsPagination = computed(() => {
  if (searchData.value && searchTerms.value.length > MIN_LENGTH_SEARCH) {
    const {
      endCursor: nextPage,
      hasNextPage,
      hasPreviousPage,
      startCursor: prevPage,
    } = searchData.value.searchTalents.pageInfo;

    return {
      label: stringTalents,
      currentPage: page.value ?? 1,
      nextPage,
      hasNextPage,
      prevPage,
      hasPreviousPage,
      totalItem: totalCount.value,
      totalPages: totalPages.value,
    };
  } else if (data.value) {
    const {
      endCursor: nextPage,
      hasNextPage,
      hasPreviousPage,
      startCursor: prevPage,
    } = data.value.talents.pageInfo;

    return {
      label: stringTalents,
      currentPage: page.value ?? 1,
      nextPage,
      hasNextPage,
      prevPage,
      hasPreviousPage,
      totalItem: totalCount.value,
      totalPages: totalPages.value,
    };
  }
});

const changePage = async (type: 'first' | 'prev' | 'next' | 'last', cursor: string | undefined) => {
  const { currentPage, paginationParams } = usePagination(
    type,
    cursor,
    page.value,
    visibleResult.value,
    totalPages.value,
    paginationTalentsValue.value,
    searchData.value && searchTerms.value.length > MIN_LENGTH_SEARCH ? searchParams : params
  );
  page.value = currentPage;
  Object.assign(params, paginationParams);
};

watch([successAddNote, successUpdateBiography, errorAddNote, errorUpdateBiography], () => {
  // If we have any success or error, close the modal and show notification
  if (
    successAddNote.value ||
    successUpdateBiography.value ||
    errorAddNote.value ||
    errorUpdateBiography.value
  ) {
    actionOpenTalentModal.value = false;
    notificationPopup.value = true;

    // Reset the form values
    note.value = '';
    bio.value = '';

    modalTimeout.value = setTimeout(() => {
      notificationPopup.value = false;
    }, NOTIFICATION_DURATION);
  }
});
</script>

<template>
  <div class="Talents">
    <div class="Talents__nav container-fluid">
      <Nav class="col-12" :items="navItems" :active-item="activeNav.value" @clicked="setActiveNav">
        <template #content>
          <Button
            variant="primary"
            tag="router-link"
            to="/talents/add"
            :label="addTalent"
            icon="plus"
            :disabled="userRole !== 'ADMIN'"
          />
        </template>
      </Nav>
    </div>
    <div class="container">
      <Filters
        class="col-12"
        :tab-items="tabItemsMapped"
        :select-options="talentsFilterData"
        :active-tab="typeof activeTab === 'object' ? activeTab.name : activeTab"
        :hidden-tab="isDeleted"
        :download-c-s-v="downloadCSV"
        :loading-download="exportLoading && enabledDownloadCSV"
        :filters-query="filtersQuery"
        @tab-selected="setActiveTab"
        @keystart="handleSearch"
        @active="activeFilters"
        @reset="resetFilters"
        @start-download="(download: boolean) => startDownload = download"
      />
    </div>
    <div class="container">
      <div class="col-12">
        <div class="Talents__select">
          <div class="Talents__select-actions">
            <Select
              :placeholder="selectAction"
              size="small"
              :options="!isDeleted ? talentsString.options : talentsString.optionsDeleted"
              @selected="setAction"
            />
            <Button
              :label="buttonAction"
              variant="secondary"
              native-type="submit"
              :disabled="isApplyButtonDisabled"
              @click="submit"
            />
          </div>
          <div class="Talents__select-actions">
            <Select
              :label="selectVisibleTalents.label"
              :options="selectVisibleTalents.options"
              :initial-value="initialVisibleResult"
              variant="table"
              @selected="(option: Option) => (visibleResult = +option.value, page = 1)"
            />
            <MultiSelect
              :options="columnsOptions"
              :initial-value="visibleColumns"
              :placeholder="selectColumns"
              size="small"
              @selected="removeColumn"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="col-12">
        <div class="Talents__found" v-if="resultTalentsFound || searchTerms.length">
          <span class="Talents__found--number">{{ totalCount }}</span>
          <span class="Talents__found--text">{{ resultFound }}</span>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="col-12">
        <Table
          :items="items"
          :columns="realColumns"
          :action="actions"
          icon="edit"
          is-small-row
          :is-loading="searchTerms.length > MIN_LENGTH_SEARCH ? loadingSearch : isLoading"
          :highlight-text="searchTerms"
          @select-items="selectItems"
          @reset-selection="resetSelection"
        />
        <Pagination
          v-if="talentsPagination && items.length > 0"
          v-bind="{ ...talentsPagination }"
          @change-page="changePage"
        />
      </div>
    </div>
    <ModalEvaluateStatus
      :inference-review="talent?.talent.inferenceReview"
      :talent-id="talentId.talentId"
      :open="openModalEvaluateStatus"
      :prediction="talent?.talent?.prediction"
      :options="optionsEvaluateStatus"
      :note="talent?.talent.notes || ''"
      @reset-modal="(reset: boolean) => openModalEvaluateStatus = reset"
      @inference-review="
        (formValue: CreateInferenceReviewInput) => ((inferenceReviewTalent.input = { ...formValue, talentId: talentId.talentId }), note = formValue.note || '', updateNote() )
      "
    />
    <Modal
      :is-modal-active="actionOpenTalentModal"
      @close-modal="actionOpenTalentModal = false"
      :head="showCorrectModaltext.head"
    >
      <template #modal-main v-if="typeModal !== 'addNote'">
        <div class="popupInterview">
          <o-skeleton
            class="popupInterview-skeleton --title"
            :animated="true"
            v-if="!talent?.talent.firstName"
          />
          <h4 class="popupInterview-title heading-h4" v-else>
            {{ talent?.talent.firstName }} {{ talent?.talent.lastName }}
          </h4>
          <o-skeleton
            class="popupInterview-skeleton --subtitle"
            :animated="true"
            v-if="!talent?.talent.email"
          />
          <div class="popupInterview-subtitle text-body-1" v-else>
            {{ talent?.talent.email }}
          </div>
          <div class="popupInterview-main">
            <p
              v-if="!successSentInterview || !successRejectTalent || !successRemindSentInterview"
              class="popupInterview-main-paragraph heading-h4"
            >
              {{ showCorrectModaltext.paragraph }}
            </p>
            <p v-else class="popupInterview-main-paragraph heading-h4">
              {{ showCorrectModaltext.sent }}
            </p>
          </div>
        </div>
      </template>
      <template #modal-main>
        <div class="popupAddNote">
          <div class="popupAddNote__note-details mb-1" v-if="talent?.talent.notesDetails?.userId">
            <span class="popupAddNote__note-details--info">
              {{ lastUpdate }}:
              {{
                moment(talent?.talent.notesDetails.updatedAt)
                  .locale('it')
                  .format('YYYY-MM-DD, ddd H:mm')
              }}
            </span>
            <span class="popupAddNote__note-details--info">
              {{ user }}: {{ talent?.talent.notesDetails.firstName }}
              {{ talent?.talent.notesDetails.lastName }}
            </span>
            <span class="popupAddNote__note-details--info">
              {{ user }} ID: {{ talent?.talent.notesDetails.userId }}
            </span>
          </div>

          <o-skeleton v-if="!successDataTalent" class="popupAddNote-skeleton" :animated="true" />
          <TextAreaForm
            v-else
            :initial-value="talent?.talent.notes"
            :placeholder="showCorrectModaltext.paragraph.replace('{name}', talentString)"
            @keystart="(p) => (note = p)"
          />

          <TextAreaForm
            :initial-value="talent?.talent.biography"
            placeholder="Add a bio for this Talent"
            @keystart="(p) => (bio = p)"
            class="mt-1"
          />
        </div>
      </template>

      <template #modal-footer v-if="typeModal === 'addNote'">
        <component
          :is="Button"
          variant="primary"
          :label="saveNote"
          @clicked="
            updateNote();
            updateBio();
          "
          :disabled="note.length < 1 && bio.length < 1"
        ></component>
      </template>
      <template #modal-footer v-else>
        <component
          :is="Button"
          variant="secondary"
          :label="cancel"
          @clicked="actionOpenTalentModal = false"
          v-if="!successSentInterview || !successRejectTalent || !successRemindSentInterview"
        ></component>
        <component
          :is="Button"
          variant="primary"
          :label="confirmButton"
          @clicked="sendingActionTalent = true"
          v-if="!successSentInterview || !successRejectTalent || !successRemindSentInterview"
        ></component>

        <component
          v-else
          :is="Button"
          variant="primary"
          :label="understand"
          @clicked="actionOpenTalentModal = false"
        ></component>
      </template>
    </Modal>
    <Notification
      v-if="notificationPopup && currentNotification"
      :message="currentNotification.message"
      :status="(currentNotification.status as NotificationStatus)"
      :duration="NOTIFICATION_DURATION"
    />
  </div>
</template>

<style scoped lang="scss">
.Talents {
  &__nav {
    margin-bottom: 4rem;
  }

  &__select {
    @include flexing(row, space-between, center);
    margin-bottom: 3.9rem;

    &-actions {
      @include flexing(row, start, center);
      gap: 0.8rem;
    }
  }

  &__found {
    @extend .heading-h4;
    @include flexing(row, start, center);
    gap: 0.4rem;
    margin-bottom: 2.4rem;

    &--text {
      color: $black-50;
    }
  }

  mark {
    background-color: $accent;
    color: $secondary-color;
    border-radius: 0.2rem;
  }
}

.popupAddNote {
  padding: 2.5rem;

  &__note-details {
    border: 0.1rem solid $black-20;
    border-radius: 0.4rem;
    @include flexing(column, center, center);
    gap: 0.8rem;
    @extend .text-body-2;
    padding: 2rem 0;
  }

  &-skeleton {
    :deep(.o-sklt__item) {
      min-height: 18.8rem;
    }

    width: 100%;
  }
}

.popupInterview {
  text-align: center;
  width: 39rem;
  margin: auto;

  &-skeleton {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.8rem;

    &.--title {
      :deep(.o-sklt__item) {
        height: 3rem;
      }

      width: 40%;
    }

    &.--subtitle {
      :deep(.o-sklt__item) {
        height: 2.5rem;
      }

      width: 60%;
    }
  }

  &-title {
    margin: 0;
    margin-bottom: 0.4rem;
  }

  &-subtitle {
    color: $black-50;
  }

  &-main {
    margin: 3.2rem 0;

    &-paragraph {
      margin: 0;
    }
  }
}
</style>
